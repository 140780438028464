export const role = {
  USER: "USER",
  VERIFIER: "VERIFIER",
  MODERATOR: "MODERATOR",
  SUPERVISOR: "SUPERVISOR",
  SUPERMODERATOR: "SUPERMODERATOR",
};
// export const apiUrl = "http://localhost:8080/mrss/v-1/";
// export const apiUrl = "http://10.179.2.219:8080/mrss/v-1/";

export const apiUrl =
  window.location.protocol +
  "//" +
  window.location.hostname +
  "/mrssapi/mrss/v-1/";

// export const apiUrl = "http://megrssa.nic.in:8080/mrssapi/mrss/v-1/";
// export const apiUrl = "http://megrssa.nic.in/mrssapi/mrss/v-1/";
// export const apiUrl = process.env.REACT_APP_ENDPOINT;
