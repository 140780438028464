import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const getAddress = (address) => {
  return (
    address.address1 +
    ", " +
    address.address2 +
    ", pincode: " +
    address.pinCode +
    ", District: " +
    address.district.districtName +
    ", State: " +
    address.district.statename.stateName
  );
};

export default function LandlordDetails(props) {
  const classes = useStyles();

  //  console.log(props.landlord);
  const { landlord } = props;
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row" style={{ color: "blue" }}>
              Full Name:
            </TableCell>
            <TableCell align="left" style={{ color: "blue" }}>
              {landlord.firstName}{" "}
              {landlord.middleName != null || landlord.middleName != ""
                ? landlord.middleName
                : ""}{" "}
              {landlord.lastName}{" "}
            </TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row" style={{ color: "blue" }}>
              Landlord ID:
            </TableCell>
            <TableCell align="left" style={{ color: "blue" }}>
              {landlord.landlord_id}
            </TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row" style={{ color: "blue" }}>
              Occupation:
            </TableCell>
            <TableCell align="left" style={{ color: "blue" }}>
              {" "}
              {landlord.occupation}{" "}
            </TableCell>
          </TableRow>
          <TableRow key={4}>
            <TableCell component="th" scope="row" style={{ color: "blue" }}>
              Mobile Number:
            </TableCell>
            <TableCell align="left" style={{ color: "blue" }}>
              {" "}
              {landlord.mobile}{" "}
            </TableCell>
          </TableRow>
          <TableRow key={5}>
            <TableCell component="th" scope="row" style={{ color: "blue" }}>
              Permanent Address:
            </TableCell>
            <TableCell align="left" style={{ color: "blue" }}>
              {getAddress(landlord.permanentAddress)}{" "}
            </TableCell>
          </TableRow>
          <TableRow key={6}>
            <TableCell component="th" scope="row" style={{ color: "blue" }}>
              Present Address:
            </TableCell>
            <TableCell align="left" style={{ color: "blue" }}>
              {getAddress(landlord.presentAddress)}{" "}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
