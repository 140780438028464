import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import classnames from "classnames";
// import HourglassFullIcon from "@material-ui/icons/HourglassFull"; //this is for the pending button.
import AvTimerIcon from "@material-ui/icons/AvTimer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ForwardIcon from "@material-ui/icons/Forward";

// styles
var useStyles = makeStyles((theme) => ({
  dotBase: {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.text.hint,
    borderRadius: "50%",
    transition: theme.transitions.create("background-color"),
  },
  dotSmall: {
    width: 5,
    height: 5,
  },
  dotLarge: {
    width: 11,
    height: 11,
  },
}));

export default function Dot({ size, color, label }) {
  var classes = useStyles();
  var theme = useTheme();

  return label === "Pending" ? (
    <AvTimerIcon />
  ) : label === "Approved" ? (
    <CheckCircleOutlineIcon />
  ) : label === "Forwarded" ? (
    <ForwardIcon />
  ) : label === "Rejected" ? (
    <BlockIcon />
  ) : label === "Add" ? (
    <AddCircleOutlineIcon />
  ) : (
    <div
      className={classnames(classes.dotBase, {
        [classes.dotLarge]: size === "large",
        [classes.dotSmall]: size === "small",
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
      }}
    />
  );
}
