import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Paper,
} from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import { uploadFile } from "../../api/GlobalService";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';


const useStyle = makeStyles((theme) => ({
  dialogElements: {
    display: "flex",
  },
  dialogActions: {
    justifyContent: "center",
  },
  dialogTitle: {
    margin: "auto",
  },
  closeDialog: {
    width: "10%",
    margin: "auto",
    marginTop: "40px",
    marginBottom: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Document = ({
  setOpenCamera,
  label,
  document,
  setDocument,
  setUploading,
  addTenantDocument,
  setSnackBar,
  snackBar,
  setCamFile
}) => {
  const classes = useStyle();
  const [backDropOpen, setBackDropOpen] = useState(false);

  return (
    <Box sx={{ marginTop: "1%", marginBottom: "1%" }}>
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper elevation={3}>
        <DialogContent>
          <FormControl>
            <FormLabel>

              {label === "PHOTO" || label === "IDENTITYPROOF" ? <span>
                {label} <span style={{ color: 'red' }}>*</span>
              </span> : label
              }
            </FormLabel>
          </FormControl>

          <DropzoneArea
            filesLimit={1}
            maxFileSize={1024 * 500}
            // showPreviews={true}
            // showPreviewsInDropzone={true}
            acceptedFiles={["image/jpeg", "image/jpg"]}
            onChange={(files) => {
              setDocument(files[0]);
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <IconButton
            onClick={() => {
              setCamFile(label);
              setOpenCamera(true);
            }}
          // color="primary"
          >
            <CameraAltOutlinedIcon />
          </IconButton>
          <Button
            sx={{ margin: "auto" }}
            variant="contained"
            size={"small"}
            onClick={async () => {
              await uploadFile(
                setSnackBar,
                document,
                setUploading,
                addTenantDocument,
                setDocument,
                snackBar,
                label,
                setBackDropOpen  // only state-argument which has to be declared in the component
              );
            }}
            color="primary"
          >
            {/* Upload {label} */}
            Upload
          </Button>
        </DialogActions>
      </Paper>
    </Box>
  );
};

export default Document;
