import * as React from "react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import DataGrid from "react-data-grid";
import "react-data-grid/dist/react-data-grid.css";
import ApplicationService from "../../api/ApplicationService";
import LandlordService from "../../api/LandlordService";
import HelperService from "../../api/HelperService";
import { GlobalContext } from "../../context/GlobalState";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Tooltip } from "@material-ui/core";
import TenantService from "../../api/TenantService";
import ImageDialog from "../../components/ImageDialog";
import { formatDateToIndianFormat, getDecryptedData } from "../../api/GlobalService"
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";
import { DropzoneArea } from "material-ui-dropzone";
import { Alert } from "@mui/lab";
import DocumentService from "../../api/DocumentService";

export default function HelpersDataTable(props) {
  const { refresh, setRefresh } = props;
  const { tenantApplication, currentApplication } = useContext(GlobalContext);
  const [domesticHelpers, setDomesticHelpers] = useState([]);

  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [helperId, setHelperId] = useState("")
  const [selectedDocumentType, setSelectedDocumentType] = useState("SIGNATURE");
  const [open, setOpen] = React.useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);


  const handleCloseDialog = () => {
    setSelectedDocumentType(null);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setSelectedDocumentType(null);
    setOpen(true);
  };

  const handleRemove = async () => {
    // getDecryptedData("role") === "MODERATOR" ||
    //   getDecryptedData("role") === "SUPERMODERATOR" ||
    //   getDecryptedData("role") === "VERIFIER"
    sessionStorage.getItem("role") === "MODERATOR" ||
      sessionStorage.getItem("role") === "SUPERMODERATOR" ||
      sessionStorage.getItem("role") === "VERIFIER"
      ? await TenantService.removeHelper(helperId).then(res => {
        console.log("responseDeleted: ", res.data)
        alert("Helper successfully removed!")
        setReload(reload => !reload);

      })
      : await TenantService.removeHelperAnonymous(
        helperId
      ).then(res => {
        console.log("responseDeleted: ", res.data)
        alert("Helper successfully removed!")
        setReload(reload => !reload);

      })
  }

  const columns = [
    {
      name: "memberCode",
      label: "Member Code",
      options: {
        filter: true,
        display: false
      },
    },
    {
      name: "memberName",
      label: "Member Name",
      options: {
        filter: true,
      },
    },
    {
      name: "age",
      label: "Date of Birth",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatDateToIndianFormat(tableMeta.rowData[2])
        }
      },
    },
    {
      name: "permanentAddress",
      label: "Permanent Address",
      options: {

        customBodyRender: (value, tableMeta, updateValue) => {
          return <>
            <span>
              {tableMeta.rowData[3].address1},&nbsp;
              {tableMeta.rowData[3].address2}
            </span>
            <br />
            <span>
              {tableMeta.rowData[3].district.districtName},&nbsp;{tableMeta.rowData[3].pinCode}
            </span>
            <br />
            <span>
              {tableMeta.rowData[3].district.statename.stateName}
            </span>


          </>
        },
      }
    },
    {
      name: "leftOn",
      label: "Status",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[4] === null ? (
            <Chip
              label="Active"
              style={{ padding: 0, color: "#fff", backgroundColor: "#92d459" }}
            />
          ) : (
            <Chip
              label={`Left on: ${formatDateToIndianFormat(tableMeta.rowData[4])}`}
              style={{ padding: 0, color: "#fff", backgroundColor: "red" }}
            />
          );
        },
      },
    },

    {
      name: "documents",
      label: "Actions",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log("tableMetaaa: ", tableMeta)
          return (<Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Tooltip
              title={`View signature of ${tableMeta.rowData[1]}`}
              placement="top-start"
              sx={{ flexGrow: 1 }}
              arrow
            >
              <Button
                variant="outlined"
                size="small"
                // startIcon={<AddIcon />}
                style={{
                  backgroundColor: "#F3A712",
                  color: "#fff",
                  borderRadius: "4px",
                }}
                onClick={async () => {
                  console.log("tablemetaundefined: ", tableMeta)
                  if (tableMeta.rowData[5].length === 0) {
                    alert("No image uploaded!");
                    return;
                  }
                  else {
                    const fileBase64 =
                      // getDecryptedData("role") === "MODERATOR" ||
                      //   getDecryptedData("role") === "SUPERMODERATOR" ||
                      //   getDecryptedData("role") === "VERIFIER"
                      sessionStorage.getItem("role") === "MODERATOR" ||
                        sessionStorage.getItem("role") === "SUPERMODERATOR" ||
                        sessionStorage.getItem("role") === "VERIFIER"
                        ? await TenantService.downloadHelperDocument(tableMeta.rowData[5][0].docid)
                        : await TenantService.downloadHelperDocumentAnonymous(
                          tableMeta.rowData[5][0].docid
                        );
                    if (!fileBase64) {
                      alert("Invalid Image inputted.")
                      return
                    };
                    const contenttype = fileBase64.data.contenttype;
                    var type = null;
                    if (
                      contenttype == "JPEG" ||
                      contenttype == "JPG" ||
                      contenttype == "jpeg" ||
                      contenttype == "jpg"
                    ) {
                      type = "image/jpg";
                    } else {
                      type = "application/pdf";
                    }
                    handleDownload(fileBase64.data.base64img, type);
                  }
                }}
              >
                View
              </Button>

            </Tooltip>
            {tableMeta.rowData[4] === null && <Tooltip title={`Remove ${tableMeta.rowData[1]}`}
              sx={{ flexGrow: 1 }}
              placement="top-start"
              arrow>
              <Button
                size="small"

                variant="outlined"
                // startIcon={<AddIcon />}
                style={{
                  backgroundColor: "#F3A712",
                  color: "#fff",
                  borderRadius: "4px",
                }}
                onClick={async () => {
                  setHelperId(tableMeta.rowData[0])
                  setOpenConfirmDialog(true);
                }}
              >
                Remove
              </Button>
            </Tooltip>}
            <Tooltip title={`Upload signature for ${tableMeta.rowData[1]}`}
              sx={{ flexGrow: 1 }}
              placement="top-start"
              arrow>
              <Button
                size="small"

                variant="outlined"
                // startIcon={<AddIcon />}
                style={{
                  backgroundColor: "#F3A712",
                  color: "#fff",
                  borderRadius: "4px",
                }}
                onClick={async () => {
                  await setHelperId(tableMeta.rowData[0])
                  handleClickOpen();
                }}

              >
                Upload Signature
              </Button>
            </Tooltip>

          </Box>

          );
        },
      },
    },

  ];

  function getBase64(file, callback) {
    const reader = new FileReader();

    reader.addEventListener("load", () => callback(reader.result));

    reader.readAsDataURL(file);
  }
  function getExt(filename) {
    var ext = filename.split(".").pop();
    if (ext === filename) return "";
    return ext;
  }
  const uploadFile = async () => {
    if (file === null || file === undefined) {
      setSnackBar({
        message: "Please Choose a file to upload",
        open: true,
        severity: "error",
      });
      return;
    }

    getBase64(file, async function (base64Data) {
      const fileExtension = getExt(file.name);


      let base64String = /,(.+)/.exec(base64Data)[1];

      const payload = {
        ownerid: helperId,
        ownertype: "tenanthelper",
        doctype: "SIGNATURE",
        base64img: base64String,
        contenttype: fileExtension,
      };

      try {
        setUploading(true);
        // getDecryptedData("role") === "MODERATOR" ||
        //   getDecryptedData("role") === "SUPERMODERATOR"
        sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR"
          ? await DocumentService.create(payload)
          : await DocumentService.createAnonymous(payload);
        setFile(null);
        setSelectedDocumentType(null);
        setSnackBar({
          message: "Uploaded Successfully!",
          open: true,
          severity: "success",
        });
        setRefresh(!refresh);
        setOpen(false);
        setUploading(false);
      } catch (err) {
        setUploading(false);
        setFile(null);
        setSelectedDocumentType(null);
        setSnackBar({
          message: err.response.data.message,
          open: true,
          severity: "error",
        });
      }
      setUploading(false);
    });
  };


  const handleDownload = async (base64img, type) => {
    var blob = base64toBlob(base64img, type);
    let blobUrl = URL.createObjectURL(blob);
    setIsLoading(false);
    setImage(blobUrl);
    setOpenImage(true);
  };

  function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
  }

  const getDomesticHelpers = async () => {
    let response;
    if (props.type === "TENANT") {
      // response =
      //   getDecryptedData("role") === "MODERATOR" ||
      //     getDecryptedData("role") === "SUPERMODERATOR"
      //     ? await HelperService.getHelpersByTenantId(
      //       getDecryptedData("tenantId")
      //     )
      //     : await HelperService.getHelpersByTenantIdAnonymous(
      //       getDecryptedData("tenantId")
      //     );
      response =
        sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR"
          ? await HelperService.getHelpersByTenantId(
            sessionStorage.getItem("tenantId")
          )
          : await HelperService.getHelpersByTenantIdAnonymous(
            sessionStorage.getItem("tenantId")
          );
    } else {
      response = await LandlordService.getDomesticHelperByLandlordEpicNumber(
        currentApplication.id
      );
    }
    // setDomesticHelpers(currentApplication.helper)
    setDomesticHelpers(response.data);
  };


  useEffect(() => {
    getDomesticHelpers();
  }, [reload, refresh]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <CustomDataTable
        rows={domesticHelpers}
        columns={columns}
        title="Domestic Helpers List"
      />
      <ImageDialog
        openImage={openImage}
        setOpenImage={setOpenImage}
        image={image}
      />
      <ConfirmDialog
        text="Are you sure you want to delete this helper?"
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        handleDelete={handleRemove}
      />

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="1000px"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">Upload Signature</DialogTitle>
        {snackBar.open ? (
          <Alert
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
            severity={snackBar.severity}
          >
            {snackBar.message}
          </Alert>
        ) : (
          <></>
        )}
        {uploading === false ? (
          <DialogContent>
            <FormLabel>SIGNATURE(only JPG, PNG or JPEG)</FormLabel>
            <DropzoneArea
              filesLimit={1}
              acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
              onChange={(files) => {
                setFile(files[0]);
              }}
            />
          </DialogContent>
        ) : (
          <h3>Uploading....Please wait</h3>
        )}
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button
            onClick={async () => {
              await uploadFile();
            }}
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
