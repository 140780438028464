import React from "react";
import { Grid } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import ApplicationDataTable from "../offlineapplication/DataTable";
import NewApplication from "../offlineapplication/NewApplication";
import * as Constants from "../../Constants";
import { getDecryptedData } from "../../api/GlobalService";

export default function Rectification() {
  // const role = getDecryptedData("role");
  const role = sessionStorage.getItem("role");
  return (
    <>
      <PageTitle title="Rectification Applications" />
      {role === Constants.role.USER ? <NewApplication /> : <></>}
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <ApplicationDataTable status="RECT" />
        </Grid>
      </Grid>
    </>
  );
}
