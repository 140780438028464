import * as React from "react";
import { useEffect, useState } from "react";
import MasterData from "../../api/MasterData";
import UserService from "../../api/UserService";
import {
  Button,
  IconButton,
  makeStyles,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Alert } from "@mui/lab";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import UserSignUpForm from "./UserSignUpForm";
import AddIcon from "@mui/icons-material/Add";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import { getDecryptedData } from "../../api/GlobalService";

// import { eventManager } from 'react-toastify/dist/core';

const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function PoliceDataTable({ history, refresh, toggleRefresh }) {
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [rowParams, setRowParams] = useState();
  const [publicKey, setPublicKey] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [roles, setRoles] = useState([]);
  // const roles = [{ title: "VERIFIER" }, { title: "USER" }, { title: "MODERATOR" }]
  const [policeUser, setPoliceUser] = useState({
    username: "",
    password: "",
    usercode: "",
    role: [{}],
  });

  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    getpolicestation();
  }, [refresh]);

  const getRoles = () => {
    UserService.getRoles()
      .then((response) => {
        setRoles(response.data);
        console.log(response.data);
      })
      .catch((e, response) => {
        console.error(e);
        // alert(JSON.stringify(e.response.data));
      });
  };

  const columns = [
    {
      name: "id",
      label: "Sl no.",
      options: {
        filter: true,
      },
    },
    {
      name: "policeStationName",
      label: "Police Station Name",
      options: {
        filter: true,
      },
    },
    {
      name: "users",
      label: "Users",
      options: {
        filter: true,
      },
    },
    // { field: 'action', headerName: 'Actions', width: 200, filterable: false,sortable: false,}

    {
      name: "action1",
      label: "Actions",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // getDecryptedData("role") !== "SUPERVISOR" && (
            sessionStorage.getItem("role") !== "SUPERVISOR" && (
              <Tooltip
                title={`Add user for ${tableMeta.rowData.policeStationName}`}
                placement="top-start"
                arrow
              >
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{
                    backgroundColor: "#F3A712",
                    color: "#fff",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    setRowParams(tableMeta.rowData);
                    setOpenDialog(true);
                  }}
                >
                  Add User
                </Button>

              </Tooltip>
            )
          );
        },
      },
    },
  ];

  const [policestaionList, setPolicestaionList] = useState([]);
  const getpolicestation = async () => {
    const response = await MasterData.getPoliceStations();
    var data = [];
    data = response.data;
    for (var i = 0; i < data.length; i++) {
      data[i].id = i + 1;
      data[i].policeStationName = data[i].policeStationName;
      const userresponse = await UserService.getUserbycode(
        data[i].policeStationCode
      );
      data[i].users = userresponse.data;
    }
    setPolicestaionList(data);
  };

  return (
    <div style={{ height: 600, width: "100%" }}>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      {policestaionList.length !== 0 && (
        <CustomDataTable
          rows={policestaionList}
          columns={columns}
          title="Police Station List"
        />
      )}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      // maxWidth="md"
      >
        <DialogTitle id="form-dialog-title" sx={{ margin: "auto" }}>
          {/* Add New Police Station */}
          <Typography variant="h3">Police User Entry</Typography>
        </DialogTitle>

        <DialogContent sx={{ padding: "50px" }}>
          {/* <PoliceStationForm /> */}
          <UserSignUpForm
            rowParams={rowParams}
            policeStationList={policestaionList}
            toggleRefresh={toggleRefresh}
            setOpenDialog={setOpenDialog}
            snackBar={snackBar}
            setSnackBar={setSnackBar}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
