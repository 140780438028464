import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";
// import RentalUnit from "../rentalunits/RentalUnit";
import Helpers from "../tenants/Helpers";
import TenantForm from "../tenants/TenantForm";
import TenantService from "../../api/TenantService";
import { GlobalContext } from "../../context/GlobalState";
import { Button, FormControl, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import FamilyMembers from "../tenants/FamilyMembers";
import TenantDocuments from "../tenants/documents/TenantDocuments";
import { Box } from "@mui/system";
import ForwardIcon from '@mui/icons-material/Forward';
import { getDecryptedData, storeEncryptedData } from "../../api/GlobalService";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TenantTab(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { selectedRentalUnit } = props;
  const { tenantApplication } = useContext(GlobalContext);
  const [profile, setProfile] = React.useState(
    props.profile
      ? props.profile
      : props.dupTenantApplication
        ? props.dupTenantApplication
        : tenantApplication
  );
  const [epicNo, setEpicNo] = useState("");

  const [tenantID, setTenantID] = useState(null);
  const [disabledOtherTabs, setDisableOtherTabs] = useState(
    props.saved === true ? false : profile.exist === true ? false : true
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleIDChange = (event) => {
    setEpicNo(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      // setProfile({...profile,epicNo:epicNo});
      const response = await TenantService.getByEpicNumber(epicNo);
      // If exist and isParentNotCheck, Error Saying already exist, but what if he shifted to another House
      // Design flaw here

      sessionStorage.setItem("tenantId", response.data.id);
      // storeEncryptedData("tenantId", response.data.id)

      setProfile({ ...response.data, exist: true });
      setDisableOtherTabs(false);
    } catch (error) {
      console.log(error);
      setDisableOtherTabs(true);
      setProfile({ ...profile, epicNo: epicNo, exist: false });
    }
  };

  useEffect(() => {
    if (epicNo !== "") {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          console.log("Enter key was pressed. Run your function.");
          event.preventDefault();
          // callMyFunction();
          handleSubmit();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  });

  function goToNextTab() {
    setValue(value + 1);
  }

  if (profile.epicNo === undefined) {
    return (
      <form className={classes.root} noValidate autoComplete="off">
        <FormControl style={{ width: "100%" }}>
          <TextField
            id="epic-number"
            onChange={handleIDChange}
            value={profile.epicNo ? profile.epicNo : epicNo}
            label="Tenant's ID"
            fullWidth={true}
          />
        </FormControl>
        <br />
        <Button
          style={{ float: "right", marginTop: 5 }}
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={async () => {

            sessionStorage.setItem("tenantEpicNo", epicNo);
            // storeEncryptedData("tenantEpicNo", epicNo);

            // Query The Application Epic Card
            handleSubmit();

            //setProfile({...profile,epicNo:epicNo, individualEpic: isParentEpicCard });
          }}
        >
          Ok
        </Button>
      </form>
    );
  } else
    return (
      <div
        className={classes.root}
        style={{
          // overflowY: "scroll",
          width: "100%",
        }}
      >
        {/* <AppBar position="static"> */}
        {props.registerFlag ?
          <Tabs
            value={value}
            onChange={handleChange}
            // aria-label="simple tabs example"
            aria-label="scrollable auto tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Basic Details" {...a11yProps(0)} />

          </Tabs>
          :
          <Tabs
            value={value}
            onChange={handleChange}
            // aria-label="simple tabs example"
            aria-label="scrollable auto tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label="Basic Details"
              {...a11yProps(0)}
            />
            <Tab
              label="Family Members"
              {...a11yProps(1)}
              disabled={disabledOtherTabs}
            />
            <Tab
              label="Domestic Helpers"
              {...a11yProps(2)}
              disabled={disabledOtherTabs}
            />
            <Tab
              label="Documents"
              {...a11yProps(3)}
              disabled={disabledOtherTabs}
            />
          </Tabs>}
        {/* {getDecryptedData("role") !== null && ( */}
        {sessionStorage.getItem("role") !== null && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "20px" }}>
            <Button
              endIcon={<ForwardIcon />}
              variant="contained"
              size={"small"}
              color="primary"
              onClick={() => {
                props.setActiveStep(() => props.activeStep + 1);
              }}
            >
              Proceed to Applications
            </Button>
          </Box>
        )}
        {/* </AppBar> */}
        <TabPanel value={value} index={0}>
          <TenantForm
            selectedRentalUnit={selectedRentalUnit}
            profile={profile}
            setTenantId={setTenantID}
            setDisableOtherTabs={setDisableOtherTabs}
            toNextTab={goToNextTab}
            setSaved={props.setSaved}
            setActiveStep={props.setActiveStep}
          />
        </TabPanel>
        <TabPanel value={value} index={1} >
          <FamilyMembers tenantId={tenantID} epicNo={profile.id} />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setValue(value + 1);
                setDisableOtherTabs();
              }}
            >
              Skip
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Helpers profile={profile} type="TENANT" />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setValue(value + 1);
                setDisableOtherTabs();
              }}
            >
              Skip
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TenantDocuments
            closeTenantModal={props.closeTenantModal}
            setDocumentsExist={props.setDocumentsExist}
          />

        </TabPanel>

      </div>
    );
}
