import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 5,
  },
  lsInputs: {
    marginLeft: 10,
    // marginTop: 10,
  },
  laButton: {
    marginTop: "4%",
  },
}));
