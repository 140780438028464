import React from "react";
import { Grid } from "@material-ui/core";
// styles

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import TenantsDataTable from "../../pages/rentalunits/RentalUnitDataTable";


export default function LandlordTenants() {

    // Get the Id of the landlord from the login

  return (
    <>
      <PageTitle title="Tenants" />
     
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>   
        </Grid>
      </Grid>
    </>
  );
}
