import { Grid } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import HailIcon from "@mui/icons-material/Hail";
import ApplicationDataTable from "../offlineapplication/DataTable";
// import CustomDataTable from "../../components/data-table/CustomDataTable";

const ApprovedTenants = () => {
    return <>
        <PageHeader
            title="Approved Tenants"
            subTitle="View tenants currently residing in your locality."
            icon={<HailIcon fontSize="large" />}
        />
        {/* <CustomDataTable/> */}
        <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
                <ApplicationDataTable status="PLVP" />
            </Grid>
        </Grid>
    </>
}

export default ApprovedTenants;