import React, { useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";
// styles
import useStyles from "./styles";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import RentalUnitDataTable from "./RentalUnitDataTable";
import NewRentalUnit from "./NewRentalUnit";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";

export default function RentalUnit(props) {
  const { currentApplication } = useContext(GlobalContext);
  var first = currentApplication.firstName;
  var middle = currentApplication.middleName
    ? currentApplication.middleName
    : "";
  var last = currentApplication.lastName;
  var full = `${first} ${middle} ${last}`;
  // var classes = useStyles();
  return (
    <>
      <NewRentalUnit />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <p>Landlord Name: {full.toUpperCase()}</p>
          <RentalUnitDataTable />
        </Grid>
      </Grid>
    </>
  );
}
