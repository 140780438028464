import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { TimelineOppositeContent } from "@mui/lab";

const ApplicationFlowDialog = ({ openAppFlow, setOpenAppFlow, appFlow }) => {
  return (
    <Dialog open={openAppFlow} onClose={() => setOpenAppFlow(false)} fullWidth>
      <DialogTitle>
        <Typography align="center" variant="h5">
          Application Flow
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>Here lies the app flow</DialogContentText> */}
        <Timeline>
          {appFlow.map((appState, index) => {
            index++;
            return (
              <TimelineItem key={appState.id}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  {/* {appState.logdt.slice(0, 10)} */}
                  {appState.logdt.slice(8, 10) +
                    "-" +
                    appState.logdt.slice(5, 7) +
                    "-" +
                    appState.logdt.slice(0, 4)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      backgroundColor:
                        appState.aplstatus === "LAVP"
                          ? "#F3A712"
                          : appState.aplstatus === "LARP"
                          ? "#F3A712"
                          : appState.aplstatus === "PLVP"
                          ? "#92d459"
                          : "#B12B39",
                    }}
                  />
                  {!(appFlow.length === index) && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  {/* {appState.remarks} */}
                  {appState.aplstatus === "LARP"
                    ? `Application Submitted(${appState.remarks})`
                    : appState.aplstatus === "LAVP"
                    ? `
                      Application forwarded to Locality by Sub-Locality
                    (${appState.remarks})`
                    : appState.aplstatus === "PLVP"
                    ? "Application approved by Locality"
                    : `${appState.remarks}`}
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </DialogContent>
    </Dialog>
  );
};

export default ApplicationFlowDialog;
