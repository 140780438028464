import React, { useState } from "react";
import { Button, Card, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

// components
// import PageTitle from "../../components/PageTitle/PageTitle";
import FamilyMemberDataTable from "./FamilyMemberDataTable";

import NewFamilyMember from "./NewFamilyMember";
import { Alert, Snackbar } from "@mui/material";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import PageHeader from "../../components/Header/PageHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FamilyMembers(props) {
  var classes = useStyles();
  const [famMemberFlag, setFamMemberFlag] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false)


  return (
    <div className={classes.root}>
      <Card style={{ width: "100%" }}>
        {/* <PageTitle title="Family Members" /> */}
        <PageHeader
          title="Family Memebers"
          subTitle="Add family members of tenants."
          icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        />
        <NewFamilyMember
          setFamMemberFlag={setFamMemberFlag}
          famMemberFlag={famMemberFlag}
          refresh={refresh}
          setRefresh={setRefresh}
          reload={reload}
          setReload={setReload}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <FamilyMemberDataTable
              epicNo={props.epicNo}
              famMemberFlag={famMemberFlag}
              reload={reload}
              setReload={setReload}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
