import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Button from "@mui/material/Button";

// import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";

// components
import PoliceDataTable from "./policeDataTable";
import PageHeader from "../../components/Header/PageHeader";
import { Dialog, DialogContent, DialogTitle, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import PoliceStationForm from "./PoliceStationForm";
import { getDecryptedData } from "../../api/GlobalService";

function AddPoliceStation({ history }) {
  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(3),
    },
  }));
  const [open, setOpen] = useState(false);

  const errorInitialState = {
    error: false,
    psName: {
      exist: false,
      helperText: "Police station name required",
    },
    district: {
      exist: false,
      helperText: "District required",
    },
  };

  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => {
    setRefresh((refresh) => !refresh);
  };

  return (
    // <Box                                                                       // Very handy for forms with half the viewport.
    //   sx={{
    //     bgcolor: "background.paper",
    //     width: 500,
    //     position: "relative",
    //     minHeight: 200,
    //   }}
    // >
    <>
      <Box
        sx={{
          position: "relative",
          minHeight: 200,
        }}
      >
        <PageHeader
          title={
            // getDecryptedData("role") === "SUPERVISOR"
            sessionStorage.getItem("role") === "SUPERVISOR"
              ? "Police Stations"
              : "New Police Station"
          }
          subTitle={
            // getDecryptedData("role") === "SUPERVISOR"
            sessionStorage.getItem("role") === "SUPERVISOR"
              ? "All police stations and police outposts are displayed here."
              : "Add new police station and its corresponding users."
          }
          icon={<LocalPoliceIcon fontSize="large" />}
        />
        {/* <Paper className={classes.pageContent}>
        <PoliceStationForm />
      </Paper> */}
        {/* {getDecryptedData("role") === "SUPERVISOR" ? ( */}
        {sessionStorage.getItem("role") === "SUPERVISOR" ? (
          ""
        ) : (
          <Grid container>
            {/* <Grid item>
            <Box sx={{ flexGrow: 1 }} />
          </Grid> */}
            <Box sx={{ flexGrow: 1 }} />
            <Grid item>
              <Button
                variant="contained"
                endIcon={<AddIcon sx={{ mr: 1 }} />}
                onClick={() => setOpen(true)}
                sx={{
                  margin: "25px 0",
                  display: { md: "none", lg: "none" },
                  backgroundColor: "#29335C",
                  "&:hover": {
                    background: "#015090",
                  },
                }}
              >
                Add Police Station
              </Button>
            </Grid>
          </Grid>
        )}
        <Paper sx={{ position: "relative" }}>
          {/* {getDecryptedData("role") === "SUPERVISOR" ? ( */}
          {sessionStorage.getItem("role") === "SUPERVISOR" ? (
            ""
          ) : (
            <Button
              variant="contained"
              size={"small"}
              startIcon={<AddIcon sx={{ mr: 1 }} />}
              onClick={() => setOpen(true)}
              sx={{
                display: {
                  sm: "none",
                  xs: "none",
                  lg: "inline-flex",
                  md: "inline-flex",
                },
                position: "absolute",
                right: 270,
                top: 16,
                zIndex: 1000,
                backgroundColor: "#29335C",
                "&:hover": {
                  background: "#015090",
                },
              }}
            >
              ADD PS
            </Button>
          )}
          <PoliceDataTable refresh={refresh} toggleRefresh={toggleRefresh} />
        </Paper>
        <Dialog
          open={open}
          onClose={() => setOpen(!open)}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
        // maxWidth="md"
        >
          <DialogTitle id="form-dialog-title" sx={{ margin: "auto" }}>
            {/* Add New Police Station */}
            <Typography variant="h3">Police Station Entry</Typography>
          </DialogTitle>

          <DialogContent sx={{ padding: "50px" }}>
            <PoliceStationForm
              setOpen={setOpen}
              toggleRefresh={toggleRefresh}
            />
          </DialogContent>
        </Dialog>
        {/* <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <PoliceStationForm />
        </Slide> */}
        {/* <Fab
          onClick={() => setOpen(true)}
          color="primary"
          aria-label="add"
          sx={{ position: "absolute", bottom: 1, right: 1 }}
          variant="extended"
        >
          <AddIcon />
        </Fab> */}
        {/* <Fab variant="extended" onClick={() => setOpen(true)} color="primary">
          <AddIcon sx={{ mr: 1 }} />
          Add Police Station
        </Fab> */}
      </Box>
    </>
  );
}

export default AddPoliceStation;
