import http from "./http-common";
import httpApi from "./http-common-apikey";
import httpApiApp from "./http-common-apikey-application";

const create = (data) => {
  return http.post("/register/rentalUnit", data);
};

const getLocalAutoritiesByPoliceStationId = (id) => {
  return httpApi.get("/master/LocalAuthority/" + id);
};

const getLocalities = (psCode) => {
  return httpApi.get(`/master/Locality/${psCode}`);
};

const getAllLocalities = () => {
  return httpApi.get("/master/Locality");
};

const getLocalAutoritiesByLACode = (id) => {
  return httpApi.get("/master/LocalAuthorityBycode/" + id);
};

const getLocalityByCode = (code) => {
  return httpApi.get(`/master/Locality/code/${code}`);
};

const getLocalAutorities = () => {
  return httpApi.get("/master/LocalAuthority");
};

const changeRentalUnitStatus = (lndId, rtlId) => {
  return httpApiApp.get(`/process/rentalUnit/changestatus/${lndId}/${rtlId}`);
};

const changeRentalUnitStatusByLA = (lndId, rtlId) => {
  return http.get(`/process/local/rentalUnit/changestatus/${lndId}/${rtlId}`);
};

// APIs for Anonymous user
const createAnonymous = (data) => {
  return httpApiApp.post("/process/rentalUnit", data);
};

const getLocalAutoritiesByLocalityCode = (code) => {
  return httpApi.get(`/master/LocalAuthorityByLocality/${code}`);
};

const editOrDeleteRentalUnit = (data, actionNumber) => {
  switch (actionNumber) {
    case 0:
      return http.put("/register/rentalUnit/edit", data);
    case 1:
      return http.put("/register/rentalUnit/delete", data);
    default:
      alert("failed!");
  }
};

const editOrDeleteRentalUnitAnonymous = (data, action) => {
  switch (action) {
    case 0:
      return httpApiApp.put("/process/rentalUnit/edit", data);

    case 1:
      return httpApiApp.put("/process/rentalUnit/delete", data);

    default:
      alert("failed!");
  }
};

export default {
  create,
  getLocalAutoritiesByPoliceStationId,
  getLocalAutoritiesByLACode,
  getLocalAutorities,
  changeRentalUnitStatus,
  createAnonymous,
  getLocalities,
  getAllLocalities,
  getLocalAutoritiesByLocalityCode,
  getLocalityByCode,
  editOrDeleteRentalUnit,
  editOrDeleteRentalUnitAnonymous,
  changeRentalUnitStatusByLA
};
