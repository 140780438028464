import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Typography, Box, StepIcon } from '@mui/material';

const HorizontalStepper = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepClick = (step) => {
    setActiveStep(step);
  };


  const steps = ['Landlord and Tenant Registration','Clearance Application', 'Verfication by Sub-Local Authority', 'Verfication by Local Authority'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Typography
          align="center"
          variant='subtitle1'
          sx={{
            fontFamily: "montserrat",
            fontSize:"20px",
          fontWeight:"500"
        }}>
          Register yourself as a Landlord or Tenant accordingly.<br />
          Registration is a one - time process.<br />
          Different form will appear for each role.
        </Typography>
      case 1:
        return <Typography
          align="center"
          variant='subtitle1'
          sx={{
            margin: "2rem",
            fontFamily: "montserrat",
            fontSize:"20px",
          fontWeight:"500"
          }}>Fill the Clearance Application form.<br />
              The form has to be filled by the Landlord.
          </Typography>
      case 2:
        return <Typography align="center"
          variant='subtitle1'
          sx={{
            margin: "2rem",
            fontFamily: "montserrat",
            fontSize:"20px",
          fontWeight:"500"
          }}>The Application will be processed by the Sub-Local Authority.<br />
          Processed applications will be forwarded to the Local-Authority.
        </Typography>
      case 3:
        return <Typography align="center"
          variant='subtitle1'
          sx={{
            margin: "2rem",
            fontFamily: "montserrat",
            fontSize:"20px",
          fontWeight:"500"
          }}>The Application will be processed by the Local Authority.<br />
          Successful processed applications shall be deemed verified.
        </Typography>
      default:
        return 'The Application will be processed by the Local Authority. Sucessful processed applications shall be deemed verified.';
    }
  };

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        // sx={{ margin: "4rem" }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}>
            <StepLabel
              onClick={() =>
                handleStepClick(index)
              }
              StepIconComponent={StepIcon}
            >
              <Typography sx={{ fontFamily: "rubik" }}>
              
              {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt={6}>
        {activeStep === 0 && <Typography variant="body1">{getStepContent(0)}</Typography>}
        {activeStep === 1 && <Typography variant="body1">{getStepContent(1)}</Typography>}
        {activeStep === 2 && <Typography variant="body1">{getStepContent(2)}</Typography>}
        {activeStep === 3 && <Typography variant="body1">{getStepContent(3)}</Typography>}
      </Box>
    </div>
  );
};

export default HorizontalStepper;
