// import http from "./http-common";
import http from "./http-common";
import httpApiApp from "./http-common-apikey-application";

const create = async (data) => {
  return http.post("/register/uploaddocument/", data);
};

// APIs for Anonymous user
const createAnonymous = async (data) => {
  return httpApiApp.post("/process/uploaddocument/", data);
};
export default {
  create,
  createAnonymous,
};
