import { getDecryptedData } from "./GlobalService";
import http from "./http-common";
import httpApiApp from "./http-common-apikey-application";

const getAll = () => {
  return http.get("/todos/1");
};
const getByEpicNumber = async (id) => {
  return http.get(`/register/tenantById/${id}`);
};

const create = async (data) => {
  return http.post("/register/tenant", data);
};

const addFamilyMember = async (data) => {
  return http.post("/register/tenant/family", data);
};

const getTenantFamilyMembersByEpicNumber = async (id) => {
  return http.get(`/register/tenant/family/${id}`);
};
const getTenantDomesticHelpersByEpicNumber = async (id) => {
  return http.get(`/register/tenant/domesticHelper/${id}`);
};

const getTenantDocumentsByTenantId = async (tenantid) => {
  return http.get(`/register/tenant/documents/downloaddocument/${tenantid}`);
};

/* You may move this to DocumentService*/
const getDocumentsByDocumentId = async (documentId) => {
  return http.get(`/register/downloaddocument/${documentId}`);
};

const createApplication = async (data, otpKey) => {
  console.log("otpKey::: ", otpKey);
  httpApiApp.defaults.headers["API-key"] = otpKey;
  return httpApiApp.post(`process/SaveApplication_Police`, data);
};

const createApplicationFromLoggedInUser = async (data) => {

  http.defaults.headers.common["Authorization"] = sessionStorage.getItem(
    "accessToken"
  );
  // http.defaults.headers.common["Authorization"] = getDecryptedData(
  //   "accessToken"
  // );

  return http.post(`process/SaveApplication`, data);
};

const remove = async (id) => {
  return http.put(`/register/tenant/${id}`);
};

const removeAll = async () => {
  return http.delete(`/user`);
};

const getTenantById = (id) => {
  return http.get(`/register/tenantById/${id}`);
};
const checkTenant = async (data) => {
  return http.get(`/register/Existtenant/${data}`);
};

const getTenantRegistrationForm = async (tenantId) => {
  return http.get(`/register/getTenantRegistrationform/${tenantId}`);
};

//apis for Anonymous User

const getTenantRegistrationFormAnonymous = async (tenantId) => {
  return httpApiApp.get(`/process/getTenantRegistrationform/${tenantId}`);
};

const getByEpicNumberAnonymous = async (id) => {
  return httpApiApp.get(`/process/tenant/${id}`);
};
const getTenantByIdAnonymous = (id) => {
  return httpApiApp.get(`/process/tenantById/${id}`);
};
const getTenantDomesticHelpersByEpicNumberAnonymous = async (id) => {
  return httpApiApp.get(`/process/tenant/domesticHelper/${id}`);
};
const addFamilyMemberAnonymous = async (data) => {
  return httpApiApp.post("/process/tenant/family", data);
};
const getDocumentsByDocumentIdAnonymous = async (documentId) => {
  return httpApiApp.get(`/process/downloaddocument/${documentId}`);
};

const getTenantDocumentsByTenantIdAnonymous = async (tenantid) => {
  return httpApiApp.get(
    `/process/tenant/documents/downloaddocument/${tenantid}`
  );
};
const getTenantFamilyMembersByEpicNumberAnonymous = async (id) => {
  return httpApiApp.get(`/process/tenant/family/${id}`);
};

const update = async (data) => {
  return http.put("/register/updatetenant", data);
};

const downloadFamilyMemberDocument = async (documentId) => {
  return http.get(
    `/register/family/downloaddocument/${documentId}`
  );
};

const getFamilyMemberDocuments = async (memberID) => {
  return http.get(
    `/register/family/documents/downloaddocument/${memberID}`
  );
};


const downloadFamilyMemberDocumentAnonymous = async (documentId) => {
  return httpApiApp.get(
    `/process/family/downloaddocument/${documentId}`
  );
};


const downloadHelperDocument = async (documentId) => {
  return http.get(
    `/register/tenantHelper/documents/downloaddocument/${documentId}`
  );
};

const downloadHelperDocumentAnonymous = async (documentId) => {
  return httpApiApp.get(
    `/process/tenantHelper/documents/downloaddocument/${documentId}`
  );
};

const removeHelper = async (helperId) => {
  return http.post(
    `/register/tenant/domesticHelper/remove/${helperId}`
  )
}
const removeHelperAnonymous = async (helperId) => {
  return httpApiApp.post(
    `/process/tenant/domesticHelper/remove/${helperId}`
  )
}

const removeFamilyMember = async (familyMemberId) => {
  return http.post(
    `/register/tenant/family/remove/${familyMemberId}`
  )
}
const removeFamilyMemberAnonymous = async (familyMemberId) => {
  return httpApiApp.post(
    `/process/tenant/family/remove/${familyMemberId}`
  )
}

export default {
  getByEpicNumber,
  getAll,
  create,
  createApplication,
  getTenantById,
  remove,
  removeAll,
  addFamilyMember,
  getTenantFamilyMembersByEpicNumber,
  getTenantDocumentsByTenantId,
  getDocumentsByDocumentId,
  getTenantDomesticHelpersByEpicNumber,
  createApplicationFromLoggedInUser,
  checkTenant,
  getTenantRegistrationForm,
  getTenantRegistrationFormAnonymous,
  getByEpicNumberAnonymous,
  getTenantByIdAnonymous,
  getTenantDomesticHelpersByEpicNumberAnonymous,
  addFamilyMemberAnonymous,
  getDocumentsByDocumentIdAnonymous,
  getTenantDocumentsByTenantIdAnonymous,
  getTenantFamilyMembersByEpicNumberAnonymous,
  update,
  downloadFamilyMemberDocument,
  downloadFamilyMemberDocumentAnonymous,
  getFamilyMemberDocuments,
  downloadHelperDocument,
  downloadHelperDocumentAnonymous,
  removeHelper,
  removeHelperAnonymous,
  removeFamilyMember,
  removeFamilyMemberAnonymous
};
