import * as React from "react";
import "react-data-grid/dist/react-data-grid.css";
import { useState } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { useEffect } from "react";
import {
  Button,
  Chip,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ApplicationService from "../../api/ApplicationService";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LandlordService from "../../api/LandlordService";
import { Autocomplete, CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import RentalUnitService from "../../api/RentalUnitService";
import UserService from "../../api/UserService";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import { getDecryptedData } from "../../api/GlobalService";

const useStyle = makeStyles({
  rowColor: {
    backgroundColor: "#E8EAFC",
    color: "black",
  },
});

export default function LandlordTable(props) {
  const classes = useStyle();
  const { addProcessApplication } = useContext(
    GlobalContext
  );
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedAppID, setSelectedAppID] = useState(null);
  // const role = getDecryptedData("role");
  const role = sessionStorage.getItem("role");
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [firstPage, setFirstPage] = useState(false);
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noLandlords, setNoLandlords] = useState(false);
  const [localAuthorities, setLocalAuthorities] = useState([]);
  const [localAuthPayload, setLocalAuthPayload] = useState({
    username: "",
    usercode: "",
  });
  const [selectedLocalAuth, setSelectedLocalAuth] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [applIdtoDelete, setApplIdToDelete] = React.useState(false);


  const { status, shifted } = props;

  let arr = [];
  for (var i = 1; i <= totalPages; i++) {
    arr.push(i);
  }


  const columns = [
    // {
    //   name: "landlord_id",
    //   label: "Landlord ID",
    // },
    {
      name: "firstName",
      label: "Firstname",
    },
    {
      name: "middleName",
      label: "Middlename",
    },
    {
      name: "lastName",
      label: "Lastname",
    },
    // {
    //   name: "email",
    //   label: "Email",
    // },
    {
      name: "mobile",
      label: "Mobile",
    },
    {
      name: "occupation",
      label: "Occupation",
    },
    // {
    //   name: "rentcode",
    //   label: "Rent Code",

    // },
    // {
    //   name: "landlordcode",
    //   label: "Landlord Epic",
    // },
   
  ];
  const getLandlords = async (dataForSupermoderator) => {
    if (dataForSupermoderator !== null) {
      try {
        const response = await LandlordService.getAllLandlordsByLocalityCodeForPagination(
          // getDecryptedData("username"),
          // selectedPageNumber,
          // getDecryptedData("role") === "SUPERMODERATOR"
          //   ? dataForSupermoderator.localAuthorityCode
          //   : getDecryptedData("usercode")
          sessionStorage.getItem("username"),
         
          sessionStorage.getItem("role") === "SUPERMODERATOR"
            ? dataForSupermoderator.localAuthorityCode
            : sessionStorage.getItem("usercode"), selectedPageNumber
        );

        setNoLandlords(false);

         if (lastPage !== response.data.iscurrentPageLast)
          setLastPage(!lastPage);

         if (firstPage !== response.data.iscurrentPageFirst)
           setFirstPage(!firstPage);

         if (totalPages === 0) {
           setTotalPages(response.data.totalPages);
           setLinks(response.data.content);
         }
        /* the datagrid required for each row a unique id */
         for (var i = 0; i < response.data.content.length; i++) {
           response.data.content[i].id = i;
         }

        // getApplications(response.data.content); //

        setData(response.data.content);
      } catch {
        setNoLandlords(true);
      }
    } else return;
  };

  const getLocalAuth = async (localityCode) => {
    const response = await RentalUnitService.getLocalAutoritiesByLocalityCode(
      // getDecryptedData("usercode")
      sessionStorage.getItem("usercode")
    );
    var data = [];
    data = response.data;
    for (var i = 0; i < data.length; i++) {
      data[i].id = i + 1;
      data[i].localityName = data[i].localityName;

      const userresponse = await UserService.getUserbycode(
        // sessionStorage.getItem("role") === "SUPERMODERATOR"
        //   ? data[i].localAuthorityCode
        //   : data[i].locality.localityCode
        //   ? data[i].localAuthorityCode
        data[i].localAuthorityCode
      );
      data[i].users = userresponse.data;
    }
    setLocalAuthorities(data);
  };

  const handleDelete = async () => {
    await ApplicationService.deleteApplication(applIdtoDelete)
      .then((response) => {
        alert(response.data.message)
        setRefresh(!refresh);
      })
      .catch((err) => alert(err.response.data.message));
  }

  useEffect(() => {
    // getDecryptedData("role") !== "SUPERMODERATOR"
    sessionStorage.getItem("role") !== "SUPERMODERATOR"
      ? getLandlords()
      : getLocalAuth();
    // getDecryptedData("role") === "SUPERMODERATOR" &&
    sessionStorage.getItem("role") === "SUPERMODERATOR" &&
      getLandlords(selectedLocalAuth);
  }, [selectedPageNumber, refresh]);

  // useEffect(() => {
  //   sessionStorage.getItem("role") === "SUPERMODERATOR" &&
  //     getLandlords(selectedLocalAuth);
  // }, [selectedPageNumber]);

  const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getApplication = async (id) => {
    try {
      const app = await ApplicationService.getApplication(
        id != null ? id : selectedAppID
      );
      addProcessApplication(app.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  function rowKeyGetter(row) {
    return row.id;
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
  
      {/* {getDecryptedData("role") === "SUPERMODERATOR" && ( */}
      {sessionStorage.getItem("role") === "SUPERMODERATOR" && (
        <FormControl>
          <Autocomplete
            size={"small"}
            onChange={(event, value) => {
              setToggle(() => !toggle);
              setSelectedLocalAuth(value);
              getLandlords(value);
            }}
            id="standard-basic"
            options={localAuthorities}
            getOptionLabel={(option) => option.localAuthorityName}
            sx={{
              width: { xs: 300, sm: 600, lg: 600, md: 600 },
              marginBottom: { sm: "10px", xs: "10px", lg: 1, md: 1 },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter applications by Sub-Localities *"
                variant="outlined"
              // error={errors.localAuthority.exist === true ? true : false}
              // helperText={
              //   errors.localAuthority.exist
              //     ? errors.localAuthority.helperText
              //     : ""
              // }
              />
            )}
          />
        </FormControl>
      )}

      {noLandlords ? (
        <div>No Applications</div>
      ) : (
        <div>
          <CustomDataTable
            rows={data}
            columns={columns}
            title="Landlord List"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={firstPage}
              onClick={() => setSelectedPageNumber(selectedPageNumber - 1)}
            >
              <NavigateBeforeIcon />
            </Button>
            {arr.map((number) => (
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectedPageNumber(number - 1);
                  console.log(`Page number is ${number}`);
                }}
                key={number}
              >
                {number}
              </Button>
            ))}
            <Button
              disabled={lastPage}
              onClick={() => setSelectedPageNumber(selectedPageNumber + 1)}
            >
              <NavigateNextIcon />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
