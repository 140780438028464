import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Inbox as InboxIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  useLayoutDispatch,
  toggleSidebar,
} from "../../../../context/LayoutContext";

// styles
import useStyles from "./styles";

// components
import Dot from "../Dot";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  title,
}) {
  var classes = useStyles();
  var layoutDispatch = useLayoutDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();

  // local
  const [isOpen, setIsOpen] = useState(false);
  var isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (type === "title")
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;

  if (!children)
    return (
      <Tooltip title={title}>
        <ListItem
          button
          component={link && Link}
          to={link}
          className={classes.link}
          classes={{
            root: classnames(classes.linkRoot, {
              [classes.linkActive]: isLinkActive && !nested,
              [classes.linkNested]: nested,
            }),
          }}
          disableRipple
          onClick={() => isSidebarOpened && toggleSidebar(layoutDispatch)}
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {nested ? (
              <Dot color={isLinkActive && "primary"} label={label} />
            ) : (
              icon
            )}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </ListItem>
      </Tooltip>
    );

  return (
    <>
      <Divider />

      <Tooltip title={title}>
        <ListItem
          button
          component={link && Link}
          onClick={toggleCollapse}
          // onClick={!isSidebarOpened ? handleClick : toggleCollapse}
          // onClick={() => {
          //   if (!isSidebarOpened) toggleSidebar(layoutDispatch);
          //   else if (isSidebarOpened) toggleCollapse();
          //   else toggleCollapse();
          // }}
          className={classes.link}
          to={link}
          disableRipple
          // onMouseOver={!isSidebarOpened && handleClick}
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onMouseOver={() => !isSidebarOpened && toggleSidebar(layoutDispatch)}
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {icon ? icon : <InboxIcon />}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </ListItem>
      </Tooltip>
      <Menu
        dense
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        PaperProps={{
          elevation: 0,

          sx: {
            backgroundColor: "#29335C",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: -1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              // bgcolor: "background.paper",
              bgcolor: "#29335C",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {children.map((childrenLink) => (
          <MenuItem
            sx={{
              color: "#fff",
              "&:hover": {
                color: "#F3A712",
              },
            }}
            key={childrenLink && childrenLink.link}
            component={link && Link}
            to={childrenLink.link}
            onClick={() => {
              history.push(childrenLink.link);
            }}
          >
            <ListItemText inset>{childrenLink.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
      <Divider />
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
