import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import JSEncrypt from "jsencrypt";
import React, { useEffect, useState } from "react";
import http from "../../api/http-common";
import UserService from "../../api/UserService";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UserSignUpForm = ({
  rowParams,
  policeStationList,
  toggleRefresh,
  setOpenDialog,
  snackBar,
  setSnackBar,
}) => {
  console.log("RowParams :: ", rowParams);
  const [publicKey, setPublicKey] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);

  const errorInitialState = {
    error: false,
    username: {
      exist: false,
      helperText: "Username required",
    },
    email: {
      exist: false,
      helperText: "Email required",
    },
    password: {
      exist: false,
      helperText: "Password required",
    },
    role: {
      exist: false,
      helperText: "Role required",
    },
  };

  const [errors, setErrors] = useState(errorInitialState);

  const [policeUser, setPoliceUser] = useState({
    username: "",
    password: "",
    usercode: "",
    role: [{}],
    email: "",
  });

  const getRoles = () => {
    UserService.getRoles()
      .then((response) => {
        setRoles(response.data);
      })
      .catch((e) => {
        console.error(e);
        // alert(JSON.stringify(e.response.data));
      });
  };

  const handleRoleChange = (event, srole) => {
    // console.log(event.target.value);
    // let value = event.target.value;
    console.log("SROLE:: ", srole);

    let data = roles.find((role) => role.name === srole.name);
    console.log(data);

    setPoliceUser((prevState) => ({
      ...prevState,
      role: [data],
    }));
    // policeUser.role[0] = data;
    //  console.log(policeUser);

    // const api: GridApi = rowParams.api;
    // const fields = api
    //   .getAllColumns()

    //   .map((c) => c.field)
    //   .filter((c) => c !== "__check__" && !!c);
    // const thisRow: Record<string, GridCellValue> = {};
    // fields.forEach((f) => {
    //   thisRow[f] = rowParams.getValue(f);
    // });

    // let psCode = JSON.stringify(thisRow, null, 4)

    let policeStation = policeStationList.filter(
      (ps) => ps.policeStationName === rowParams[1]
    );

    console.log("Required Ps: ", policeStation);

    // policeUser.usercode = thisRow.id

    setPoliceUser((prevState) => ({
      ...prevState,
      usercode: policeStation[0].policeStationCode,
    }));
  };

  useEffect(() => {
    UserService.getPublicKey()
      .then((response) => setPublicKey(response.data))
      .catch((err) => console.log(err));
    getRoles();
  }, []);

  const validateUserData = () => {
    const e = errorInitialState;
    if (policeUser.username === "") {
      e.error = true;
      e.username.exist = true;
    }
    // if (policeUser.email === "") {
    //   e.error = true;
    //   e.email.exist = true;
    // }
    if (policeUser.password === "") {
      e.error = true;
      e.password.exist = true;
    }

    return e;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const errs = validateUserData();

    if (errs.error === true) {
      setErrors(errs);
      setSnackBar({
        message: "Validation Errors!",
        severity: "error",
        open: true,
      });
      return;
    }

    let crypt = new JSEncrypt();
    crypt.setPublicKey(publicKey);
    let enc = crypt.encrypt(policeUser.password);
    console.log("enc is : ", enc);

    // setPoliceUser({
    //   ...policeUser,
    //   password: enc,
    // });

    let userData = { ...policeUser };
    userData.password = enc;
    // if (policeUser.role[0].name === "USER") {
    //   userData.level = {
    //     id: 3,
    //     level: "POLICE STATION",
    //   };
    // }

    console.log("userdata: ", userData);

    //encrypt the password here.

    // e.preventDefault();
    UserService.signUp(userData)
      .then((response) => {
        console.log("Response for PoliceUser Entry is : ", response);
        // alert("Successfully Created User");
        setSnackBar({
          open: true,
          message: "Successfully Created User",
          severity: "success",
        });
        // history.push("/app/dashboard");
        //   setError(null)
        //   setIsLoading(false);

        // history.push("/app/dashboard");
        //   dispatch({ type: "LOGIN_SUCCESS" });
      })
      .then(() => {
        toggleRefresh();
        setOpenDialog(false);
      })
      .catch((e, response) => {
        // alert(response.)
        console.error(e);
        alert(JSON.stringify(e.response.data));
        //   setError(true);
        //   setIsLoading(false);
        //dispatch({ type: "LOGIN_FAILURE" }); // No need this, if enable it will reset the tabIndex State
      });
  };

  return (
    <>
      <Stack>
        {/* <Item> */}
        <TextField
          autoFocus={true}
          size={"small"}
          margin="dense"
          label="Username"
          type="text"
          fullWidth
          value={policeUser.username}
          onChange={(e) => {
            setPoliceUser({
              ...policeUser,
              username: e.target.value,
            });
          }}
          error={errors.username.exist === true ? true : false}
          helperText={errors.username.exist ? errors.username.helperText : ""}
        />

        <TextField
          size={"small"}
          margin="dense"
          label="Email"
          type="text"
          fullWidth
          value={policeUser.email}
          onChange={(e) => {
            setPoliceUser({
              ...policeUser,
              email: e.target.value,
            });
          }}
          error={errors.email.exist === true ? true : false}
          helperText={errors.email.exist ? errors.email.helperText : ""}
        />

        {/* </Item> */}
        {/* <Item> */}

        <TextField
          size={"small"}
          margin="dense"
          label="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          value={policeUser.password}
          InputProps={{
            // classes: {
            //   underline: classes.textFieldUnderline,
            //   input: classes.textField,
            // },
            // className: classes.textColor,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setPoliceUser({
              ...policeUser,
              password: e.target.value,
            });
            console.log("entered!!");
          }}
          error={errors.password.exist === true ? true : false}
          helperText={errors.password.exist ? errors.password.helperText : ""}
        />

        {/* </Item> */}

        {/* <select
          id="role"
          onChange={(event) => {
            handleRoleChange(event);
          }}
          style={{ height: "40px" }}
        >
          <option value="select" selected>
            {" "}
            ----{" "}
          </option>
          {roles.map((role) => (
            <option value={role.name} key={role.id}>
              {role.name}
            </option>
          ))}
        </select> */}
        {/* <Item> */}
        <Autocomplete
          size={"small"}
          onChange={(event, value) => {
            handleRoleChange(event, value);
          }}
          id="role"
          options={roles}
          getOptionLabel={(option) => option.rolename}
          renderInput={(params) =>
            roles.length === 0 ? (
              <CircularProgress size={22} />
            ) : (
              <TextField
                margin="dense"
                name="dist"
                {...params}
                label="Role *"
                variant="outlined"
              />
            )
          }
          error={errors.role.exist === true ? true : false}
          helperText={errors.role.exist ? errors.role.helperText : ""}
        />
        {/* </Item> */}
        {/* <Item> */}
        <Box sx={{ width: "100%", p: 1, my: 0.5 }} />
        <Button
          size="small"
          type="submit"
          onClick={handleClick}
          variant="contained"
          color="secondary"
          // className={classes.policeinputs}
        >
          Create User
        </Button>
        {/* </Item> */}
      </Stack>
    </>
  );
};

export default UserSignUpForm;
