import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import UserService from "../../api/UserService";
import { GlobalContext } from "../../context/GlobalState";
import { getDecryptedData } from "../../api/GlobalService";

const EmailUpdateForm = ({ title, subheader, placeHolder }) => {
  const [newEmail, setNewEmail] = useState("");
  const { openSnackBar } = useContext(GlobalContext);

  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    // await UserService.updateEmail(getDecryptedData("username"), newEmail)
    await UserService.updateEmail(sessionStorage.getItem("username"), newEmail)
      .then((response) => {
        openSnackBar({
          open: true,
          message: response.data.message,
          severity: "success",
        });
      })
      .catch((err) => {
        openSnackBar({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  };

  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
          }}
        >
          <FormControl
            sx={{
              display: {
                lg: "inline-flex",
                md: "inline-flex",
                sm: "inline-flex",
                xs: "block",
              },
              width: { lg: "70%", md: "70%", sm: "50%", xs: "100%" },
            }}
          >
            <TextField
              size={"small"}
              id="text"
              margin="normal"
              placeholder={placeHolder}
              type="email"
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </FormControl>
          <CardActions>
            <Button
              variant="outlined"
              sx={{ marginTop: "7px" }}
              onClick={handleUpdateEmail}
            >
              Update
            </Button>
          </CardActions>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EmailUpdateForm;
