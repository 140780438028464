import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";

export default class MrssPdf extends Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }
  state = { numPages: null, pageNumber: 1 };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <>
        {/* <Box sx={{ margin: "auto", align: "center" }} align="center">
          <Stack spacing={2} direction="row">
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={this.goToPrevPage}
              disabled={this.state.pageNumber === 1}
            >
              Prev
            </Button>

            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={this.goToNextPage}
              disabled={this.state.pageNumber === numPages}
            >
              Next
            </Button>
          </Stack>
        </Box> */}
        <Document
          file={process.env.PUBLIC_URL + "/MRSS.pdf"}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={1200}
              height={10}
            />
          ))}
          {/* <Page pageNumber={pageNumber} width={1500} />  for single page */}
        </Document>
        {/* <Grid container direction="row">
          <Grid item>
            <Stack spacing={2} direction="row">
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={this.goToPrevPage}
                disabled={this.state.pageNumber === 1}
              >
                Prev
              </Button>

              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={this.goToNextPage}
                disabled={this.state.pageNumber === numPages}
              >
                Next
              </Button>
            </Stack>
          </Grid>
          <Box sx={{ flexGrow: 0.4 }} />
          <Grid item>
            Page {pageNumber} of {numPages}
          </Grid>
        </Grid> */}
      </>
    );
  }
}
