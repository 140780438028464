import React, { useContext, useEffect, useState } from "react";
import { IconButton, InputAdornment, Button } from "@material-ui/core";
import {
  Alert,
  Autocomplete,
  Card,
  FormControl,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Delete, Visibility, VisibilityOff } from "@mui/icons-material";
import MasterData from "../../api/MasterData";
import UserService from "../../api/UserService";
import JSEncrypt from "jsencrypt";
import PageHeader from "../../components/Header/PageHeader";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { GlobalContext } from "../../context/GlobalState";
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";

const levels = [
  { levelId: 1, level: "LANDLORD" },
  { levelId: 2, level: "LOCAL AUTHORITY" },
  { levelId: 3, level: "POLICE STATION" },
  { levelId: 4, level: "DISTRICT ADMIN" },
  { levelId: 5, level: "STATE ADMIN" },
];

const Users = () => {
  const { openSnackBar } = useContext(GlobalContext);
  const [username, setUsername] = useState("");
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    role: [{}],
    // level: null,
    usercode: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [publicKey, setPublicKey] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [roles, setRoles] = useState([]);

  const getStates = async () => {
    // get district for permanent Address
    const response = await MasterData.getStates();
    setStates(response.data);
  };

  const getDistricts = async (stateCode) => {
    // get district for permanent Address
    const response = await MasterData.getDistrictsByStateId(stateCode);
    setDistricts(response.data);
  };

  const getRoles = () => {
    UserService.getRoles()
      .then((response) => {
        setRoles(response.data);
      })
      .catch((e) => {
        console.error(e);
        // alert(JSON.stringify(e.response.data));
      });
  };

  const handleStateChange = (event, code) => {
    if (code !== null) {
      getDistricts(code.stateCode);

      //   setPs({ ...ps, dist: code.districtCode });
    } else {
      //   setPs({ ...ps, dist: "" });
    }
  };

  const handleDistrictChange = (event, code) => {
    if (code !== null) {
      setUser({ ...user, usercode: code.districtCode });
    } else {
      //   setPs({ ...ps, dist: "" });
    }
  };

  const handleRoleChange = (event, srole) => {
    console.log("SROLE:: ", srole);

    // let data = roles.find((role) => role.name === srole.name);
    // console.log(data);

    setUser((prevState) => ({
      ...prevState,
      role: [srole],
    }));

    console.log("USER DATA:: ", user);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    let crypt = new JSEncrypt();
    crypt.setPublicKey(publicKey);
    let enc = crypt.encrypt(user.password);

    let userData = { ...user };
    userData.password = enc;

    console.log("userdata: ", userData);

    //encrypt the password here.

    UserService.signUp(userData)
      .then((response) => {
        console.log("Response for PoliceUser Entry is : ", response);
        // alert("Successfully Created User");
        openSnackBar({
          message: "Successfully Created User.",
          open: true,
          severity: "success",
        });
      })
      .catch((e, response) => {
        console.error(e);
        // alert(JSON.stringify(e.response.data));
        openSnackBar({
          message: e.response.data.message,
          open: true,
          severity: "error",
        });
      });
  };

  const handleDelete = () => {
    UserService.deleteUser(username)
      .then((response) => {
        setOpenConfirmDialog(false);
        openSnackBar({
          message: response.data.message,
          severity: "success",
          open: true,
        });
        setUsername("");
      })
      .catch((err) => {
        setOpenConfirmDialog(false);
        openSnackBar({
          message: err.response.data.message,
          severity: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    getStates();
    getRoles();
    UserService.getPublicKey()
      .then(function (response) {
        setPublicKey(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Card
        sx={{
          width: { lg: "50%", md: "50%" },
          margin: "auto",
          padding: "20px",
        }}
      >
        <PageHeader
          title="Create Users"
          subTitle="Create District Admins & State Admins here."
          icon={<PeopleAltIcon fontSize="large" />}
        />
        <TextField
          autoFocus={true}
          size={"small"}
          margin="dense"
          label="Username"
          type="text"
          fullWidth
          value={user.username}
          onChange={(e) => {
            setUser({
              ...user,
              username: e.target.value,
            });
          }}
        />

        <TextField
          // autoComplete="off"

          id="text"
          name="password"
          autoComplete="new-password"
          size={"small"}
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={user.email}
          onChange={(e) => {
            setUser({
              ...user,
              email: e.target.value,
            });
          }}
        />

        {/* </Item> */}
        {/* <Item> */}

        <TextField
          size={"small"}
          margin="dense"
          label="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          value={user.password}
          InputProps={{
            // classes: {
            //   underline: classes.textFieldUnderline,
            //   input: classes.textField,
            // },
            // className: classes.textColor,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                  color="primary"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setUser({
              ...user,
              password: e.target.value,
            });
          }}
        />
        <Autocomplete
          size={"small"}
          onChange={(event, value) => {
            handleStateChange(event, value);
          }}
          id="state"
          options={states}
          getOptionLabel={(option) => option.stateName}
          renderInput={(params) => (
            <TextField
              margin="dense"
              name="state"
              {...params}
              label="State *"
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          size={"small"}
          onChange={(event, value) => {
            handleDistrictChange(event, value);
          }}
          id="district"
          options={districts}
          getOptionLabel={(option) => option.districtName}
          renderInput={(params) => (
            <TextField
              margin="dense"
              name="dist"
              {...params}
              label="District *"
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          size={"small"}
          onChange={(event, value) => {
            handleRoleChange(event, value);
          }}
          id="role"
          options={roles}
          getOptionLabel={(option) => option.rolename}
          renderInput={(params) => (
            <TextField
              margin="dense"
              name="dist"
              {...params}
              label="Role *"
              variant="outlined"
            />
          )}
        />
        {/* <Autocomplete
        size={"small"}
        onChange={(event, value) => {
          setUser({ ...user, level: value.level });
        }}
        id="sub-role"
        options={[
          {
            name: "DC",
            level: levels[3],
          },
          {
            name: "SP",
            level: levels[4],
          },
        ]}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            margin="dense"
            name="level"
            {...params}
            label="Level *"
            variant="outlined"
          />
        )}
      /> */}
        {/* </Item> */}
        {/* <Item> */}
        <Box sx={{ width: "100%", p: 1, my: 0.5 }} />
        <Button
          size="small"
          onClick={handleClick}
          variant="contained"
          color="secondary"
          sx={{ margin: "auto" }}
        >
          Create User
        </Button>
      </Card>
      <Box minHeight={20} />
      <Card
        sx={{
          width: { lg: "50%", md: "50%" },
          margin: "auto",
          padding: "20px",
        }}
      >
        <PageHeader
          title="Delete Users"
          subTitle="Delete user-accounts of any stakeholder here."
          icon={<PeopleAltIcon fontSize="large" />}
        />
        <Box
          sx={{
            display: "flex",
            gap: 3,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControl>
            <TextField
              autoFocus={true}
              size={"small"}
              margin="dense"
              label="User ID"
              type="text"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <Button
              disabled={username === ""}
              onClick={() => setOpenConfirmDialog(true)}
              variant="contained"
              size="small"
              startIcon={<Delete />}
              color="primary"
            >
              Delete
            </Button>
          </FormControl>
        </Box>
      </Card>
      <ConfirmDialog
        text="Deletion is irreversible. Are you sure you want to delete this user?"
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Users;
