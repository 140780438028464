import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Button,
  Slide,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Link,
  Snackbar,
  useMediaQuery,
  Theme
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";

import MenuIcon from "@mui/icons-material/Menu";

// context
import { useUserDispatch, useUserState } from "../../context/UserContext";

import http from "./../../api/http-common";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LandlordStepperForm from "./LandlordStepperForm";
import TenantStepperForm from "./TenantStepperForm";
import CreateApplication from "./CreateApplication";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Tooltip, Zoom } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import MrssPdf from "../../components/PDFs/MRSS";
import Container from "@mui/material/Container";
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/core/ButtonUnstyled";
import { styled } from "@mui/system";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { AppBar } from "@material-ui/core";
import UserService from "../../api/UserService";
import MuiAlert from "@mui/material/Alert";
import LoginDialog from "./LoginDialog";
import Footer from "../../components/Footer/Footer";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import TrackApplicationDialog from "./TrackApplicationDialog";
import InfoIcon from "@mui/icons-material/Info";
import PreReqDialog from "../../components/pre-req-dialog/PreReqDialog";
import Prompt from "../../components/Prompt";
import ScrollToTop from "react-scroll-to-top";
import classNames from "classnames";
import VideoManuals from "../../components/Footer/VideoManuals";
import { GlobalContext } from "../../context/GlobalState";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import HorizontalStepper from "./HorizontalStepper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ButtonRoot = React.forwardRef(function ButtonRoot(props, ref) {
  const { children, ...other } = props;

  return (
    <svg width="80" height="35" {...other} ref={ref}>
      {/* Using <rect> element with rounded corners for border-radius */}
      <rect x="0" y="0" width="80" height="35" rx="5" ry="5" className="bg" />
      <polygon points="0,35 0,0 80,0 80,35" className="borderEffect" />
      <foreignObject x="0" y="0" width="80" height="35">
        <div className="content">{children}</div>
      </foreignObject>
    </svg>

    //  <svg width="100" height="30" {...other} ref={ref}>
    //   <polygon points="0,30 0,0 100,0 100,30" className="bg" />
    //   <polygon points="0,30 0,0 100,0 100,30" className="borderEffect" />
    //   <foreignObject x="0" y="0" width="100" height="30">
    //     <div className="content">{children}</div>
    //   </foreignObject>
    // </svg>
  );
});



ButtonRoot.propTypes = {
  children: PropTypes.node,
};

const CustomButtonRoot = styled(ButtonRoot)(
  ({ theme }) => `
  overflow: visible;
  cursor: pointer;
  --main-color: ${theme.palette.mode === "light" ? "rgb(25,118,210)" : "rgb(144,202,249)"
    };
  --hover-color: ${theme.palette.mode === "light"
      ? "rgba(25,118,210,0.04)"
      : "rgba(144,202,249,0.08)"
    };
  --active-color: ${theme.palette.mode === "light"
      ? "rgba(25,118,210,0.12)"
      : "rgba(144,202,249,0.24)"
    };

  & polygon {
    fill: transparent;
    transition: all 800ms ease;
    pointer-events: none;
  }
  
  & .bg {
    stroke: var(--main-color);
    stroke-width: 0.5;
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.1));
    fill: transparent;
  }

  & .borderEffect {
    stroke: var(--main-color);
    stroke-width: 2;
    stroke-dasharray: 150 600;
    stroke-dashoffset: 150;
    fill: transparent;
  }

  &:hover,
  &.${buttonUnstyledClasses.focusVisible} {
    .borderEffect {
      stroke-dashoffset: -600;
    }

    .bg {
      fill: var(--hover-color);
    }
  }

  &:focus,
  &.${buttonUnstyledClasses.focusVisible} {
    outline: none;
  }

  &.${buttonUnstyledClasses.active} { 
    & .bg {
      fill: var(--active-color);
      transition: fill 300ms ease-out;
    }
  }

  & foreignObject {
    pointer-events: none;

    & .content {
      font-family: Helvetica, Inter, Arial, sans-serif;
      font-size: 18px;
      font-weight: 200;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--main-color);
      // text-transform: uppercase;
    }

    & svg {
      margin: 0 5px;
    }
  }`
);

const steps = [
  {
    content: (
      <h2>
        {" "}
        Welcome to MRSSA Online Application. I am here to guide you. Let's begin
        our journey!
      </h2>
    ),
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: "center",
    target: "body",
  },
  {
    content: <h2>Login as an official user.</h2>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".login",
  },
  {
    content: <h2>Get updates of an applied application.</h2>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".trackApplication",
  },
  {
    content: <h2>Register or Login as a Landlord.</h2>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".regLandlord",
  },
  {
    content: <h2>Register or Login as a Tenant.</h2>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".regTenant",
  },
  {
    content: <h2>Apply an Application.</h2>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: ".clearanceApplication",
  },
  // {
  //   content: "These are our super awesome projects!",
  //   placement: "bottom",
  //   styles: {
  //     options: {
  //       width: 300,
  //     },
  //   },
  //   target: ".demo__projects h2",
  //   title: "Our projects",
  // },
  // {
  //   content: (
  //     <div>
  //       You can render anything!
  //       <br />
  //       <h3>Like this H3 title</h3>
  //     </div>
  //   ),
  //   placement: "top",
  //   target: ".demo__how-it-works h2",
  //   title: "Our Mission",
  // },
  // {
  //   content: (
  //     <div>
  //       <h3>All about us</h3>
  //       <svg
  //         width="50px"
  //         height="50px"
  //         viewBox="0 0 96 96"
  //         xmlns="http://www.w3.org/2000/svg"
  //         preserveAspectRatio="xMidYMid"
  //       >
  //         <g>
  //           <path
  //             d="M83.2922435,72.3864207 C69.5357835,69.2103145 56.7313553,66.4262214 62.9315626,54.7138297 C81.812194,19.0646376 67.93573,0 48.0030634,0 C27.6743835,0 14.1459311,19.796662 33.0745641,54.7138297 C39.4627778,66.4942237 26.1743334,69.2783168 12.7138832,72.3864207 C0.421472164,75.2265157 -0.0385432192,81.3307198 0.0014581185,92.0030767 L0.0174586536,96.0032105 L95.9806678,96.0032105 L95.9966684,92.1270809 C96.04467,81.3747213 95.628656,75.2385161 83.2922435,72.3864207 Z"
  //             fill="#000000"
  //           />
  //         </g>
  //       </svg>
  //     </div>
  //   ),
  //   placement: "left",
  //   target: ".demo__about h2",
  // },
];
function Login(props) {
  var classes = useStyles();
  // const search = useLocation().search;
  // const string = new URLSearchParams(search).get("string");
  // console.log("string: ", string);
  // sessionStorage.setItem("string", string);

  // global
  var userDispatch = useUserDispatch();
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  var [activeTabId, setActiveTabId] = useState(0);
  const [loginValue, setLoginValue] = useState("");
  var [captchaValue, setCaptchaValue] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const { backgroundFlag } = useUserState();
  const [createAppFlag, setCreateAppFlag] = useState(false);
  const [openTrackApp, setOpenTrackApp] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  // const [width, setWidth] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [forgotPswd, setForgotPswd] = useState(false);
  const [username, setUsername] = useState("");
  const [otpPswdChnge, setOtpPswdChnge] = useState("");
  const [newPswd, setNewPswd] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [openForever, setOpenForever] = useState(true);
  const [passwordValue, setPasswordValue] = useState("");
  const [lndOrTntLogin, setLndOrTntLogin] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [openPreReq, setOpenPreReq] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [openManuals, setOpenManuals] = useState(false);
  const { snackbar, openSnackBar } = useContext(GlobalContext);
  const [openDownloadForms, setOpenDownloadForms] = useState(false);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const history = useHistory(); // Hook to handle navigation


  sessionStorage.removeItem("_expiredTime");

  const SvgButton = React.forwardRef(function SvgButton(props, ref) {
    return (
      <ButtonUnstyled
        {...props}
        component={CustomButtonRoot}
        ref={ref}
        onClick={() => {
          setOpenLoginDialog(true);
        }}
      />
    );
  });

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setOpenDownloadForms(false);
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClickOpenDownloadForms = () => {
    setOpenDownloadForms(!openDownloadForms);
  };

  const getPublicKey = () => {
    http
      .get("users/getPublicKey")
      .then(function (response) {
        setPublicKey(response.data);
        // loginUser(
        //   // userCredentialsData
        //   setAccessToken,
        //   response.data,
        //   userDispatch,
        //   "admin",
        //   "admin",
        //   props.history,
        //   setIsLoading,
        //   setError,
        //   setActiveTabId,
        //   backgroundFlag
        // );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      openLoginDialog &&
      error === null &&
      localStorage.getItem("accessToken") === null
    ) {
      getPublicKey();
      UserService.getCaptcha()
        .then((response) => {
          handleDownload(response.data, "image/jpg");
        })
        .catch((err) => {
          console.log("Error in Captcha: ", err);
          // handleDownload(err.response.data, "image/jpg");
        });
    } else if (openLoginDialog && error === true) {
      getPublicKey();
      UserService.getCaptcha()
        .then((response) => {
          handleDownload(response.data, "image/jpg");
        })
        .catch((err) => {
          console.log("Error in captcha: ", err);
          // handleDownload(err.response.data, "image/jpg");
        });
    } else {
      getPublicKey();
    }
  }, [openLoginDialog, error]);

  useEffect(() => {
    setOpenPreReq(true);
  }, []);

  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
  //     setWidth(window.innerWidth);
  //     // return (_) => {
  //     //   window.removeEventListener("resize", () => {
  //     //     console.log(
  //     //       "resized to now: ",
  //     //       window.innerWidth,
  //     //       "x",
  //     //       window.innerHeight
  //     //     );
  //     //     setWidth(window.innerWidth);
  //     //   });
  //     // };
  //   });
  // }, []);

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  const handleClickOpen = () => {
    setOpen(true);
    // history.push('/landlord-login')
  };
  const handleClose = () => {
    sessionStorage.removeItem("tenantId");
    sessionStorage.removeItem("tenantEpicNo");
    // setLoginValue("");
    setActiveStep(0);
    setCreateAppFlag(false);
    setFlag(false);
    setOpen(false);
    setLndOrTntLogin(false);
  };

  const handleDownload = async (base64img, type) => {
    //   const base64Pdf = await TenantService.getDocumentsByDocumentId(props.row.docid);
    var blob = base64toBlob(base64img, type);
    const blobUrl = URL.createObjectURL(blob);

    setCaptcha(blobUrl);
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blob);
    // } else {
    //   const blobUrl = URL.createObjectURL(blob);
    //   window.open(blobUrl);
    // }
  };
  function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link
          sx={{
            cursor: "pointer",
          }}
          target="_blank"
          href={process.env.PUBLIC_URL + "/Form A.pdf"}
          rel="noreferrer"
          download="Form A"
        >
          Download Form-A
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link
          sx={{
            cursor: "pointer",
          }}
          target="_blank"
          href={process.env.PUBLIC_URL + "/Form B.pdf"}
          rel="noreferrer"
          download="Form B"
        >
          Download Form-B
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link
          sx={{
            cursor: "pointer",
          }}
          target="_blank"
          href={process.env.PUBLIC_URL + "/Acknowledgements.pdf"}
          rel="noreferrer"
          download="Acknowledgements"
        >
          Download Acknowledgements
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link
          sx={{
            cursor: "pointer",
          }}
          target="_blank"
          href={process.env.PUBLIC_URL + "/MRSS.pdf"}
          rel="noreferrer"
          download="MRSSA"
        >
          Download MRSS Act and Rules
        </Link>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          setCreateAppFlag(true);
          handleMobileMenuClose();
          // handleClickOpen();
        }}
      >
        <IconButton
          size="large"
          // aria-label="show 4 new mails"
          color="inherit"
        >
          {/* <Badge badgeContent={4} color="error"> */}
          <AppRegistrationIcon />
          {/* </Badge> */}
        </IconButton>
        <p>Apply for Clearance</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenTrackApp(true);
          handleMobileMenuClose();
        }}
      >
        <IconButton size="large" color="inherit">
          <FindInPageIcon />
        </IconButton>
        <p>Track Application</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClickOpen();
          handleMobileMenuClose();
        }}
      >
        <IconButton
          size="large"
          // aria-label="show 17 new notifications"
          color="inherit"
        >
          {/* <Badge badgeContent={17} color="error"> */}
          <ManageAccountsIcon />
          {/* </Badge> */}
        </IconButton>
        <p>Register &nbsp; / &nbsp; Login as Landlord</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setFlag(!flag);
          handleClickOpen();
          handleMobileMenuClose();
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Register&nbsp;/&nbsp;Login as Tenant</p>
      </MenuItem>
      <ListItemButton onClick={handleClickOpenDownloadForms}>
        <ListItemIcon sx={{ paddingLeft: 1.4 }}>
          <FileDownloadIcon />
        </ListItemIcon>
        <ListItemText primary="Download Forms" />
        {openDownloadForms ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openDownloadForms} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText>
              <Link
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "lightgray",
                }}
                target="_blank"
                href={process.env.PUBLIC_URL + "/Form A.pdf"}
                rel="noreferrer"
                download="Form A"
              >
                Download Form-A
              </Link>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText>
              <Link
                sx={{
                  cursor: "pointer",
                }}
                target="_blank"
                href={process.env.PUBLIC_URL + "/Form B.pdf"}
                rel="noreferrer"
                download="Form B"
              >
                Download Form-B
              </Link>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText>
              <Link
                sx={{
                  cursor: "pointer",
                }}
                target="_blank"
                href={process.env.PUBLIC_URL + "/MRSS.pdf"}
                rel="noreferrer"
                download="MRSSA"
              >
                Download MRSS Act
              </Link>
            </ListItemText>
          </ListItemButton>
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText>
              <Link
                sx={{
                  cursor: "pointer",
                }}
                target="_blank"
                href={process.env.PUBLIC_URL + "/Acknowledgements.pdf"}
                rel="noreferrer"
                download="Acknowledgements"
              >
                Download Acknowledgements
              </Link>
            </ListItemText>
          </ListItemButton>
        </List>
      </Collapse>
      <MenuItem
        onClick={() => {
          setOpenLoginDialog(true);
          handleMobileMenuClose();
        }}
      >
        <IconButton
          size="large"
          // aria-label="show 17 new notifications"
          color="inherit"
        >
          {/* <Badge badgeContent={17} color="error"> */}
          <ManageAccountsIcon />
          {/* </Badge> */}
        </IconButton>
        <p>Login</p>
      </MenuItem>
    </Menu>
  );

  function ScrollTop(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#back-to-top"
      );

      if (anchor) {
        anchor.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    };

    return (
      <Zoom in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          {children}
        </Box>
      </Zoom>
    );
  }

  ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

  const handleCloseSandesSnackbar = () => {
    setOpenForever(false);
  };

  return (
    <div
    // style={{
    //   maxWidth: "100%"
    // }}
    // maxWidth={false}
    >
      {/* {dimensions.width > 600 && (
        <Joyride
          // callback={this.handleJoyrideCallback}
          continuous={true}
          // getHelpers={this.getHelpers}.
          run={openPreReq}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )} */}
      < Grid container direction="column" lg="auto" >
        {/* banner */}
        {/* < img
          src={banner}
          alt="Banner"
          style={{ width: "100%", height: "auto" }}
        /> */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ margin: "1rem 1rem" }}

        >

          <Box
            sx={{
              height: "5rem",
              width: "5rem"
            }}>
            <img src="static/logo.png" alt="logo" width="100%" />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "inline"
              }
            }}
          >
            <Typography
              variant="h1"
              style={{
                fontFamily: "playfair display",
                fontStyle: "italic",
                fontWeight: "bold",
                letterSpacing: 1,
                color: "#a52a2a"
              }}>
              Meghalaya Residents Safety & Security Act
            </Typography>
          </Box>
          <Box sx={{ display: { lg: "none", xs: "inline", sm: "inline", md: "none" } }}>
            <Typography
              variant="h2"
              style={{
                letterSpacing: 1,
                fontFamily: "playfair display",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#a52a2a"
              }}
            >MRSSA</Typography>
          </Box>

          <Box >

            <Tooltip
              title="Login as authorized user."
              placement="top-start"
              arrow

            >
              <SvgButton>Login</SvgButton>
            </Tooltip>
          </Box>
        </Box>
        < Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={() => openSnackBar({ open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              openSnackBar({ open: false });
            }}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar >
        {/* <Snackbar
            open={openForever}
            autoHideDuration={6000}
            // onClose={handleCloseg}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            // key={"top" + "right"}
            // onClose={handleCloseSandesSnackbar}      //commenting this as it closes snackbar on click of other components
          >
            <Alert
              severity="info"
              sx={{ width: "100%" }}
              onClose={handleCloseSandesSnackbar}
              style={{ backgroundColor: "#EE4140" }}
            >
              <AlertTitle>Important</AlertTitle>
              <Typography variant="h4">
                Please install Sandes mobile app in order to receive OTP.
              </Typography>
            </Alert>
          </Snackbar> */}
        < ScrollToTop smooth color="#29335C" />

        {/* <Grid
          item
          style={{
            backgroundColor: "transparent",
          }}
        >
          <marquee
            scrolldirection="left"
            style={{
              color: "#EE4140",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoIcon style={{ color: "#EE4140" }} />
              <span>
                <b>
                  Please install Sandes mobile app from App Store in order to
                  receive OTP.
                </b>
              </span>
            </div>
          </marquee>
        </Grid> */}

        {/* appbar for desktop */}
        <AppBar position="static" color="primary" style={{ position: "sticky", top: 0, zIndex: 200 }}>
          <Toolbar sx={{ position: "relative" }} >
            <Box display="flex" justifyContent="center" alignItems="center" gap={3} sx={{ width: "100%", display: { xs: "none", md: "flex" } }}>
              <Tooltip
                title="Know the status of an application"
                placement="top-start"
                arrow
              >
                <Typography
                  onClick={() => setOpenTrackApp(true)}
                  className={classNames(
                    classes.navbarButton,
                    "trackApplication"
                  )}
                >
                  Track Application
                </Typography>
              </Tooltip>
              <Tooltip
                title="Enter landlord details"
                placement="top-start"
                arrow
              >
                <Typography

                  onClick={handleClickOpen}
                  // className="regLandlord"
                  className={classNames(
                    classes.navbarButton,
                    "regLandlord"
                  )}
                >
                  Register / Login as Landlord
                </Typography>
              </Tooltip>
              <Tooltip
                title="Enter tenant details"
                placement="top-start"
                arrow
              >
                <Typography

                  color="red"
                  onClick={() => {
                    setFlag(!flag);
                    handleClickOpen();
                  }}
                  // className="regTenant"
                  className={classNames(
                    classes.navbarButton,
                    "regTenant"
                  )}
                >
                  Register / Login as Tenant
                </Typography>
              </Tooltip>
              {/* <Tooltip
                  title="Enter hosteller details"
                  placement="top-start"
                  arrow
                >
                  <Typography
                    
                    onClick={handleClickOpen}
                    // className="regLandlord"
                    className={classNames(
                      classes.navbarButton,
                      "regLandlord"
                    )}
                  >
                    Register / Login as Hosteller
                  </Typography>
                </Tooltip> */}
              <Tooltip
                title="Create an application"
                placement="top-start"
                arrow
              >
                <Typography

                  onClick={() => {
                    setCreateAppFlag(true);
                    // handleClickOpen();
                  }}
                  // className="clearanceApplication"
                  className={classNames(
                    classes.navbarButton,
                    "clearanceApplication"
                  )}

                >
                  Apply for Clearance
                </Typography>
              </Tooltip>
              <>
                <Typography

                  className={classNames(
                    classes.navbarButton,
                    "downloadForms"
                  )}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  endIcon={<ArrowDropDownIcon />}
                >
                  Download Forms
                </Typography>
              </>

              <Box sx={{ display: "flex", gap: 2, position: "absolute", right: 20 }}>

                <Tooltip
                  title="Increase text size"
                  placement="top-start"
                  arrow
                  style={{ cursor: "pointer", userSelect: 'none' }}
                >
                  <Typography

                    color="inherit"
                    onClick={() => {
                      props.instance.menuInterface.increaseText();
                    }}
                  >
                    <Typography variant="h4" >A+</Typography>
                    {/* <FormatSizeIcon /> */}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title="Decrease text size"
                  placement="top-start"
                  arrow
                  style={{ cursor: "pointer", userSelect: 'none' }}

                >
                  <Typography

                    color="inherit"
                    onClick={() => {
                      props.instance.menuInterface.decreaseText();
                    }}
                  >
                    <Typography variant="h4">A-</Typography>
                  </Typography>
                </Tooltip>
              </Box>

            </Box>



            {/* For mobile screen, hamburger menu. */}
            <Box sx={{ position: "absolute", right: 0, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        {/* appbar for mobile */}
        <Grid item >
          <Box sx={{ flexGrow: 1 }}>
            {renderMobileMenu}
            {renderMenu}
          </Box>
        </Grid>
        <div id="back-to-top" />

        {/* hero section */}
        <Grid container alignItems="center" justifyContent="center" style={{ backgroundColor: "#d3eae8" }} >

          {!isSmallScreen && (
            <Grid item md={6} >
              {/* <Hero /> */}
              <Box className={classes.hero} >

              </Box>
            </Grid>
          )}


          <Grid item md={6} sm={12} xs={12} >
            <Typography
              align="center"
              variant="h2"

              className={classes.heroHeading}
            >Welcome to the Meghalaya Residents Safety & Security Act Online Portal!</Typography>

            <Box sx={{ textAlign: "center", marginBottom: "2rem" }}>

              <Button
                style={{
                  width: "50%",
                  backgroundColor: "#3d495f",
                  color: "#faebd7",
                  letterSpacing: 3
                }}
                size="large"
                onClick={() => {
                  setOpenLoginDialog(true);
                }}
                variant="contained">
                Login
              </Button>
            </Box>


          </Grid>

        </Grid>


        {/* Manuals */}
        <Box sx={{ padding: "2rem 1rem", textAlign: "center", }}>
          <Typography variant="h2" style={{ margin: "1rem", fontFamily: "raleway", fontWeight: 500 }}>How to Apply</Typography>
          <Typography variant="h4" style={{ fontFamily: "montserrat", margin: "3rem 0" }}>
            Application of rental units can be done by completing the following steps :
          </Typography>
          <HorizontalStepper />
        </Box>


        {/* carousel */}
        {/* <Grid item>
          {dimensions.width > 600 ? (

            <BannerExample
              setOpenPdf={setOpenPdf}
              setCreateAppFlag={setCreateAppFlag}
              setOpenTrackApp={setOpenTrackApp}
              setOpen={setOpen}
              openLoginDialog={openLoginDialog}
              forgotPswd={forgotPswd}
            />
          ) : (

            <MyProjectsExample
              setOpenPdf={setOpenPdf}
              setCreateAppFlag={setCreateAppFlag}
              setOpen={setOpen}
              isMobileMenuOpen={isMobileMenuOpen}
            />
          )}
        </Grid> */}


      </Grid >

      {/* This grid is specifically for spacing the above and below container grids. */}
      < Grid container direction="row" >
        <Box sx={{ minHeight: 50 }} />
      </Grid >

      {/* footer */}
      < Footer
        isMobileMenuOpen={isMobileMenuOpen}
        dimensions={dimensions}
        setOpenManuals={setOpenManuals}
      />

      {/* tenant, landlord and Clearance */}
      < Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#232338", color: "#fff" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              disabled={lndOrTntLogin}
            >
              <CloseIcon />
            </IconButton>
            <p style={{ fontWeight: 600 }}>
              {flag
                ? "Tenant Login / Registration"
                : "Landlord Login / Registration"}
            </p>
            <Box sx={{ flexGrow: 1 }} />
            {lndOrTntLogin && (
              <Button
                variant="outlined"
                style={{ color: "#fff", backgroundColor: "#F3A712" }}
                onClick={() => {
                  setPrompt(true);
                }}
              >
                Logout
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <DialogTitle id="form-dialog-title">
          {flag ? "Tenant Login/Registration" : "Landlord Login/Registration"}
        </DialogTitle>
        <DialogContent>
          {flag ? (
            <TenantStepperForm
              lndOrTntLogin={lndOrTntLogin}
              setLndOrTntLogin={setLndOrTntLogin}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              loginValue={loginValue}
              setLoginValue={setLoginValue}
            />
          ) : (
            <LandlordStepperForm
              lndOrTntLogin={lndOrTntLogin}
              setLndOrTntLogin={setLndOrTntLogin}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              loginValue={loginValue}
              setLoginValue={setLoginValue}
            />
          )}
        </DialogContent>
        <Prompt
          setLoginValue={setLoginValue}
          setActiveStep={setActiveStep}
          setLndOrTntLogin={setLndOrTntLogin}
          setPrompt={setPrompt}
          prompt={prompt}
          text="Are you sure you want to logout?"
        />
      </Dialog >

      {/* login */}
      < Dialog
        disableBackdropClick
        open={openLoginDialog}
        onClose={() => {
          setOpenLoginDialog(false);
          setErrorMessage("");
          setLoginValue("");
          setPasswordValue("");
          setCaptchaValue("");
        }}
        PaperProps={{                              //useful for making transparent dialogs
          style: {
            // backgroundColor: "transparent",
            // boxShadow: "none",
            borderRadius: "14px"
          },
        }}
      >
        <IconButton
          style={{
            // backgroundColor: "#eb5809",
            color: "#fff",
            position: "absolute",
            right: "1rem",
            top: "1.2rem",
            height: "20px",
            width: "20px",
            borderRadius: 1,
          }}
          onClick={() => {
            setOpenLoginDialog(false);
            setErrorMessage("");
            setLoginValue("");
            setPasswordValue("");
            setCaptchaValue("");
            setIsLoading(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle style={{ backgroundColor: "#29335C", color: "#fff" }}>
          <Typography variant="h4" align="center">
            USER LOGIN
          </Typography>
        </DialogTitle>
        <LoginDialog
          errorMessage={errorMessage}
          captcha={captcha}
          setCaptcha={setCaptcha}
          passwordValue={passwordValue}
          setPasswordValue={setPasswordValue}
          captchaValue={captchaValue}
          setCaptchaValue={setCaptchaValue}
          isLoading={isLoading}
          publicKey={publicKey}
          history={props.history}
          setIsLoading={setIsLoading}
          setError={setError}
          setActiveTabId={setActiveTabId}
          setErrorMessage={setErrorMessage}
          setOpenLoginDialog={setOpenLoginDialog}
          setForgotPswd={setForgotPswd}
        />
      </Dialog >
      <Dialog
        fullScreen
        open={openPdf}
        onClose={() => setOpenPdf(false)}
        TransitionComponent={Transition}
      >
        <Container>
          <Box>
            <Button
              size={"large"}
              onClick={() => setOpenPdf(false)}
              startIcon={<CloseIcon />}
              variant="outlined"
              style={{ marginTop: "20px", marginLeft: "5%" }}
            >
              Close
            </Button>
            <Link
              // component="button" // cannot download if this is enabled
              target="_blank"
              href={process.env.PUBLIC_URL + "/MRSS.pdf"}
              rel="noreferrer"
              download="MRSS"
              style={{ marginLeft: "900px", marginTop: "20px", fontSize: 15 }}
            // variant="body2"
            >
              Download
            </Link>
          </Box>

          {/* <AppBar style={{ backgroundColor: "#B12B39" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpenPdf(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <p style={{ fontWeight: 600 }}>
                Meghalya Resident Safety & Security Act
              </p>
            </Toolbar>
          </AppBar> */}
          <MrssPdf />
        </Container>
      </Dialog>

      <TrackApplicationDialog
        openTrackApp={openTrackApp}
        setOpenTrackApp={setOpenTrackApp}
      />

      <Dialog open={forgotPswd} onClose={() => setForgotPswd(false)}>

        <ForgotPasswordDialog
          setUsername={setUsername}
          username={username}
          setOtpPswdChnge={setOtpPswdChnge}
          otpPswdChnge={otpPswdChnge}
          setNewPswd={setNewPswd}
          newPswd={newPswd}
          publicKey={publicKey}
          setForgotPswd={setForgotPswd}
          setOpenLoginDialog={setOpenLoginDialog}
        />
      </Dialog>
      <Dialog
        onClose={handleClose}
        open={createAppFlag}
        disableBackdropClick
      // style={{ position: "relative" }}
      >
        <DialogTitle style={{ backgroundColor: "#29335C", color: "#fff" }}>
          <Typography align="center" variant="h4">
            CLEARANCE APPLICATION{" "}
          </Typography>
        </DialogTitle>
        <IconButton
          style={{
            // backgroundColor: "#eb5809",
            color: "#fff",
            position: "absolute",
            right: "1rem",
            top: "1.2rem",
            height: "20px",
            width: "20px",
            borderRadius: 1,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <CreateApplication />
        </DialogContent>
      </Dialog>
      <PreReqDialog openPreReq={openPreReq} setOpenPreReq={setOpenPreReq} />
      <Dialog
        fullScreen
        open={openManuals}
        onClose={() => setOpenManuals(false)}
        TransitionComponent={Transition}
      >
        {/* <Container> */}
        <Box>
          <Button
            size={"large"}
            onClick={() => setOpenManuals(false)}
            startIcon={<CloseIcon />}
            variant="outlined"
            style={{ marginTop: "20px", marginLeft: "5%" }}
          >
            Close
          </Button>
          <Link
            // component="button" // cannot download if this is enabled
            target="_blank"
            href={process.env.PUBLIC_URL + "/password_change.pdf"}
            rel="noreferrer"
            download="MRSS_Change_Password"
            style={{
              marginLeft: "100px",
              marginTop: "20px",
              fontSize: 15,
            }}

          // variant="body2"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InfoIcon color="primary" />
              Forgot/Change Password Instructions
            </Box>
          </Link>
        </Box>
        <DialogContent>
          <VideoManuals />
        </DialogContent>
        {/* </Container> */}
      </Dialog>
    </div >
  );
}

export default withRouter(Login);
