import { getDecryptedData } from "./GlobalService";
import http from "./http-common";
import httpApi from "./http-common-apikey";

const getAll = () => {
  return http.get("/todos/1");
};

// Hardcoded Username and Password, We need to change
// const signIn = (username, password) => {
//   let encPass = CryptoJS.AES.encrypt(password, "secret key 123").toString();
//   return http.post("users/signin", {
//     username: username,
//     password: encPass,
//   });
// };
const signIn = (username, password, captcha) => {
  return httpApi.post("users/signin", {
    username: username,
    password: password,
    captcha: captcha,
  });
};

const signUp = (data) => {
  return http.post("/users/sign-up", {
    username: data.username,
    email: data.email,
    password: data.password,
    usercode: data.usercode,
    roles: data.role,
    level: data.level,
  });
};

const get = (id) => {
  return http.get(`/user/${id}`);
};
const getUserbycode = (data) => {
  http.defaults.headers.common["Authorization"] = sessionStorage.getItem(
    "accessToken"
  );
  // http.defaults.headers.common["Authorization"] = getDecryptedData(
  //   "accessToken"
  // );
  return http.get(`/users/userbyUserCode/${data}`);
};

const create = (data) => {
  return http.post("/user", data);
};

const deleteUser = (username) => {
  return http.delete(`/users/removeuser/${username}`);
};

const update = (id, data) => {
  return http.put(`/user/${id}`, data);
};

const getApplications = (id) => {
  return http.get(`/GetApplicationDetails/user`);
};

const removeAll = () => {
  return http.delete(`/user`);
};
const createPoliceStation = (data) => {
  return http.post("/master/policeStationEntry", data);
};
const createLocality = (data) => {
  return http.post("/master/locality", data);
};
const createLocalAuthority = (data) => {
  return http.post("/master/localAuthorityEntry", data);
};

const getRoles = () => {
  return http.get(`/users/getroles/${sessionStorage.getItem("username")}`);
  // return http.get(`/users/getroles/${getDecryptedData("username")}`);
};

const getPublicKey = () => {
  return http.get("/users/getPublicKey");
};

const getCaptcha = () => {
  return http.get("/users/getcaptcha");
};

const getLogRecords = () => {
  http.defaults.headers.common["Authorization"] = sessionStorage.getItem(
    "accessToken"
  );
  // http.defaults.headers.common["Authorization"] = getDecryptedData(
  //   "accessToken"
  // );
  return http.get("/users/getlog");
};

const signOut = () => {
  return http.get("users/logout");
};

const updateEmail = (username, email) => {
  return http.post(`users/updateuser/${username}/${email}`);
};
export default {
  getAll,
  get,
  create,
  update,
  removeAll,
  signIn,
  signOut,
  getApplications,
  signUp,
  createPoliceStation,
  createLocality,
  getUserbycode,
  getRoles,
  getPublicKey,
  getCaptcha,
  getLogRecords,
  createLocalAuthority,
  deleteUser,
  updateEmail,
};
