import React, { useState, useEffect } from "react";
import { Avatar, Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  BorderAll as TableIcon,
  LibraryBooks as LibraryIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";

// import HourglassFullIcon from "@material-ui/icons/HourglassFull";                 //this is for the pending button.
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageIcon from "@mui/icons-material/Image";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { getDecryptedData } from "../../api/GlobalService";
// import logo from "./logo.svg";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  { id: 1, label: "Profile", link: "/app/profile", icon: <TypographyIcon /> },
  {
    id: 2,
    label: "Rental Units",
    link: "/app/rentalunits",
    icon: <TableIcon />,
  },
  { id: 3, label: "Tenants", link: "/app/tenants", icon: <TableIcon /> },
  {
    id: 4,
    label: "Domestic Helpers",
    link: "/app/domestic_helpers",
    icon: <NotificationsIcon />,
  },
  /*{
    id: 4,
    label: "UI Elements",
    link: "/app/ui",
    icon: <UIElementsIcon />,
    children: [
      { label: "Icons", link: "/app/ui/icons" },
      { label: "Charts", link: "/app/ui/charts" },
      { label: "Maps", link: "/app/ui/maps" },
    ],
  },
  { id: 5, type: "divider" },
  { id: 6, type: "title", label: "HELP" },
  { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  { id: 10, type: "divider" },
  { id: 11, type: "title", label: "PROJECTS" },
  {
    id: 12,
    label: "My recent",
    link: "",
    icon: <Dot size="small" color="warning" />,
  },
  {
    id: 13,
    label: "Starred",
    link: "",
    icon: <Dot size="small" color="primary" />,
  },
  {
    id: 14,
    label: "Background",
    link: "",
    icon: <Dot size="small" color="secondary" />,
  },
  */
];

const moderatorSidebar = [
  {
    id: 0,
    label: "My Profile",
    link: "/app/profile",
    icon: <PersonIcon />,
    title: "Profile",
  },
  {
    id: 1,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    title: "Home",
  },
  {
    id: 2,
    label: "New Application",
    link: "/app/new-application",
    icon: <NoteAddIcon />,
    title: "Create Application",
  },
  {
    id: 3,
    label: "Application",
    // link: "/app/profile", // VERY CRUCIAL.. for navigation when sidebar is closed.
    icon: <AssignmentIcon />,
    children: [
      /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
      {
        label: "Pending",
        link: "/app/application/pending",
        title: "Pending Applications",
      },
      {
        label: "Forwarded",
        link: "/app/application/forwarded",
        title: "Forwarded Applications",
      },
      {
        label: "Approved",
        link: "/app/application/approved",
        title: "Approved Applications",
      },
      {
        label: "Rejected",
        link: "/app/application/rejected",
        title: "Rejected Applications",
      },
      // { label: "Rectification", link: "/app/application/rectification" },
    ],
    title: "View Applications",
  },
  {
    id: 4,
    label: "Delete Tenant",
    link: "/app/update-tenant",
    icon: <DeleteIcon />,
    title: "Delete a Tenant",
  },
  // {
  //   id: 2,
  //   label: "BackLog",
  //   link: "/app/application/rectification",
  //   icon: <TableIcon />,
  // },
  /*{ id: 2, label: "Offline Application", link: "/app/offlineapplication", icon: <TableIcon /> },*/

  /*{
    id: 4,
    label: "UI Elements",
    link: "/app/ui",
    icon: <UIElementsIcon />,
    children: [
      { label: "Icons", link: "/app/ui/icons" },
      { label: "Charts", link: "/app/ui/charts" },
      { label: "Maps", link: "/app/ui/maps" },
    ],
  },
  { id: 5, type: "divider" },
  { id: 6, type: "title", label: "HELP" },
  { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  { id: 10, type: "divider" },
  { id: 11, type: "title", label: "PROJECTS" },
  {
    id: 12,
    label: "My recent",
    link: "",
    icon: <Dot size="small" color="warning" />,
  },
  {
    id: 13,
    label: "Starred",
    link: "",
    icon: <Dot size="small" color="primary" />,
  },
  {
    id: 14,
    label: "Background",
    link: "",
    icon: <Dot size="small" color="secondary" />,
  },
  */
];

const verifierSidebar = [
  {
    id: 0,
    label: "My Profile",
    link: "/app/profile",
    icon: <PersonIcon />,
    title: "Profile",
  },
  {
    id: 1,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    title: "Home",
  },
  {
    id: 2,
    label: "Create Report",
    link: "/app/report",
    icon: <UploadFileIcon />,
    title: "Generate Report",
  },
  {
    id: 3,
    label: "New Application",
    link: "/app/new-application",
    icon: <NoteAddIcon />,
    title: "Create Application",
  },
  {
    id: 4,
    label: "Application",
    // link: "/app/profile",
    icon: <AssignmentIcon />,
    children: [
      /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
      {
        label: "Approved",
        link: "/app/application/approved",
        title: "Approved Applications",
      },
      {
        label: "Rejected",
        link: "/app/application/rejected",
        title: "Rejected Applications",
      },
      // { label: "Rectification", link: "/app/application/rectification" },
    ],
    title: "View Applications",
  },
  {
    id: 5,
    label: "Localities",
    link: "/app/localities",
    icon: <AccountBalanceIcon />,
    title: "Add Localities",
  },
  {
    id: 6,
    label: "Sub-Localities",
    link: "/app/local-authorities",
    icon: <BusinessIcon />,
    title: "Add Sub-Localities",
  },
  // {
  //   id: 2,
  //   label: "BackLog",
  //   link: "/app/application/rectification",
  //   icon: <TableIcon />,
  // },
  /*{ id: 2, label: "Offline Application", link: "/app/offlineapplication", icon: <TableIcon /> },*/

  /*{
    id: 4,
    label: "UI Elements",
    link: "/app/ui",
    icon: <UIElementsIcon />,
    children: [
      { label: "Icons", link: "/app/ui/icons" },
      { label: "Charts", link: "/app/ui/charts" },
      { label: "Maps", link: "/app/ui/maps" },
    ],
  },
  { id: 5, type: "divider" },
  { id: 6, type: "title", label: "HELP" },
  { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  { id: 10, type: "divider" },
  { id: 11, type: "title", label: "PROJECTS" },
  {
    id: 12,
    label: "My recent",
    link: "",
    icon: <Dot size="small" color="warning" />,
  },
  {
    id: 13,
    label: "Starred",
    link: "",
    icon: <Dot size="small" color="primary" />,
  },
  {
    id: 14,
    label: "Background",
    link: "",
    icon: <Dot size="small" color="secondary" />,
  },
  */
];

const normalUserStructure = [
  {
    id: 0,
    label: "My Profile",
    link: "/app/profile",
    icon: <PersonIcon />,
    title: "Profile",
  },
  {
    id: 1,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    title: "Home",
  },
  {
    id: 2,
    label: "Application",
    // link: "/app/profile",
    icon: <AssignmentIcon />,
    children: [
      /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
      { label: "Pending", link: "/app/application/pending" },
      { label: "Approved", link: "/app/application/approved" },
      { label: "Rejected", link: "/app/application/rejected" },
      // { label: "Rectification", link: "/app/application/rectification" },
    ],
    title: "View Applications",
  },
];

const localUserStructure = [
  {
    id: 0,
    label: "My Profile",
    link: "/app/profile",
    icon: <PersonIcon />,
    title: "Profile",
  },
  {
    id: 1,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    title: "Home",
  },
  {
    id: 2,
    label: "Sub-Localities",
    link: "/app/profile",
    icon: <LocationCityIcon />,
    children: [
      /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
      {
        label: "Add",
        link: "/app/local-authorities",
        title: "Add Sub-Localities",
      },
    ],
    title: "Sub-Localities",
  },
  {
    id: 3,
    label: "Application",
    // link: "/app/profile",
    icon: <AssignmentIcon />,
    children: [
      /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
      { label: "Pending", link: "/app/application/pending" },
      { label: "Forwarded", link: "/app/application/forwarded" },
      { label: "Approved", link: "/app/application/approved" },
      { label: "Rejected", link: "/app/application/rejected" },
      // { label: "Rectification", link: "/app/application/rectification" },
    ],
    title: "View Applications",
  },

  // {
  //   id: 2,
  //   label: "Sub-Localities",
  //   link: "/app/profile",
  //   icon: <LocationCityIcon />,
  //   children: [
  //     /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
  //     { label: "Add", link: "/app/local-authorities" },
  //   ],
  // },
  // {
  //   id: 2,
  //   label: "BackLog",
  //   link: "/app/application/rectification",
  //   icon: <TableIcon />,
  // },
  /*{ id: 2, label: "Offline Application", link: "/app/offlineapplication", icon: <TableIcon /> },*/

  /*{
    id: 4,
    label: "UI Elements",
    link: "/app/ui",
    icon: <UIElementsIcon />,
    children: [
      { label: "Icons", link: "/app/ui/icons" },
      { label: "Charts", link: "/app/ui/charts" },
      { label: "Maps", link: "/app/ui/maps" },
    ],
  },
  { id: 5, type: "divider" },
  { id: 6, type: "title", label: "HELP" },
  { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  { id: 10, type: "divider" },
  { id: 11, type: "title", label: "PROJECTS" },
  {
    id: 12,
    label: "My recent",
    link: "",
    icon: <Dot size="small" color="warning" />,
  },
  {
    id: 13,
    label: "Starred",
    link: "",
    icon: <Dot size="small" color="primary" />,
  },
  {
    id: 14,
    label: "Background",
    link: "",
    icon: <Dot size="small" color="secondary" />,
  },
  */
];

const landLordStructure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Application",
    link: "/app/profile",
    icon: <TypographyIcon />,
    children: [
      /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
      { label: "Pending", link: "/app/application/pending" },
      { label: "Approved", link: "/app/application/approved" },
      { label: "Rejected", link: "/app/application/rejected" },
      // { label: "Rectification", link: "/app/application/rectification" },
    ],
  },

  {
    id: 7,
    label: "Tenants",
    link: "/app/landlord/tenants",
    icon: <LibraryIcon />,
  },
  {
    id: 8,
    label: "Rental Units",
    link: "/landlord/rentalunits",
    icon: <LibraryIcon />,
  },
  /*{ id: 2, label: "Offline Application", link: "/app/offlineapplication", icon: <TableIcon /> },*/

  /*{
    id: 4,
    label: "UI Elements",
    link: "/app/ui",
    icon: <UIElementsIcon />,
    children: [
      { label: "Icons", link: "/app/ui/icons" },
      { label: "Charts", link: "/app/ui/charts" },
      { label: "Maps", link: "/app/ui/maps" },
    ],
  },
  { id: 5, type: "divider" },
  { id: 6, type: "title", label: "HELP" },
  { id: 7, label: "Library", link: "", icon: <LibraryIcon /> },
  { id: 8, label: "Support", link: "", icon: <SupportIcon /> },
  { id: 9, label: "FAQ", link: "", icon: <FAQIcon /> },
  { id: 10, type: "divider" },
  { id: 11, type: "title", label: "PROJECTS" },
  {
    id: 12,
    label: "My recent",
    link: "",
    icon: <Dot size="small" color="warning" />,
  },
  {
    id: 13,
    label: "Starred",
    link: "",
    icon: <Dot size="small" color="primary" />,
  },
  {
    id: 14,
    label: "Background",
    link: "",
    icon: <Dot size="small" color="secondary" />,
  },
  */
];

const adminStructure = [
  {
    id: 2,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    title: "Home",
  },
  {
    id: 1,
    label: "My Profile",
    link: "/app/profile",
    icon: <PersonIcon />,
    title: "Profile",
  },
  {
    id: 0,
    label: "Update Media",
    link: "/app/media-update",
    icon: <ImageIcon />,
    title: "Update Media",
  },

  {
    id: 3,
    label: "Police Stations",
    // link: "/app/profile",
    icon: <LocalPoliceIcon />,
    children: [
      /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
      {
        label: "Add",
        link: "/app/policestations",
        title: "Add Police Stations",
      },
    ],
    title: "Police Stations",
  },
  {
    id: 4,
    label: "Log Records",
    link: "/app/log-records",
    icon: <ReceiptLongIcon />,
    title: "View Login and Logout Records",
  },
  {
    id: 5,
    label: "Users",
    link: "/app/users",
    icon: <PeopleAltIcon />,
    title: "View and add users",
  },
  // {
  //   id: 4,
  //   label: "Exp Rows",
  //   link: "/app/expandable-rows",
  //   icon: <PeopleAltIcon />,
  //   title: "Rowss",
  // },
  // {
  //   id: 3,
  //   label: "Formik",
  //   link: "/app/formik",
  //   icon: <ReceiptLongIcon />,
  //   title: "Formik",
  // },

  // {
  //   id: 5,
  //   label: "Create Report",
  //   link: "/app/report",
  //   icon: <UploadFileIcon />,
  //   title: "Generate Report",
  // },

  // {
  //   id: 2,
  //   label: "Sub-Localities",
  //   link: "/app/profile",
  //   icon: <LocationCityIcon />,
  //   children: [
  //     /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
  //     { label: "Add", link: "/app/local-authorities" },
  //   ],
  // },
  // {
  //   id: 2,
  //   label: "Create Users",
  //   link: "/app/profile",
  //   icon: <TypographyIcon />,
  //   children: [
  //     /*{ label: "Alls", link: "/app/applications" ,icon: <LibraryIcon /> },*/
  //     { label: "Add", link: "/app/policestations" },
  //   ],
  // },
];

const supervisorSidebar = [
  {
    id: 0,
    label: "My Profile",
    link: "/app/profile",
    icon: <PersonIcon />,
    title: "Profile",
  },
  {
    id: 1,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    title: "Home",
  },

  {
    id: 2,
    label: "Police Stations",
    link: "/app/policestations",
    icon: <LocalPoliceIcon />,
    title: "Police Stations",
  },
  {
    id: 3,
    label: "Create Report",
    link: "/app/report",
    icon: <UploadFileIcon />,
    title: "Generate Report",
  },
];

const superModeratorSidebar = [
  {
    id: 0,
    label: "My Profile",
    link: "/app/profile",
    icon: <PersonIcon />,
    title: "Profile",
  },
  {
    id: 1,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />,
    title: "Home",
  },
  {
    id: 2,
    label: "New Application",
    link: "/app/new-application",
    icon: <NoteAddIcon />,
    title: "Create Application",
  },
  {
    id: 3,
    label: "Application",
    icon: <AssignmentIcon />,
    children: [
      {
        label: "Pending",
        link: "/app/application/pending",
        title: "Pending Applications",
      },
      {
        label: "Approved",
        link: "/app/application/approved",
        title: "Approved Applications",
      },
      {
        label: "Rejected",
        link: "/app/application/rejected",
        title: "Rejected Applications",
      },
    ],
    title: "View Applications",
  },
  {
    id: 4,
    label: "Sub-Localities",
    link: "/app/local-authorities",
    icon: <BusinessIcon />,
    title: "Add Sub-Localities",
  },
  {
    id: 5,
    label: "Delete Tenant",
    link: "/app/update-tenant",
    icon: <DeleteIcon />,
    title: "Delete a Tenant",
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  // const userRol = getDecryptedData("role");
  const userRol = sessionStorage.getItem("role");

  // const userRole = 2;

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <img
        src="/logo.svg"
        alt="mrss-logo"
        style={{ padding: "12% 20% 0% 20%" }}
      />
      <List className={classes.sidebarList}>
        {userRol === "VERIFIER"
          ? verifierSidebar.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))
          : userRol === "ADMIN"
            ? adminStructure.map((link) => (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            ))
            : userRol === "MODERATOR"
              ? moderatorSidebar.map((link) => (
                <SidebarLink
                  key={link.id}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  {...link}
                />
              ))
              : userRol === "SUPERMODERATOR"
                ? superModeratorSidebar.map((link) => (
                  <SidebarLink
                    key={link.id}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    {...link}
                  />
                ))
                : userRol === "SUPERVISOR"
                  ? supervisorSidebar.map((link) => (
                    <SidebarLink
                      key={link.id}
                      location={location}
                      isSidebarOpened={isSidebarOpened}
                      {...link}
                    />
                  ))
                  // : userRol === "USER" && getDecryptedData("role") === "MODERATOR"
                  : userRol === "USER" && sessionStorage.getItem("role") === "MODERATOR"
                    ? localUserStructure.map((link) => (
                      <SidebarLink
                        key={link.id}
                        location={location}
                        isSidebarOpened={isSidebarOpened}
                        {...link}
                      />
                    ))
                    : normalUserStructure.map((link) => (
                      <SidebarLink
                        key={link.id}
                        location={location}
                        isSidebarOpened={isSidebarOpened}
                        {...link}
                      />
                    ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
