import {
  DialogActions,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import UserService from "../../api/UserService";
import MasterData from "../../api/MasterData";
import RentalUnitService from "../../api/RentalUnitService";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import AddLocalAuthorityDialog from "./AddLocalAuthorityDialog";
import AddLocalAuthorityUserDialog from "./AddLocalAuthorityUserDialog";
import AddIcon from "@mui/icons-material/Add";
import { Paper, Tooltip } from "@mui/material";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import { getDecryptedData } from "../../api/GlobalService";

function AddLocalAuthority({ history }) {
  const [ps, setPs] = useState("");
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [roles, setRoles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [localAuthorityCode, setLocalAuthorityCode] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [la, setLa] = useState({
    localityName: "",
    headManName: "",
    // area: "",
    ps: "",
  });

  const [landlordUser, setLandlordUser] = useState({
    username: "",
    password: "",
    usercode: "",
    role: [{}],
    email: "rilangkishabong@gmail.com",
  });

  const toggleRefresh = () => {
    setRefresh(!refresh);
  };

  const getRoles = () => {
    UserService.getRoles()
      .then((response) => {
        setRoles(response.data);
        console.log(response.data);
      })
      .catch((e, response) => {
        console.error(e);
        // alert(JSON.stringify(e.response.data));
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const columns = [
    {
      name: "localityCode",
      label: "Locality Code",
      options: {
        filter: true,
      },
    },
    {
      name: "localityName",
      label: "Locality Name",
      options: {
        filter: true,
      },
    },
    {
      name: "headManName",
      label: "Headman Name",
      options: {
        filter: true,
      },
    },
    {
      name: "users", // needs to use .map() here
      label: "Users",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[3].map((user, index) => (
            <div key={index}>
              {user}
              {tableMeta.rowData[3].length - 1 === index ? "" : ","}
            </div>
          ));
        },
      },
    },
    {
      name: "action1", // needs buttons
      label: "Actions",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            style={{
              backgroundColor: "#F3A712",
              color: "#fff",
              borderRadius: "20px",
            }}
            onClick={() => {
              setLocalAuthorityCode(tableMeta.rowData[0]);
              setOpen(!open);
            }}
          >
            ADD USER
          </Button>
        ),
      },
    },
  ];

  const getLocalAuth = async () => {
    const response = await RentalUnitService.getLocalities(
      // getDecryptedData("usercode")
      sessionStorage.getItem("usercode")
    );
    var data = [];
    data = response.data;
    for (var i = 0; i < data.length; i++) {
      data[i].id = i + 1;
      data[i].localityName = data[i].localityName;
      data[i]["localityCode"] = data[i].localityCode;

      const userresponse = await UserService.getUserbycode(
        data[i].localityCode
      );
      // data[i].users = userresponse.data;
      data[i]["users"] = userresponse.data;
    }
    setData(data);
  };

  const getPoliceStation = () => {
    // MasterData.getPoliceStationByCode(getDecryptedData("usercode"))
    MasterData.getPoliceStationByCode(sessionStorage.getItem("usercode"))
      .then(
        (res) => {
          console.log("police Station is : ", res.data.policeStationName);
          setPs(res.data.policeStationName);
          setLa({ ...la, ps: res.data.policeStationCode });
        }
      );
  };

  useEffect(() => {
    // getDistricts();
    getPoliceStation();
    getLocalAuth();
  }, [refresh]);

  const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  function rowKeyGetter(row) {
    // let result = [];
    // if (params.row.phone) {
    //   if (params.row.phone.home) {
    //     result.push("home: " + params.row.phone.home);
    //   }
    //   if (params.row.phone.office) {
    //     result.push("office: " + params.row.phone.office);
    //   }
    // } else {
    //   result = ["Unknown"];
    // }
    // return result.join(", ");

    return row.id;
  }

  return (
    <>
      <PageHeader
        title="Localities"
        subTitle="Add new locality and its corresponding users."
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Button
        onClick={() => {
          setOpenDialog(true);
        }}
        variant="contained"
        style={{ backgroundColor: "#F3A712", color: "#fff" }}
      >
        + Add Locality
      </Button>

      <div style={{ marginTop: "2%" }}>
        <Paper>
          <CustomDataTable rows={data} columns={columns} title="Localities" />
        </Paper>
        <Dialog // Dialog for Creating Local Auth User
          open={open}
          onClose={() => setOpen(!open)}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h3" align="center">
              Create Locality User
            </Typography>
          </DialogTitle>
          <DialogContent>
            <AddLocalAuthorityUserDialog
              landlordUser={landlordUser}
              setLandlordUser={setLandlordUser}
              roles={roles}
              localAuthorityCode={localAuthorityCode}
              toggleRefresh={toggleRefresh}
              setOpen={setOpen}
            />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog // Dialog for Creating Sub-Locality
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3" align="center">
            Create Locality
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AddLocalAuthorityDialog
            getLocalAuth={getLocalAuth}
            setLa={setLa}
            la={la}
            ps={ps}
            setOpenDialog={setOpenDialog}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
export default AddLocalAuthority;
