import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  //   TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const UpdateMedia = () => {
  return (
    <Card sx={{ width: { lg: "50%", md: "50%" } }}>
      <CardHeader
        title="Update Image"
        //   subheader={subheader}
      />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            },
          }}
        >
          <FormControl
            sx={{
              display: {
                lg: "inline-flex",
                md: "inline-flex",
                sm: "inline-flex",
                xs: "block",
              },
              width: { lg: "70%", md: "70%", sm: "50%", xs: "100%" },
            }}
          ></FormControl>
          <CardActions>
            <Button
              variant="outlined"
              sx={{ marginTop: "7px" }}
              //   onClick={handleUpdateEmail}
            >
              Update
            </Button>
          </CardActions>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UpdateMedia;
