import React from "react";
import { Grid } from "@material-ui/core";
import * as Constants from "../../Constants";
// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import ApplicationDataTable from "../offlineapplication/DataTable";
import NewApplication from "../offlineapplication/NewApplication";
import PageHeader from "../../components/Header/PageHeader";
import ForwardIcon from "@material-ui/icons/Forward";
import { getDecryptedData } from "../../api/GlobalService";

export default function Forwarded() {
  // const role = getDecryptedData("role");
  const role = sessionStorage.getItem("role");

  return (
    <>
      {/* <PageTitle title="Forwarded Applications" /> */}
      <PageHeader
        title="Forwarded Applications"
        subTitle="Applications that are yet to be approved by the police officer goes here."
        icon={<ForwardIcon fontSize="large" />}
      />
      {/* {role === Constants.role.USER || role === Constants.role.MODERATOR ? (
        <NewApplication />
      ) : (
        <></>
      )} */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <ApplicationDataTable
            status={
              role === "MODERATOR" || role === "SUPERMODERATOR"
                ? "LAVP"
                : "PLVP"
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
