import { makeStyles, Typography } from "@material-ui/core";
import { Grid, Link, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import NIC from "./NIC.png";
import digitalIndia from "./digitalIndia.png";
import { useHistory } from "react-router-dom";
import G20 from "./G20.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const Footer = ({ isMobileMenuOpen, dimensions, setOpenManuals }) => {
  let history = useHistory();
  const classes = useStyles();
  return (
    <Grid
      container
      // spacing={3} // if enabled, we will get a bottom scroll-bar.. Solution: enclose the grid inside <Container maxWidth={false}>
      direction={!isMobileMenuOpen ? "row" : "column-reverse"}
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      sx={{
        backgroundColor: "#232338",
        // backgroundImage:
        //   "linear-gradient(to bottom right, #29335C, #015172,#29335C)",
        padding: "3%",
        position: "relative",

      }}
    >
      <Grid
        item
        sx={{
          color: "#fff",
          position: {
            lg: "absolute",
            md: "static",
            sm: "static",
            xs: "static",
          },
          right: "2%",
        }}
      >
        <Box
          sx={{
            fontSize: "100%",
            // fontSize: 18,

            display: "flex",
            flexDirection: {
              lg: "column",
              md: "column",
              sm: "column",
              xs: "column",
            },
            justifyContent: "flex-start",
            alignContent: "flex-start",
          }}
        >
          <Link
            sx={{
              fontFamily: "Montserrat",
              textDecoration: "none"
              , '&:hover': {
                textDecoration: 'underline', // Example hover style
                color: 'white',              // Example hover color
              },
              cursor: "pointer",
              alignSelf: {
                lg: "end",
                md: "center",
                sm: "center",
                xs: "center",
              },
            }}
            target="_blank"
            href={process.env.PUBLIC_URL + "/FAQ_MRSS.pdf"}
            rel="noreferrer"
            download="FAQs_MRSS"
          >
            FAQs
          </Link>
          <Link
            sx={{
              fontFamily: "Montserrat",
              textDecoration: "none"
              , '&:hover': {
                textDecoration: 'underline', // Example hover style
                color: 'white',              // Example hover color
              },
              cursor: "pointer",
              alignSelf: {
                lg: "end",
                md: "center",
                sm: "center",
                xs: "center",
              },
            }}
            onClick={() => setOpenManuals(true)}
          >
            Manuals
          </Link>
          <Link
            sx={{
              fontFamily: "Montserrat",
              textDecoration: "none",
              '&:hover': {
                textDecoration: 'underline', // Example hover style
                color: 'white',              // Example hover color
              },
              cursor: "pointer",
              alignSelf: {
                lg: "end",
                md: "end",
                sm: "center",
                xs: "center",
              },
            }}
            target="_blank"
            href={process.env.PUBLIC_URL + "/mrss.apk"}
            rel="noreferrer"
            download="MRSSA_APK"
          >
            Mobile App(For landlords & tenants)
          </Link>
          <Link
            sx={{
              fontFamily: "Montserrat",
              textDecoration: "none",
              '&:hover': {
                textDecoration: 'underline', // Example hover style
                color: 'white',              // Example hover color
              },
              cursor: "pointer",
              alignSelf: {
                lg: "end",
                md: "center",
                sm: "center",
                xs: "center",
              },
            }}
            onClick={() => history.push("/privacy-policy")}
          >
            Privacy Policy
          </Link>
          <Link
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              '&:hover': {
                textDecoration: 'underline', // Example hover style
                color: 'white',              // Example hover color
              },
              alignSelf: {
                lg: "end",
                md: "center",
                sm: "center",
                xs: "center",
              },
              // fontWeight:"500",
              paddding: "2rem"
            }}
            target="_blank"
            href={"http://nesda.gov.in/#/citizen-survey"}
            rel="noreferrer"
          >
            <Box sx={{
              padding: "0.1rem", display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: "Montserrat"
            }}>

              Citizen Survey&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
              </svg>
            </Box>
          </Link>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{ padding: "10px 20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            // style={{ height: "auto" }}
            src={digitalIndia}
            alt="logo"
            height={42}
          />

          <img src={NIC} alt="logo" />
          <img src={G20} alt="G20" width={60} />
        </Box>
        <Box sx={{ padding: "20px 0" }}>

          <Typography
            variant="subtitle1"
            align="center"
            style={{ color: "#fff", fontFamily: "Montserrat" }}
            className={classes.heading}
          >
            Disclaimer: This webapp is developed and maintained by National
            Informatics Centre, Shillong.
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            style={{ color: "#fff", fontFamily: "Montserrat" }}
            className={classes.heading}
          >
            Content Owned and Updated by Home Political Department, Government of
            Meghalaya
          </Typography>
          <Typography
            align="center" style={{ color: "#fff", fontFamily: "Montserrat" }} >
            Copyright © {new Date().getFullYear()} - All Rights Reserved
          </Typography>
          <Typography
            align="center"
            style={{ color: "#fff", fontFamily: "Montserrat" }}
            variant="subtitle2"
          >
            For issues or queries contact - 9612946422, 8837025236, 9774419450
          </Typography>


        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
