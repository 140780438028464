import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
// components
import Layout from "./Layout";
// pages
import Error from "../pages/error";
import Login from "../pages/login";
// context
import { useUserState } from "../context/UserContext";
import { GlobalProvider } from "../context/GlobalState";
import { ApplicationProvider } from "../context/ApplicationState";
import { Accessibility } from "accessibility/src/main";
import LandlordLogin from "../pages/janparichay-login/LandlordLogin";
import PrivacyPolicy from "./PrivacyPolicy";
import VideoManuals from "./Footer/VideoManuals";
import LandlordStepperForm from "../pages/landlord/LandlordStepperForm";

const instance = new Accessibility({
  icon: {
    useEmojis: true,
    position: {
      bottom: { size: 50, units: "px" },

      left: { size: 20, units: "px" },

      type: "fixed",
    },
    // circular: [true / false],
  },
});

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "load",
      // function () {
      instance,
      // new Accessibility({
      //   icon: {
      //     useEmojis: true,
      //     position: {
      //       bottom: { size: 50, units: "px" },

      //       left: { size: 20, units: "px" },

      //       type: "fixed",
      //     },
      //     // circular: [true / false],
      //   },
      // });
      false
    );
  }, [reset]);

  return (
    <BrowserRouter basename={"/"}>
      {/* <HashRouter> */}
      <ApplicationProvider>
        <GlobalProvider>
          <Switch>
            <Route exact path="/">
              {isAuthenticated === true ? (
                <Redirect to="/app/dashboard" />
              ) : (
                <Login instance={instance} />
              )}
            </Route>
            <Route path="/landlord-login" component={LandlordStepperForm} />
            {/* <Route
              exact
              path="/app"
              render={() => <Redirect to="/app/dashboard" />}
            /> */}
            <PrivateRoute path="/app" component={Layout} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/manuals" component={VideoManuals} />
            <Route path="*" component={Error} />
          </Switch>
        </GlobalProvider>
      </ApplicationProvider>
      {/* </HashRouter> */}
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    // if (isAuthenticated === true && backgroundFlag === false) {
    //   setAllow(true);
    // }
    return (
      <Route
        {...rest}
        render={(props) =>
          // isAuthenticated === true &&
          // backgroundFlag === false &&
          // sessionStorage.getItem("usercode") !== "null" ? (
          //   React.createElement(component, props)
          // ) : (
          //   <Redirect to="*" />
          // )
          React.createElement(component, props)
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
