import React from 'react'
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";


export const CustomListItem = ({primary,secondary,icon}) => {
  return (
    <ListItem disablePadding sx={{padding: 0, display: "flex",justifyContent:{sm: "space-between"},flexDirection: {xs: "column",sm: "row"}}}>
   
    <ListItemIcon sx={{display:{xs: "none",sm:"inherit"}}}>
     {icon}
    </ListItemIcon>
    <ListItemText
    sx={{alignSelf:{xs:"start"}}}
      primary={primary}
      secondary={secondary}
    />
    
  </ListItem>
  )
}
