import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, Autocomplete, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ApplicationService from "../../api/ApplicationService";
import httpApi from "../../api/http-common-apikey";
import httpApiApp from "../../api/http-common-apikey-application";
import InfoIcon from "@mui/icons-material/Info";
import JSEncrypt from "jsencrypt";
import http from "../../api/http-common";

import ApplicationFlowDialog from "../offlineapplication/ApplicationFlowDialog";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import UserService from "../../api/UserService";
import { GlobalContext } from "../../context/GlobalState";

const TrackApplicationDialog = ({ openTrackApp, setOpenTrackApp }) => {
  const { snackbar, openSnackBar } = useContext(GlobalContext);

  const [lndNotFoundMessage, setLndNotFoundMessage] = useState("");
  const [lndNotFound, setLndNotFound] = useState(false);
  const [status, setStatus] = useState(null);
  const [lndID, setLndID] = useState("");
  const [id, setId] = useState("");
  const [otp, setOtp] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [appFlow, setAppFlow] = useState([]);
  const [openAppFlow, setOpenAppFlow] = useState(false);
  const [list, setList] = useState([]);
  const [verified, setVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [reset, setReset] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    UserService.getPublicKey()
      .then((response) => setPublicKey(response.data))
      .catch((err) => console.log(err));
  }, [reset]);

  const handleApplicationChange = (value) => {
    if (value === null) {
      setId("");
    } else {
      setId(value.applid);
      setStatus(null);
    }
  };

  const handleClose = () => {
    setVerified(false);
    setList([]);
    setLndID("");
    setId("");
    setStatus(null);
    setOtpSent(false);
    setOtpValue("");
    setLndNotFound(false);
    setLndNotFoundMessage("");
    setAppFlow([]);
    setOtp("");
    setReset(!reset);
    setOpenAppFlow(false);
    setInvalidOTP(false);
    setOpenTrackApp(false);
  };

  return (
    <Dialog
      disableBackdropClick
      open={openTrackApp}
      fullWidth
      onClose={() => {
        setVerified(false);
        setList([]);
        setLndID("");
        setId("");
        setStatus(null);
        setOtpSent(false);
        setOtpValue("");
        setLndNotFound(false);
        setLndNotFoundMessage("");
        setAppFlow([]);
        setOtp("");
        setReset(!reset);
        setOpenAppFlow(false);
        setInvalidOTP(false);
        setOpenTrackApp(false);
      }}
    >

      <Snackbar
        open={snackBar.open}
        // autoHideDuration={3000}
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ height: "100%" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return; // Ignore the click outside behavior
          }
          setSnackBar({ ...snackBar, open: false });
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar({ ...snackBar, open: false }); // Correctly close the Snackbar when 'x' is clicked
          }}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>




      <DialogTitle style={{ backgroundColor: "#29335C", color: "#fff", position: "relative" }}>
        <IconButton
          style={{
            // backgroundColor: "#eb5809",
            color: "#fff",
            position: "absolute",
            right: "1rem",
            top: "1.2rem",
            height: "20px",
            width: "20px",
            borderRadius: 1,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography align="center" variant="h4">
          Track Application Status
        </Typography>
      </DialogTitle>

      <DialogContent style={{ alignSelf: "center" }}>
        <Stack direction="row">
          <FormControl style={{ width: "65%" }}>
            <TextField
              disabled={otpSent}
              autoFocus
              margin="dense"
              id="application-id"
              label="Landlord Epic"
              fullWidth
              variant="standard"
              value={lndID}
              onChange={(event) => {
                setLndID(event.target.value.toUpperCase());
              }}
              error={lndNotFound}
              helperText={
                lndNotFound ? "This landlord epic number does not exist!" : ""
              }
            />
          </FormControl>
          <Button
            disabled={lndID.length < 8 || otpSent}
            onClick={() =>
              httpApi
                .get(`apikey/getOTP/${lndID}`)
                .then((res) => {
                  setSnackBar({
                    message:
                      // "An OTP has been sent to your registered mobile number or email",
                      "Please enter OTP received in your registered mobile number or email.",
                    severity: "success",
                    open: true,
                  });
                  setLndNotFound(false);
                  setOtpSent(true);
                })
                .catch((err) => {
                  setLndNotFound(true);
                  console.log(err);
                })
            }
            size={"small"}
            color="secondary"
            variant="contained"
            style={{
              maxHeight: "30px",
              minWidth: "60px",
              minHeight: "30px",
              marginTop: "22px",
              marginLeft: "10px",
            }}
          >
            Get OTP
          </Button>
        </Stack>

        <Stack direction="row">
          <FormControl style={{ width: "65%" }}>
            <TextField
              disabled={!otpSent || verified}
              autoFocus
              margin="dense"
              id="otp"
              label="OTP"
              fullWidth
              variant="standard"
              value={otpValue}
              onChange={async (e) => {
                let otp = e.target.value;
                // setOtp(e.target.value);
                setOtpValue(otp);

                if (otp.length >= 6) {
                  try {
                    let crypt = new JSEncrypt();
                    crypt.setPublicKey(publicKey);
                    let enc = crypt.encrypt(otp);
                    httpApi.defaults.headers["Content-type"] =
                      "application/json";
                    setOtp(enc);
                  } catch (error) {
                    console.log(error.response);
                  }
                }
              }}
              error={invalidOTP}
              helperText={invalidOTP ? "Invalid OTP" : ""}
            />
          </FormControl>
          <Button
            disabled={otpValue.length < 6 || verified}
            onClick={async () => {
              httpApi
                .post(`apikey/validateOTP/${lndID}`, otp)
                .then((response) => {
                  setSnackBar({ ...snackBar, open: false });

                  httpApiApp.defaults.headers["API-key"] = response.data;
                  setVerified(true);
                  ApplicationService.getAllApplicationListByLandlord(
                    lndID
                  ).then((res) => setList(res.data));
                })
                .catch((err) => {
                  setInvalidOTP(true);
                  console.log(err);
                });
            }}
            size={"small"}
            color="secondary"
            variant="contained"
            style={{
              maxWidth: "60px",
              maxHeight: "30px",
              minWidth: "60px",
              minHeight: "30px",
              marginTop: "22px",
              marginLeft: "10px",
            }}
          >
            Verify
          </Button>
        </Stack>
        {/* <TextField
          autoFocus
          margin="dense"
          id="application-id"
          label="Application ID"
          fullWidth
          variant="standard"
          onChange={(e) => {
            if (e.target.value.length >= 13) setId(e.target.value);
          }}
        /> */}

        {verified && (
          <FormControl fullWidth>
            <Autocomplete
              onChange={(event, value) => {
                handleApplicationChange(value);
              }}
              id="application"
              options={list}
              // value={
              //   id !== ""
              //     ? { option: id } //must be an object. This object is then fetched by getOptionLabel
              //     : list.options
              // }
              getOptionLabel={(option) =>
                option.applid +
                " / " +
                option.tenant.firstName +
                " " +
                option.tenant.lastName
              }
              getOptionSelected={() => id}
              // style={{ width: 200 }}
              // disabled={profile.exist ? true : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Application *"
                  variant="outlined"
                // error={errors.occupation.exist}
                // helperText={
                //   errors.occupation.exist ? errors.occupation.helperText : ""
                // }
                />
              )}
            />
          </FormControl>
        )}

        <ApplicationFlowDialog
          openAppFlow={openAppFlow}
          setOpenAppFlow={setOpenAppFlow}
          appFlow={appFlow}
        />
      </DialogContent>
      <DialogActions style={{ margin: "auto" }}>
        <Button
          size={"small"}
          color="primary"
          variant="contained"
          disabled={id === "" || verified === false}
          onClick={() => {
            ApplicationService.getApplicationAnonymouseUser(id, lndID)
              .then((response) => {
                setLndNotFoundMessage("");
                setStatus(response.data);
              })
              .catch((error) => {
                setStatus(null);
                setLndNotFoundMessage(error.response.data.message);
                console.log("Error::: ", error.response.data.message);
              });
          }}
        >
          Track
        </Button>
        <Button
          size={"small"}
          color="secondary"
          variant="contained"
          onClick={() => {
            setVerified(false);
            setList([]);
            setLndID("");
            setId("");
            setStatus(null);
            setOtpSent(false);
            setOtpValue("");
            setLndNotFound(false);
            setLndNotFoundMessage("");
            setAppFlow([]);
            setOtp("");
            setReset(!reset);
            setOpenAppFlow(false);
            setInvalidOTP(false);
          }}
        >
          Reset
        </Button>
      </DialogActions>
      {status !== null && (
        <Paper variant="elevation" style={{ padding: "25px" }}>
          <Container>
            <Stack>
              <Typography>
                Application id&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;
                <b>{id}</b>
              </Typography>

              <Typography>
                Landlord &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp; &nbsp;:&nbsp;
                <b>{status.landlordName}</b>{" "}
              </Typography>
              <Typography>
                Tenant&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;
                <b>{status.tenantName}</b>{" "}
              </Typography>
              <Typography>
                Rental Address &nbsp; &nbsp; &nbsp; &nbsp; :&nbsp;
                <b>{status.rentalUnitAddress}</b>
              </Typography>

              <Typography>
                Status of Application&nbsp;:&nbsp;
                <b
                  style={{
                    color:
                      status.applStatus === "LAVP" ||
                        status.applStatus === "LARP"
                        ? "#F3A712"
                        : status.applStatus === "PLVP"
                          ? "#92d459"
                          : "red",
                  }}
                >
                  {status.applStatus === "LAVP"
                    ? "Pending at Local Authority"
                    : status.applStatus === "LARP"
                      ? "Pending at Sub-Locality"
                      : status.applStatus === "PLVP"
                        ? "Application has been approved"
                        : "Application has been rejected."}
                </b>
              </Typography>
              <Button
                size={"small"}
                style={{ width: "50%", alignSelf: "center", marginTop: "2%" }}
                color="primary"
                variant="contained"
                onClick={() => {
                  ApplicationService.getApplicationFlowAsAnonymousUser(id).then(
                    (response) => {
                      setAppFlow(response.data);
                      setOpenAppFlow(true);
                    }
                  );
                }}
              >
                View Application Flow
              </Button>
            </Stack>
          </Container>
        </Paper>
      )}
      {lndNotFoundMessage !== "" && (
        <DialogContentText sx={{ padding: "10px" }}>
          <Paper variant="outlined">
            <Container>Sorry,{lndNotFoundMessage}</Container>
          </Paper>
        </DialogContentText>
      )}
    </Dialog>
  );
};

export default TrackApplicationDialog;
