import { Typography } from "@material-ui/core";
import React from "react";

const InfoText = ({ text }) => {
  return (
    <Typography variant="subtitle2" color="primary">
      {text}
    </Typography>
  );
};

export default InfoText;
