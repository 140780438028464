import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import JSEncrypt from "jsencrypt";
import http from "../api/http-common-apikey";
import httpAppiApp from "../api/http-common-apikey-application";
import httpApi from "../api/http-common-apikey";
import { GlobalContext } from "../context/GlobalState";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserService from "./../api/UserService";

const VerifyOTPDialog = ({
  openVerifyOTP,
  setOpenVerifyOTP,
  id,
  setDisableOtherTabs,
  snackBar,
  setSnackBar,
  otpValueForTenant
}) => {
  const { tenantApplication } = useContext(GlobalContext);
  const [otpValue, setOtpValue] = useState("");
  const [publicKey, setPublicKey] = useState("");

  useEffect(() => {
    UserService.getPublicKey()
      .then(function (response) {
        setPublicKey(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <Dialog
      disableBackdropClick
      open={openVerifyOTP}
    // onClose={() => setOpenVerifyOTP(false)}
    >
      <IconButton
        style={{
          backgroundColor: "#eb5809",
          color: "#fff",
          position: "absolute",
          right: 0,
          top: 0,
          height: "20px",
          width: "20px",
          borderRadius: 5,
        }}
        onClick={() => setOpenVerifyOTP(false)}
      >
        {/* <CancelOutlinedIcon /> */}
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        {" "}
        <Typography align="center">OTP Authentication</Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>
          Please enter OTP received in your registered mobile number or email.
        </Typography>
        <FormControl>
          <TextField
            id="otp"
            label="Enter OTP"
            // onChange={(event) => {
            //   setOtpValue(event.target.value);
            // }}
            // error={errors.presentAddress.pinCode.exist}
            // helperText={
            //   errors.presentAddress.pinCode.exist
            //     ? errors.presentAddress.pinCode.helperText
            //     : ""
            // }
            // inputProps={profile.exist && fontColor}
            onChange={async (e) => {
              let otp = e.target.value;
              if (otp.length >= 6) {
                try {
                  let crypt = new JSEncrypt();
                  crypt.setPublicKey(publicKey);
                  let enc = crypt.encrypt(otp);
                  httpApi.defaults.headers["Content-type"] = "application/json";
                  setOtpValue(enc);
                } catch (error) {
                  console.log(error.response);
                }
              }
            }}
          />
        </FormControl>
        <Box sx={{ height: "10px" }} />
        <Button
          color="primary"
          variant="outlined"
          size={"small"}
          style={{ alignSelf: "flex-end", width: "20%" }}
          onClick={async () => {
            httpApi
              .post(`apikey/validateOTP/${id}`, otpValue)
              .then((response) => {
                httpAppiApp.defaults.headers["API-key"] = response.data;
                setDisableOtherTabs(false);
                let regId = sessionStorage.getItem("tenantId") !== null ? sessionStorage.getItem("tenantId") : sessionStorage.getItem("landlordId")
                setSnackBar({
                  ...snackBar,
                  message: (
                    <Typography>
                      OTP has been verified. You can now proceed further data
                      entries!
                      <br />
                      Your registration number is {regId}
                    </Typography>
                  ),
                  open: true,
                  severity: "success",
                });

                setOpenVerifyOTP(false);
              });
          }}
        >
          Verify
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyOTPDialog;
