import { Button, FormControl } from "@material-ui/core";
import { Paper, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import UserService from "../../api/UserService";
import { useStyles } from "./useStyles";

const AddLocalAuthorityDialog = ({ getLocalAuth, setLa, la, ps, setOpenDialog }) => {
  const classes = useStyles();

  const errorInitialState = {
    error: false,
    laName: {
      exist: false,
      helperText: "Name required",
    },
    hdName: {
      exist: false,
      helperText: "Headman name required",
    },
    // area: {
    //   exist: false,
    //   helperText: "Area required",
    // },
    district: {
      exist: false,
      helperText: "District required",
    },
  };
  const [errors, setErrors] = useState(errorInitialState);

  const validateUserData = () => {
    const e = errorInitialState;
    if (la.localityName === "") {
      e.error = true;
      e.laName.exist = true;
    }
    if (la.headManName === "") {
      e.error = true;
      e.hdName.exist = true;
    }
    // if (la.area === 0) {
    //   e.error = true;
    //   e.area.exist = true;
    // }
    return e;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const errs = validateUserData();

    if (errs.error === true) {
      setErrors(errs);

      // setSnackBar({
      //   message: "Validation Errors!",
      //   severity: "error",
      //   open: true,
      // });

      return;
    }

    await UserService.createLocality(la)
      .then((response) => {
        console.log("Response for LA Entry is : ", response);
        alert("Successfully created Locality.");
        // history.push("/app/dashboard");
        //   setError(null)
        //   setIsLoading(false);

        // history.push("/app/dashboard");
        //   dispatch({ type: "LOGIN_SUCCESS" });
      })
      .then(() => {
        getLocalAuth();
        setLa(prevState => ({
          ...prevState,
          localityName: "",
          headManName: ""
        }));

        setOpenDialog(false);
      })
      .catch((e) => {
        console.error(e);
        alert(e.response.data.message);
        //   setError(true);
        //   setIsLoading(false);
        //dispatch({ type: "LOGIN_FAILURE" }); // No need this, if enable it will reset the tabIndex State
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
      }}
    >
      <FormControl className={classes.lsInputs}>
        <TextField
          size={"small"}
          variant="outlined"
          type="text"
          value={la.localityName}
          label="Locality Name"
          onChange={(e) => setLa({ ...la, localityName: e.target.value })}
          className={classes.lsInputs}
          error={errors.laName.exist === true ? true : false}
          helperText={errors.laName.exist ? errors.laName.helperText : ""}
        />
      </FormControl>
      <FormControl className={classes.lsInputs}>
        <TextField
          size={"small"}
          variant="outlined"
          type="text"
          value={la.headManName}
          label="Headman Name"
          onChange={(e) => setLa({ ...la, headManName: e.target.value })}
          className={classes.lsInputs}
          error={errors.hdName.exist === true ? true : false}
          helperText={errors.hdName.exist ? errors.hdName.helperText : ""}
        />
      </FormControl>
      {/* <FormControl className={classes.lsInputs}>
        <TextField
          size={"small"}
          variant="outlined"
          type="text"
          value={la.area}
          label="Area"
          onChange={(e) => setLa({ ...la, area: e.target.value })}
          className={classes.lsInputs}
          error={errors.area.exist === true ? true : false}
          helperText={errors.area.exist ? errors.area.helperText : ""}
        />
      </FormControl> */}
      <FormControl className={classes.lsInputs}>
        <TextField
          size={"small"}
          variant="outlined"
          type="text"
          value={ps}
          label="Police Station"
          disabled="true"
          onChange={(e) => setLa({ ...la, area: e.target.value })}
          className={classes.lsInputs}
        />
      </FormControl>
      <Button onClick={handleClick} variant="contained" color="primary">
        Add Locality
      </Button>
    </Box>
  );
};

export default AddLocalAuthorityDialog;
