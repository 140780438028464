import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
// import Title from "./Title";
import MasterData from "../../api/MasterData";
import tinycolor from "tinycolor2";
import {
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ApplicationService from "../../api/ApplicationService";
import RentalUnitService from "../../api/RentalUnitService";
import { Avatar, Box, Card, CardContent, CardMedia, Skeleton } from "@mui/material";
import { getDecryptedData } from "../../api/GlobalService";
import { useHistory } from "react-router-dom";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { green } from "@material-ui/core/colors";
import LandlordService from "../../api/LandlordService";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  responsiveLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '17px', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px', // Adjust the font size for smaller screens
    },
    fontFamily: "raleway"
    // Add more styles as needed for different breakpoints
  },
}));

export default function DashboardCard({
  bgColor,
  textColor,
  cardTitle,
  link,
  noAction,
  icon
}) {
  const classes = useStyles();
  const [noOfPs, setNoOfPs] = useState(0);
  const [noOfPendingApps, setNoOfPendingApps] = useState(0);
  const [noOfLA, setNoOfLA] = useState(0);
  const [noOfApprovedApps, setNoOfApprovedApps] = useState(0);
  const [noOfShiftedTenantsApps, setNoOfShiftedTenantsApps] = useState(0);

  const [noOfRejectedApps, setNoOfRejectedApps] = useState(0);
  const [noOfForwardedApps, setNoOfForwardedApps] = useState(0);
  const [noOfLocalities, setNoOfLocalities] = useState(0);
  const [noOfTenants, setNoOfTenants] = useState(0);
  const [noOfLandlords, setNoOfLandlords] = useState(0);

  const [noOfLandlordsInLocality, setNoOfLandlordsinLocality] = useState(0);

  const [retrieving, setRetrieving] = useState(false);
  const history = useHistory();

  function getMonth(monthNumber) {
    switch (monthNumber) {
      case "1": {
        return "Jan";
      }
      case "2": {
        return "Feb";
      }
      case "3": {
        return "Mar";
      }
      case "4": {
        return "Apr";
      }
      case "5": {
        return "May";
      }
      case "6": {
        return "Jun";
      }
      case "7": {
        return "Jul";
      }
      case "8": {
        return "Aug";
      }
      case "9": {
        return "Sep";
      }
      case "10": {
        return "Oct";
      }
      case "11": {
        return "Nov";
      }
      case "12": {
        return "Dec";
      }
      default: {
        return;
      }
    }
  }

  const getPs = async () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);
    MasterData.getPoliceStations().then((response) => {
      setNoOfPs(response.data.length);
      setRetrieving(false);
    });
  };

  const getLA = () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);
    RentalUnitService.getLocalAutoritiesByPoliceStationId(
      sessionStorage.getItem("usercode")
      // getDecryptedData("usercode")
    ).then((response) => {
      setNoOfLA(response.data.length);
      setRetrieving(false);
    });
  };

  const getLocalities = () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);

    RentalUnitService.getLocalities(sessionStorage.getItem("usercode"))
      // RentalUnitService.getLocalities(getDecryptedData("usercode"))
      .then((response) => {
        setNoOfLocalities(response.data.length);
        setRetrieving(false);
      });
  };

  const getAllLocalities = () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);
    RentalUnitService.getAllLocalities().then((response) => {
      setNoOfLocalities(response.data.length);
      setRetrieving(false);
    });
  };

  const getPendingApplications = () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);
    ApplicationService.getApplicationByStatus(
      sessionStorage.getItem("role") === "MODERATOR" ? "LARP" : "LAVP",
      sessionStorage.getItem("username")
      // getDecryptedData("role") === "MODERATOR" ? "LARP" : "LAVP",
      // getDecryptedData("username")
    ).then((response) => {
      setNoOfPendingApps(response.data);
      setRetrieving(false);
    });
  };

  const getApprovedApplications = () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);
    ApplicationService.getApplicationByStatus(
      "PLVP",

      sessionStorage.getItem("username")
      // getDecryptedData("username")
    ).then((response) => {
      setNoOfApprovedApps(response.data);
      setRetrieving(false);
    });
  };

  const getShiftedTenantsApplications = () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);
    ApplicationService.getApplicationByStatus(
      "APARV",

      sessionStorage.getItem("username")
      // getDecryptedData("username")
    ).then((response) => {
      setNoOfShiftedTenantsApps(response.data);
      setRetrieving(false);
    });
  };

  const getRejectedApplications = () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);
    ApplicationService.getApplicationByStatus(
      "RJT",

      sessionStorage.getItem("username")
      // getDecryptedData("username")
    ).then((response) => {
      setNoOfRejectedApps(response.data);
      setRetrieving(false);
    });
  };

  const getForwardedApplications = () => {
    setRetrieving(true);
    // console.log("selectedDC: ", selectedDistrictCode);
    ApplicationService.getApplicationByStatus(
      "LAVP",

      sessionStorage.getItem("username")
      // getDecryptedData("username")
    ).then((response) => {
      setNoOfForwardedApps(response.data);
      setRetrieving(false);
    });
  };

  const getLAForAdmin = () => {
    setRetrieving(true);
    RentalUnitService.getLocalAutorities().then((response) => {
      setNoOfLA(response.data.length);
      setRetrieving(false);
    });
  };

  const getTotalTenants = () => {
    setRetrieving(true);
    MasterData.getTotalTenants()
      .then((response) => {
        setNoOfTenants(response.data);
        setRetrieving(false);
      })
      .catch((err) => setRetrieving(false));
  };

  const getTotalLandlords = () => {
    setRetrieving(true);
    MasterData.getTotalLandlords()
      .then((response) => {
        setNoOfLandlords(response.data);
        setRetrieving(false);
      })
      .catch((err) => setRetrieving(false));
  };

  const getAllLandlordsByLocalityCode = async () => {
    setRetrieving(true);
    await LandlordService.getAllLandlordsByLocalityCode(sessionStorage.getItem("username"), `LA-${sessionStorage.getItem("usercode").slice(-2)}`)
      .then((response) => {
        setNoOfLandlordsinLocality(response.data);
        setRetrieving(false);
      })
      .catch((err) => setRetrieving(false));
  };

  useEffect(() => {
    cardTitle === "Registered Police Stations"
      ? getPs()
      : cardTitle === "Registered Localities"
        ? getAllLocalities()
        : cardTitle === "Pending Applications"
          ? getPendingApplications()
          : cardTitle === "Sub-Localities"
            ? getLA()
            : cardTitle === "Approved Applications"
              ? getApprovedApplications()
              : cardTitle === "Rejected Applications"
                ? getRejectedApplications()
                : cardTitle === "Forwarded Applications"
                  ? getForwardedApplications()
                  : cardTitle === "Localities"
                    ? getLocalities()
                    : cardTitle === "Total Tenants Registered"
                      ? getTotalTenants()
                      : cardTitle === "Total Landlords Registered"
                        ? getTotalLandlords()
                        : cardTitle === "Tenants"
                          ? getApprovedApplications()
                          : cardTitle === "Shifted Tenants"
                            ? getShiftedTenantsApplications()
                            : cardTitle === "Landlords"
                              ? getAllLandlordsByLocalityCode()
                              : getLAForAdmin();
  }, []);

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: bgColor,
        padding: "10px",
        cursor: "pointer",
      }}
      onClick={
        () => history.push(link)
      }
    >
      <CardContent
        style={{
          // backgroundImage: "linear-gradient(to right, #29335C, #B12B39)",
          color: textColor,
          padding: "30px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap"
          }}
        >

          {/* Number here */}
          {retrieving ? (
            <Skeleton
              height={36}
              width={70}
              variant="rounded"
              sx={{
                margin: "auto",
                bgcolor: "#6d4c41",
                borderRadius: "10%",
              }}
            />
          ) : (
            <Typography
              variant="h1"
            >
              {cardTitle === "Registered Police Stations"
                ? noOfPs
                : cardTitle === "Registered Localities"
                  ? noOfLocalities
                  : cardTitle === "Pending Applications"
                    ? noOfPendingApps
                    : cardTitle === "Sub-Localities"
                      ? noOfLA
                      : cardTitle === "Localities"
                        ? noOfLocalities
                        : cardTitle === "Approved Applications"
                          ? noOfApprovedApps
                          : cardTitle === "Rejected Applications"
                            ? noOfRejectedApps
                            : cardTitle === "Forwarded Applications"
                              ? noOfForwardedApps
                              : cardTitle === "Total Tenants Registered"
                                ? noOfTenants
                                : cardTitle === "Total Landlords Registered"
                                  ? noOfLandlords
                                  : cardTitle === "Tenants"
                                    ? noOfApprovedApps
                                    : cardTitle === "Shifted Tenants"
                                      ? noOfShiftedTenantsApps
                                      : cardTitle === "Landlords"
                                        ? noOfLandlordsInLocality
                                        : noOfLA}
            </Typography>
          )}


          {/* Title here */}
          <Typography
            className={classes.responsiveLabel}
            // sx={{ fontSize: 14 }}
            gutterBottom
            align="center"
          >
            {cardTitle}
          </Typography>
        </Box>

        {/* SVG Image here */}

      </CardContent>
      {/* <img
        src={process.env.PUBLIC_URL + "static/dashboard_application.png"}
        alt="SVG" style={{ height: '70px' }} /> */}

      <Box >

        {icon}
      </Box>

    </Card>
  );
}


{/* <Typography variant="body2" align="center">
          as on{" "}
          {new Date().getDate() +
            "-" +
            getMonth((new Date().getMonth() + 1).toString()) +
            "-" +
            new Date().getFullYear()}
          <br />
        </Typography> */}
{/* {!noAction && <CardActions>
        {link && <Link
          to={link}
          style={{
            margin: "auto",
            textDecoration: "none",
            color: "#C36241"
          }}
        >
          View
        </Link>}
      </CardActions>} */}