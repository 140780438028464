import React, { useState } from "react";
import { Card, Grid } from "@material-ui/core";

// styles
import useStyles from "../styles";

// components

import NewDocument from "./NewDocument";
import TenantDocumentsDataTable from "./TenantDocumentsDataTable";
import PageHeader from "../../../components/Header/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";

export default function TenantDocuments(props) {
  var classes = useStyles();
  const [refresh, setRefresh] = useState(false);

  return (
    <Card>
      {/* <PageTitle title="Tenant Documents" /> */}
      <PageHeader
        title="Tenant Documents"
        subTitle="Add tenant documents."
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <NewDocument setRefresh={setRefresh} refresh={refresh} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <TenantDocumentsDataTable
            refresh={refresh}
            closeTenantModal={props.closeTenantModal}
            setDocumentsExist={props.setDocumentsExist}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
