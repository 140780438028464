import http from "./http-common";
import httpApi from "./http-common-apikey";

const getDistrictsByStateId = (id) => {
  return httpApi.get(`/master/district/${id}`);
};

const getDistricts = () => {
  return httpApi.get("/master/district");
};

const getStates = () => httpApi.get(`master/state`);

const getOccupation = () => {
  return httpApi.get(`master/occupation`);
};
const getPoliceStationsByDistrictCode = (dist) => {
  return httpApi.get(`/master/policestation/${dist}`);
};

const getPoliceStations = () => {
  return httpApi.get(`/master/policestation`);
};

const getLocalAuthorities = () => {
  return http.get("/master/LocalAuthority");
};

const getPoliceStationByCode = (code) => {
  return httpApi.get(`/master/policestationBycode/${code}`);
};

const getRelationships = () => {
  return httpApi.get("/master/relationshiptype");
};

const getTotalTenants = () => {
  return http.get("/register/counttenant");
}

const getTotalLandlords = () => {
  return http.get("/register/countlandlord");
}

export default {
  getDistrictsByStateId,
  getDistricts,
  getStates,
  getOccupation,
  getPoliceStationsByDistrictCode,
  getPoliceStations,
  getLocalAuthorities,
  getPoliceStationByCode,
  getRelationships,getTotalTenants,getTotalLandlords
};
