import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import ApplicationService from "../../api/ApplicationService";
import { formatDateToIndianFormat } from "../../api/GlobalService";
import CustomDataTable from "../data-table/CustomDataTable";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  responsiveLabel: {
    fontFamily: "raleway",
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.9rem', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.9rem', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem', // Adjust the font size for smaller screens
    },
    // Add more styles as needed for different breakpoints
  },
  responsiveButtonText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '9px', // Adjust the font size for smaller screens
      padding: "2px 16px"
    },
    // Add more styles as needed for different breakpoints
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const initialState = {
  name: "",
  entity: "tenant",
};
// const columns = [

//   { name: "id", title: "ID" },
//   // { name: "firstName", title: "First Name" },
//   { name: "firstName", title: "First Name" },

//   { name: "lastName", title: "Last Name" },
//   { name: "age", title: "Date of Birth" },
//   { name: "mobile", title: "mobile" },
//   { name: "occupation", title: "occupation" },
//   { name: "permanentAddress.address1", title: "Permanent Address" },
//   { name: "presentAddres.address1", title: "Present Address" },
// ];

// const options = {
//   filter: true,
//   selectableRows: "multiple",
//   selectableRowsOnClick: false,
//   selectableRowsHideCheckboxes: true,
//   filterType: "dropdown",
//   responsive: "vertical",
//   enableNestedDataAccess: ".", // allows nested data separated by "." (see column names and the data structure above)
// };
const SearchDialog = (props) => {
  const [payload, setPayload] = useState(initialState);
  const [rows, setRows] = useState([]);
  const classes = useStyles();

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        display: payload.entity === "tenant" ? true : false,
      },
    },

    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
      },
    },

    { name: "lastName", label: "Last Name" },
    {
      name: "age",
      label: "Date of Birth",
      options: { display: payload.entity === "tenant" ? true : false },
    },
    { name: "mobile", label: "Mobile" },
    { name: "occupation", label: "Occupation" },
    { name: "permanentAddress.address1", label: "Permanent Address" },
    { name: "presentAddress.address1", label: "Present Address" },
    // {
    //   name: "view",
    //   label: "View Details",
    //   options: {
    //     filter: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <Button>View Details</Button>;
    //     },
    //   },
    // },
  ];

  const { openSerchDialog, setOpenSearchDialog } = props;

  const handleSearch = async () => {
    await ApplicationService.searchPeople(payload).then(async (response) => {
      if (Array.isArray(response.data)) {
        if (payload.entity === "tenant") {
          response.data.forEach(
            (tenant) => (tenant.age = formatDateToIndianFormat(tenant.age))
          );
        }
        setRows(response.data);
      } else {
        setRows([]);
        let data = null;
        data = response.data;
        if (data.id === null) {
          data.id = data.landlord_id;
        }
        // data[0]["permanentAddress"] = data.permanentAddress.address1;
        // data.presentAddress = data.presentAddress.address1;

        setRows((oldArray) => [...oldArray, data]);
      }
    });
    // rows.length < 1 ? setNotFound(true) : setNotFound(false);
  };

  return (
    <Dialog
      fullScreen
      open={openSerchDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setPayload(initialState);
        setRows([]);
        setOpenSearchDialog(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ alignSelf: "center", margin: "1rem 0" }}>
        {/* <IconButton
          style={{
            backgroundColor: "#eb5809",
            color: "#fff",
            position: "absolute",
            right: 0,
            top: 0,
            height: "20px",
            width: "20px",
            borderRadius: 1,
          }}
          onClick={() => {
            setPayload(initialState);
            setRows([]);
            setOpenSearchDialog(false);
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <Typography
          variant="h5"

          className={classes.responsiveLabel}
        >Search Tenants or Landlords</Typography>
      </DialogTitle>
      <DialogContent>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: { xs: "90%", md: "50%" },
            padding: "2rem 1rem",
            margin: "auto"
          }}
        >
          <FormControl>
            <TextField

              key={openSerchDialog}
              autoFocus={true}
              size={"small"}
              margin="dense"
              label="Name (or EPIC,Registration no.)"
              type="text"
              // value={user.username}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  name: e.target.value,
                });
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Entity</FormLabel>
            <RadioGroup
              key={openSerchDialog}
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="tenant"
              name="radio-buttons-group"
              onChange={(event) => {
                setRows([]);
                setPayload({ ...payload, entity: event.target.value });
              }}
            >
              <FormControlLabel
                value="tenant"
                control={<Radio />}
                label="Tenant"
              />
              <FormControlLabel
                value="landlord"
                control={<Radio />}
                label="Landlord"
              />
            </RadioGroup>
          </FormControl>
        </Paper>
        <Box sx={{ minHeight: 50 }} />
        <DialogActions>
          <Box sx={{ margin: "auto" }}>
            <Button size={"small"} variant="outlined" onClick={handleSearch}>
              Search
            </Button>

            <Button
              variant="outlined"
              size={"small"}
              onClick={() => {
                setPayload(initialState);
                setRows([]);
                setOpenSearchDialog(false);
              }}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
        {rows.length !== 0 && (
          <CustomDataTable
            rows={rows}
            columns={columns}
            title={
              payload.entity === "landlord" ? "Landlord List" : "Tenant List"
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SearchDialog;
