import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Slide from '@mui/material/Slide';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CameraComponent from "../../../components/file-upload/CameraComponent";

import AddIcon from "@material-ui/icons/Add";
import { Alert } from "@mui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { GlobalContext } from "../../../context/GlobalState";

import { DialogTitle, FormControl } from "@material-ui/core";

import { DropzoneArea } from "material-ui-dropzone";
import DocumentService from "../../../api/DocumentService";
import { FormLabel } from "@material-ui/core";
import { Box } from "@mui/system";
import Document from "../../../components/file-upload/Document";
import { Typography } from "@mui/material";
import { getDecryptedData } from "../../../api/GlobalService";

const TransitionLeft = (props) => {
  return <Slide {...props} direction="left" />;
};

const useStyle = makeStyles((theme) => ({
  dialogElements: {
    display: "flex",
  },
  dialogActions: {
    justifyContent: "center",
  },
  dialogTitle: {
    margin: "auto",
  },
  closeDialog: {
    width: "10%",
    margin: "auto",
    marginTop: "40px",
    marginBottom: "10px",
  },
}));

const files = ["PHOTO", "IDENTITYPROOF", "SIGNATURE", "RESIDENTIALCERTIFICATE", "FORMA"];

export default function NewDocument({ refresh, setRefresh }) {
  const classes = useStyle();
  const { addTenantDocument } = useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const [uploading, setUploading] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [backDropOpen, setBackDropOpen] = useState(false);


  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const [signature, setSignature] = useState(null);
  const [document, setDocument] = useState(null);
  const [camFile, setCamFile] = useState("")
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getBase64(file, callback) {
    const reader = new FileReader();

    reader.addEventListener("load", () => callback(reader.result));

    reader.readAsDataURL(file);
  }

  const upLoadSignature = async () => {
    if (signature === null || signature === undefined) {
      setSnackBar({
        message: "Please Choose a signature to upload",
        open: true,
        severity: "error",
      });

      return;
    }


    getBase64(signature, async function (base64Data) {
      const fileExtension = getExt(signature.name);

      // if (
      //   fileExtension !== "jpeg" ||
      //   fileExtension !== "jpg" ||
      //   fileExtension !== "pdf" ||
      //   fileExtension !== "JPEG" ||
      //   fileExtension !== "JPEG" ||
      //   fileExtension !== "JPEG"
      // ) {
      //   setSnackBar({
      //     ...snackBar,
      //     message: "File-type not accepted!",
      //     open: true,
      //     severity: "error",
      //   });
      //   return;
      // }

      let base64String = /,(.+)/.exec(base64Data)[1];
      console.log(base64String);

      const payload = {
        // ownerid: getDecryptedData("tenantId"),
        ownerid: sessionStorage.getItem("tenantId"),
        ownertype: "tenant",
        doctype: "SIGNATURE",
        base64img: base64String,
        contenttype: fileExtension,
      };

      try {
        setUploading(true);
        const response =
          // getDecryptedData("role") === "MODERATOR" ||
          //   getDecryptedData("role") === "SUPERMODERATOR" ||
          //   getDecryptedData("role") === "VERIFIER"
          sessionStorage.getItem("role") === "MODERATOR" ||
            sessionStorage.getItem("role") === "SUPERMODERATOR" ||
            sessionStorage.getItem("role") === "VERIFIER"
            ? await DocumentService.create(payload)
            : await DocumentService.createAnonymous(payload);
        setSignature(null);
        // setSelectedDocumentType(null);
        addTenantDocument(response.data);
        setSnackBar({
          message: "Signature Uploaded Successfully!",
          open: true,
          severity: "success",
        });
        setUploading(false);
      } catch (err) {
        setUploading(false);
        setSignature(null);
        // setSelectedDocumentType(null);
        setSnackBar({
          ...snackBar,
          message: err.response
            ? err.response.data.message
            : "Uploading error. Try again later!",
          open: true,
          severity: "error",
        });
      }
      setUploading(false);
    });
  };

  function getExt(filename) {
    var ext = filename.split(".").pop();
    if (ext == filename) return "";
    return ext;
  }

  return (
    <div>
      <Button
        style={{ float: "right" }}
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        New Document
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="1000px"
        BackdropProps={{
          onClick: handleClose, // This will trigger when the backdrop (outside area) is clicked
        }}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <Typography variant="h5" align="center">
            New Document
          </Typography>
          <Typography variant="subtitle1" align="center" sx={{ color: "red" }}>
            Note: Size of documents should be maximum 500kb.
          </Typography>
        </DialogTitle>
        {snackBar.open ? (
          <Alert
            onClose={() => {
              setSnackBar({ ...snackBar, open: false });
            }}
            severity={snackBar.severity}
            TransitionComponent={TransitionLeft}
          >
            {snackBar.message}
          </Alert>
        ) : (
          <></>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            padding: "2% 4%",
          }}
        >
          {files.map((file, index) => (
            <>
              <Document
                setOpenCamera={setOpenCamera}
                key={index}
                label={file}
                document={document}
                setDocument={setDocument}
                setUploading={setUploading}
                addTenantDocument={addTenantDocument}
                setSnackBar={setSnackBar}
                snackBar
                setCamFile={setCamFile}
              />
              {/* {file === "RESIDENTIALCERTIFICATE" && (
                <Typography>
                  Tenants residing in the state who wishes to move to another
                  rental unit in a different locality needs to provide a
                  Residential Certificate produced by the Local Authority of his
                  previous Rental Unit.
                </Typography>
              )} */}
            </>
          ))}
        </Box>
        <Button
          variant="contained"
          color="secondary"
          className={classes.closeDialog}
          onClick={() => {
            setOpen(!open);
            setRefresh(!refresh);
          }}
        >
          Close
        </Button>
        <Dialog
          open={openCamera}
          onClose={() => setOpenCamera(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CameraComponent
            setSnackBar={setSnackBar}
            document={document}
            setUploading={setUploading}
            addTenantDocument={addTenantDocument}
            setDocument={setDocument}
            snackBar={snackBar}
            label={camFile}
            setOpenCamera={setOpenCamera}
          />
        </Dialog>
      </Dialog>
    </div>
  );
}
