import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import { Alert } from "@mui/lab";
import Autocomplete from "@mui/material/Autocomplete";

import { FormControl, Snackbar, Typography } from "@material-ui/core";

import { GlobalContext } from "../../../context/GlobalState";
import { useContext } from "react";
import ApplicationService from "../../../api/ApplicationService";

import CheckBoxGroup from "./CheckBoxGroup";
import TenantService from "../../../api/TenantService";
import { ApplicationContext } from "./../../../context/ApplicationState";
import ComboBox from "../../../components/combo-box/ComboBox";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";

export default function NewTenantApplication(props) {
  const { finish, refreshDataTable, setRefreshDataTable } = props;
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = useState("");
  const { currentApplication, getRunningApplication } = useContext(
    GlobalContext
  );
  const [newlyCreatedApplid, setNewlyCreatedApplid] = useState("");
  const { applicationData } = useContext(ApplicationContext);
  const [familyMemberCodes, setFamilyMemberCodes] = useState([]);
  const [helperMemberCodes, setHelperMemberCodes] = useState([]);
  const [selectedRentalUnit, setSelectedRentalUnit] = useState(null); // This is the application
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [flag, setFlag] = useState(false);
  const [remarks, setRemarks] = useState("");

  const [tenantRental, setTenantRental] = useState([]);
  const handleClickOpen = async () => {
    TenantService.createApplicationFromLoggedInUser(applicationData.state)
      .then(async (res) => {
        let data = await [];
        await data.push(res.data);
        await setTenantRental(data);
      })
      .then(() => setOpen(!open));
    setSelectedRentalUnit(null);
  };

  const handleClickOpenExisting = () => {
    setFlag(!flag);
    setOpen(!open);
    setSelectedRentalUnit(null);
  };
  const handleClose = () => {
    setSelectedRentalUnit(null);
    setOpen(false);
  };

  const handleRentalUnitChange = (value) => {
    setSelectedRentalUnit(value);
  };

  const getTenantRental = async () => {
    const response = await ApplicationService.getApplicationByLandlordEpicNumber(
      currentApplication.id
    );
    setTenantRental(response.data);
    //const response = await ApplicationService.getIncompleteApplicationByLandlordEpicNumber(currentApplication.id);
  };
  useEffect(() => {
    getTenantRental();
  }, [flag]);

  const applyApplication = async () => {
    const data = {
      membercode: familyMemberCodes,
      helperCode: helperMemberCodes,
      applid: selectedRentalUnit.applid,
    };

    const dataForRemarks = {
      applid: selectedRentalUnit.applid,
      applStatus: {
        applstatus: "LAVP",
      },
      appltype: "APPLPS",
      remarks: action === "approve" ? "(May be Accepted)" : remarks,
    };
    /*{ membercode:” [ ]”,
     helperCode: “[ ]”,
     applid: “”
     }
     */
    try {
      const response = await ApplicationService.addFamilyAndHelperMembersAsUser(
        data
      );
      const responseForRemarks = await ApplicationService.updateApplication(
        dataForRemarks
      );
      setRefreshDataTable(!refreshDataTable);
      if (response.status === 200) {
        setSnackBar({
          // message: `Application applied successfully! Please note the following ID for future references : ${response.data.applid}`,
          message: (
            <Typography>
              Successfully Saved Tenant Application.
              <br />
              <br />
              Application ID : <b>{response.data.applid}</b>
              <br />
              Tenant ID : <b>{response.data.tenant.id}</b>
              <br />
              Landlord ID : <b>{response.data.landlordcode}</b>
              <br />
              <br />
              Please save these information for future references.
            </Typography>
          ),
          severity: "success",
          open: true,
        });
        finish(true);
        setOpen(false);
        getRunningApplication(selectedRentalUnit.applid);
      } else {
        setSnackBar({
          message: "Error in applying..!",
          severity: "error",
          open: true,
        });
        setOpen(false);
      }
    } catch (err) {
      setSnackBar({
        message: "Error in applying..!",
        severity: "error",
        open: true,
      });
      setOpen(false);
    }
  };
  // action : ADD/REMOVE
  const selectFamily = (memberCode, action) => {
    if (action === "ADD") familyMemberCodes.push(memberCode);
    else if (action === "REMOVE") {
      const index = familyMemberCodes.indexOf(memberCode);
      if (index > -1) {
        familyMemberCodes.splice(index, 1);
      }
    }
    setFamilyMemberCodes([...familyMemberCodes]);
  };
  const selectHelper = (memberCode, action) => {
    if (action === "ADD") helperMemberCodes.push(memberCode);
    else if (action === "REMOVE") {
      const index = helperMemberCodes.indexOf(memberCode);
      if (index > -1) {
        helperMemberCodes.splice(index, 1);
      }
    }
    setHelperMemberCodes([...helperMemberCodes]);
  };
  return (
    <div>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Button
          style={{ float: "right" }}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleClickOpenExisting}
        >
          Incomplete Application
        </Button>
        <Button
          style={{ float: "right" }}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
        >
          Create New Application
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="1000px"
      >
        {selectedRentalUnit === null ? (
          <DialogTitle id="form-dialog-title">
            Select Application You Wish To Apply
          </DialogTitle>
        ) : (
          <DialogTitle id="form-dialog-title">
            Select Family Members and Domestic Helpers
          </DialogTitle>
        )}
        {currentApplication.rentalUnit.length === 0 ? (
          <Alert severity="error">
            No Rental Unit Found for this Landlord, Click <b>Back</b> and then
            Click <b>RENTAL UNITS</b> to add rental unit
          </Alert>
        ) : (
          <></>
        )}
        <DialogContent>
          {selectedRentalUnit === null ? (
            <FormControl>
              <Autocomplete
                onChange={(event, value) => {
                  handleRentalUnitChange(value);
                }}
                id="standard-basic"
                options={tenantRental}
                getOptionLabel={(option) =>
                  "ApplNo: " +
                  option.applid +
                  ", Floor Number:" +
                  option.rentalunit.floorNo +
                  ", House No: " +
                  option.rentalunit.houseNo +
                  ", Address: " +
                  option.rentalunit.address1 +
                  ", " +
                  option.rentalunit.address2 +
                  " | " +
                  "Tenant Name: " +
                  option.tenant.firstName +
                  " " +
                  option.tenant.lastName
                }
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tenant and Rental Unit *"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          ) : (
            <Stack>
              <CheckBoxGroup
                type="family"
                selectedTenant={selectedRentalUnit}
                selectFamily={selectFamily}
              />
              <CheckBoxGroup
                type="helper"
                selectedTenant={selectedRentalUnit}
                selectHelper={selectHelper}
              />
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Recommendation
                </FormLabel>
                <RadioGroup
                  defaultValue="approve"
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(event) => setAction(event.target.value)}
                >
                  <FormControlLabel
                    value="approve"
                    control={<Radio />}
                    label="Recommend for Approval"
                  />
                  <FormControlLabel
                    value="reject"
                    control={<Radio />}
                    label="Recommend for Rejection"
                  />
                </RadioGroup>
              </FormControl>
              {action === "reject" && (
                <ComboBox remarks={remarks} setRemarks={setRemarks} />
              )}
              <Box minHeight={10} />
              <FormControl>
                <Button
                  disabled={action === "reject" && remarks === ""}
                  style={{ float: "right" }}
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={async () => {
                    await applyApplication();
                  }}
                >
                  Apply
                </Button>
              </FormControl>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
