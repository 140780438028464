import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { makeStyles } from "@material-ui/core/styles";

import { Backdrop, CircularProgress } from "@material-ui/core";


import Webcam from "react-webcam";
import { uploadCapturedPhoto } from "../../api/GlobalService";

const useStyle = makeStyles((theme) => ({
  dialogElements: {
    display: "flex",
  },
  dialogActions: {
    justifyContent: "center",
  },
  dialogTitle: {
    margin: "auto",
  },
  closeDialog: {
    width: "10%",
    margin: "auto",
    marginTop: "40px",
    marginBottom: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CameraComponent = ({setSnackBar,
  document,
  setUploading,
  addTenantDocument,
  setDocument,
  snackBar,
  label,setOpenCamera,memberCode,setOpen}) => {

  const webcamRef = React.useRef(null);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const classes = useStyle();

  const [imageSrc, setImageSrc] = React.useState(null); // stores the image in base64
  const [toggleAction, setToggleAction] = useState(false);
  const [croppedImage, setCroppedImage] = React.useState(null)
  const [cropper, setCropper] = React.useState(null);
  const [cropFlag, setCropFlag] = useState(false)
  const [webpString, setWebpString] = useState('');
  const [jpegDataUrl, setJpegDataUrl] = useState('');
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  const captureImage = async() => {
    setToggleAction(true);
    const imageSrc = await webcamRef.current.getScreenshot();
    await setImageSrc(imageSrc);
  };

  const handleCrop = () => {
    if (cropper) {
      const croppedImage = cropper.getCroppedCanvas().toDataURL()
      setCroppedImage(croppedImage)
      setCropFlag(false);
    }
  }

  const uploadImage = async () => {
    await uploadCapturedPhoto(
      setSnackBar,
     croppedImage? croppedImage : imageSrc,
      setUploading,
      addTenantDocument,
      setDocument,
      snackBar,
      label,
      setBackDropOpen,
      setWebpString,
      setJpegDataUrl,
      jpegDataUrl,
      setOpenCamera,
      memberCode,
      setOpen
    );
    // if (imageSrc) {
    //   const link = document.createElement("a");
    //   link.href = imageSrc;
    //   link.download = "captured_photo.png";
    //   link.click();
    // }
  };

  const constraints = {
    video: {
      facingMode: isFrontCamera ? 'user' : 'environment', // 'user' for front camera, 'environment' for rear camera
    },
  };

  const toggleCamera = () => {
    setIsFrontCamera(!isFrontCamera);
    if (webcamRef.current) {
      const tracks = webcamRef.current.video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        webcamRef.current.video.srcObject = stream;
      });
    }
  };

  return (
    <div>
         <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
        { cropFlag ?
         <Cropper
         style={{height: 400, width: '100%'}}
         src={imageSrc}
         initialAspectRatio={1}
         guides={true} // Set to true to show expandable grid lines
        //  cropBoxResizable={true}
        //  zoomable={true}
         viewMode={1}
        //  dragMode="move"
         responsive={true}
         autoCropArea={1}
         zoomTo={0.5}
          preview=".img-preview"
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          checkOrientation={false} 
         onInitialized={(instance) => {
           setCropper(instance)
         }}   />
        : <Box sx={{textAlign:"center",margin: "2rem 2rem"}}>
      {imageSrc ? (
        <img src={croppedImage ? croppedImage :imageSrc} alt="CapturedOrCropped" />
        ) : (
          <Webcam audio={false} ref={webcamRef} style={{width: "100%"}}/>
          )}
          </Box>}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "center",
          padding: "4rem 2rem",
        }}
      >
        {imageSrc && (
          <>
          <Button
            onClick={async() => {
              await setCropFlag(false);
              await setImageSrc(null);
              await setCroppedImage(null);
              await setToggleAction(false);
            }}
            variant="contained"
          >
            Reset
          </Button>
          {cropFlag? 
          <Button
          onClick={handleCrop}
          variant="contained"
        >
          Crop Image
        </Button>
          :<Button
          onClick={() => {
            setCropFlag(true);
            // setImageSrc(null);
            // setToggleAction(false);
          }}
          variant="contained"
        >
          Crop
        </Button>}
        </>

        )}
        <Button
          variant="contained"
          // disabled={imageSrc===null}
          onClick={toggleAction ? uploadImage : captureImage}
        >
          {toggleAction ? "Upload Photo" : "Capture Photo"}
        </Button>
        <button onClick={toggleCamera}>Toggle Camera</button>
      </Box>
    </div>
  );
};

export default CameraComponent;
