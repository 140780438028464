import React, { createContext, useReducer } from "react";
import ApplicationReducer from "./ApplicationReducer";

const applicationData = {
  tenantid: "",
  rentcode: "",
  landlordid: "",
  applStatus: { applstatus: "" },
  landlordcode: "",
  applType: "",
  logby: "",
};

export const ApplicationContext = createContext(applicationData);

export const ApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ApplicationReducer, applicationData);

  function setApplicationData(appData) {
    dispatch({
      type: "SET_APPLICATION_DATA",
      payload: appData,
    });
  }

  function resetApplicationData() {
    dispatch({
      type: "RESET_APPLICATION_DATA",
      payload: applicationData,
    });
  }

  // function getApplicationData() {
  //   dispatch({
  //     type: "GET_APPLICATION_DATA",
  //   });
  // }

  return (
    <ApplicationContext.Provider
      value={{
        applicationData: state,
        setApplicationData,
        resetApplicationData,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
