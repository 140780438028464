import {
  DialogActions,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import UserService from "../../api/UserService";
import MasterData from "../../api/MasterData";
import RentalUnitService from "../../api/RentalUnitService";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import AddLocalAuthorityDialog from "./AddLocalAuthorityDialog";
import AddLocalAuthorityUserDialog from "./AddLocalAuthorityUserDialog";
import { GroupingState, IntegratedGrouping } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
} from "@devexpress/dx-react-grid-material-ui";
import { Box, Paper } from "@mui/material";
import CustomCircularProgress from "../../components/customCirclularProgress/CustomCircularProgress";
import CustomDataTable from "../../components/data-table/CustomDataTable";

function AddLocalAuthorityFromAdmin({ history }) {
  const [ps, setPs] = useState("");
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [roles, setRoles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [localAuthorityCode, setLocalAuthorityCode] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [la, setLa] = useState({
    localAuthorityName: "",
    headManName: "",
    area: 0,
    ps: "",
  });

  const [landlordUser, setLandlordUser] = useState({
    username: "",
    password: "",
    usercode: "",
    role: [{}],
    email: "rilangkishabong@gmail.com",
  });

  const toggleRefresh = () => {
    setRefresh(!refresh);
  };

  const getRoles = () => {
    UserService.getRoles()
      .then((response) => {
        setRoles(response.data);
        console.log(response.data);
      })
      .catch((e, response) => {
        console.error(e);
        // alert(JSON.stringify(e.response.data));
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const columns = [
    {
      name: "locality",
      label: "Locality",
      options: {
        filter: true,
      },
    },
    {
      name: "localAuthorityName",
      label: "Sub-Locality Name",
      options: {
        filter: true,
      },
    },
    {
      name: "headManName",
      label: "Headman Name",
      options: {
        filter: true,
      },
    },
    {
      name: "users",
      label: "Users",
      options: {
        filter: true,
      },
    },
    {
      name: "action1",
      label: "Actions",
      options: {
        filter: true,
      },
    },
    {
      name: "policeStation",
      label: "Police Station",
      options: {
        filter: true,
      },
    },
  ];

  const getLocalAuthForAdmin = async () => {
    setLoading(true);
    const response = await RentalUnitService.getLocalAutorities();
    let data = [];
    data = response.data;
    for (var i = 0; i < data.length; i++) {
      data[i].id = i + 1;
      data[i].policeStation = response.data[i].ps.policeStationName;
      data[i].locality = response.data[i].locality.localityName;

      const userresponse = await UserService.getUserbycode(
        data[i].localAuthorityCode
      );
      userresponse.data !== null
        ? (data[i].users = userresponse.data)
        : (data[i].users = []);
    }
    setRows(data);
    setLoading(false);
  };

  useEffect(() => {
    // getDistricts();
    getLocalAuthForAdmin();
  }, [refresh]);

  return (
    <>
      <PageHeader
        title="Registered Sub-Localities"
        subTitle="Registered Sub-Localities from different localities of different police stations."
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      {/* <Button
        onClick={() => {
          setOpenDialog(true);
        }}
        variant="contained"
        style={{ backgroundColor: "#F3A712", color: "#fff" }}
      >
        + Add Locality
      </Button> */}

      <div style={{ marginTop: "2%", position: "relative" }}>
        {/* <DataGrid
          rows={data}
          columns={columns}
          rowKey="applid"
          onGridSort={(sortColumn, sortDirection) => {
            console.log("sort");
            setData(sortRows(data, sortColumn, sortDirection));
          }}
          selectedRows={selectedRows}
          onSelectedRowsChange={(rows) => {
            console.log(rows);
          }}
          rowKeyGetter={rowKeyGetter}
          style={{ width: "100%" }}
        /> */}
        {loading ? (
          <CustomCircularProgress />
        ) : (
          <Paper>
            <CustomDataTable
              rows={rows}
              columns={columns}
              title="Registered Sub-Localities"
            />
          </Paper>
        )}

        <Dialog // Dialog for Creating Local Auth User
          open={open}
          onClose={() => setOpen(!open)}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h3" align="center">
              Create Locality User
            </Typography>
          </DialogTitle>
          <DialogContent>
            <AddLocalAuthorityUserDialog
              landlordUser={landlordUser}
              setLandlordUser={setLandlordUser}
              roles={roles}
              localAuthorityCode={localAuthorityCode}
              toggleRefresh={toggleRefresh}
            />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog // Dialog for Creating Sub-Locality
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3" align="center">
            Create Locality
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AddLocalAuthorityDialog
            getLocalAuth={getLocalAuthForAdmin}
            setLa={setLa}
            la={la}
            ps={ps}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
export default AddLocalAuthorityFromAdmin;
