import { Box, Card, CardActionArea, Container, Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { replaceUnderScoreWithSpace } from "../../api/GlobalService";
// import Plyr from "plyr-react";
// import "plyr-react/plyr.css";

const manuals = ["Application_Flow", "Landlord_Registration"];

const VideoManuals = () => {
  const [selectedVideo, setSelectedVideo] = useState("");
  const textInput = useRef(null);

  function handlePause() {
    textInput.current.pause();
  }
  //   function handlePlay() {
  //     textInput.current.play();
  //   }
  return (
    <Grid container spacing={1} flexDirection={"column"}>
      <Grid
        item
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: 1,
        }}
      >
        {manuals.map((manual, index) => (
          <Card
           
            key={index}
            onClick={() => setSelectedVideo(manual)}
          >
            <CardActionArea  sx={{
              display: "flex",
              flexGrow: { xs: 1, sm: 1, md: 0, lg: 0 },
              gap: "20%",
              // cursor:"pointer"
            }}>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography variant="subtitle1">
                  {replaceUnderScoreWithSpace(manual)}
                </Typography>
              </CardContent>
              {/* <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                <IconButton
                  aria-label="play/pause"
                  onClick={() => setSelectedVideo(manual)}
                >
                  {selectedVideo === manual ? (
                    <PauseIcon
                      sx={{ height: 38, width: 38 }}
                      onClick={handlePause}
                    />
                  ) : (
                    <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                  )}
                </IconButton>
              </Box> */}
            </Box>
              <CardMedia
                component="img"
                sx={{ width: { lg: "70%", sm: "50%", xs: "50%" } }}
                image={process.env.PUBLIC_URL + "./favicon.ico"}
                alt={manual}
              />
            </CardActionArea>
          </Card>
        ))}
      </Grid>
      <Box sx={{ minHeight: 50 }} />

      <video
        src={process.env.PUBLIC_URL + `./${selectedVideo}.mp4`}
        autoPlay
        loop
        ref={textInput}
        controls
        style={{ width: "100%" }}
      ></video>
    </Grid>
  );
};

export default VideoManuals;
