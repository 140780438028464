import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Fade from "@mui/material/Fade";
import useStyles from "./styles";
import { FormControl, Skeleton, Stack, TextField } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box } from "@mui/system";
import UserService from "../../api/UserService";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useUserDispatch, loginUser } from "../../context/UserContext";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const LoginDialog = ({
  errorMessage,
  captcha,
  setPasswordValue,
  setCaptcha,
  passwordValue,
  captchaValue,
  setCaptchaValue,
  isLoading,
  publicKey,
  history,
  setIsLoading,
  setError,
  setActiveTabId,
  setErrorMessage,
  setForgotPswd,
  setOpenLoginDialog,
}) => {
  var classes = useStyles();
  var [loginValue, setLoginValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  var userDispatch = useUserDispatch();

  const handleSubmit = async () => {
    await userDispatch({ type: "BG_LOGIN_FALSE" });
    // console.log("bgFlag: ", backgroundFlag);
    loginUser(
      publicKey,
      userDispatch,
      loginValue,
      passwordValue,
      captchaValue,
      history,
      setIsLoading,
      setError,
      setActiveTabId,
      false,
      setErrorMessage,
      setCaptchaValue
      // backgroundFlag      old-one
    );
  };

  useEffect(() => {
    if (loginValue.length > 0 && passwordValue.length > 0) {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          console.log("Enter key was pressed. Run your function.");
          event.preventDefault();
          // callMyFunction();
          handleSubmit();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  });

  const handleDownload = async (base64img, type) => {
    var blob = base64toBlob(base64img, type);
    const blobUrl = URL.createObjectURL(blob);
    setCaptcha(blobUrl);
  };

  function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
  }

  return (
    <div>
      <Card
        style={{
          backgroundColor: fade("rgb(255,255,255)", 0.1),
          padding: "20px 20px",
          position: "relative",
        }}
      >
        <React.Fragment>
          <Fade in={errorMessage !== null}>
            <Typography className={classes.errorMessage} variant="h4">
              {errorMessage !== null
                ? errorMessage
                : "Server Issue. Please try again later!"}
            </Typography>
          </Fade>
          <TextField
            autoFocus
            id="text"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
              className: classes.textColor,
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon />
                </InputAdornment>
              ),
            }}
            value={loginValue}
            onChange={(e) => setLoginValue(e.target.value)}
            margin="normal"
            placeholder="Username"
            name="password"
            // autoComplete="off"
            type="text"
            fullWidth
            autoComplete="new-password"
          />
          <TextField
            id="password"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
              className: classes.textColor,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            margin="normal"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            autoComplete="new-password"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl>
              <TextField
                size="small"
                id="text"
                // InputProps={{
                //   classes: {
                //     underline: classes.textFieldUnderline,
                //     input: classes.textField,
                //   },
                //   className: classes.textColor,
                // }}
                value={captchaValue}
                onChange={(e) => setCaptchaValue(e.target.value)}
                margin="normal"
                placeholder="Captcha"
                name="password"
                autoComplete="off"
                type="text"
              />
            </FormControl>

            {captcha === "" ? (
              <Skeleton variant="rectangular" width={130} height={40} />
            ) : (
              <Box
                component="img"
                sx={{
                  height: 50,
                  width: { xs: 130, sm: 130 },
                }}
                alt="image captcha"
                src={captcha}
              />
            )}

            <Box sx={{ backgroundColor: "#29335C", borderRadius: "10%" }}>
              <IconButton
                onClick={() => {
                  UserService.getCaptcha()
                    .then((response) => {
                      handleDownload(response.data, "image/jpg");
                    })
                    .catch((err) => {
                      console.log("Error in captcha: ", err);
                    });
                }}
                size="large"
                style={{ color: "#fff" }}
                // color="secondary"
              >
                <AutorenewIcon />
              </IconButton>
            </Box>
          </Box>

          <Box className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <Button
                disabled={loginValue.length === 0 || passwordValue.length === 0}
                onClick={handleSubmit}
                variant="contained"
                // style={{ backgroundColor: "#29335C", color: "#fff" }}
                size="large"
                color="primary"
              >
                Login
              </Button>
            )}
            <Button
              style={{ color: "#29424f",fontWeight: "normal" }}
              color="primary"
              size="small"
              startIcon={<InfoOutlinedIcon/>}
              className={classes.forgetButton}
              onClick={() => {
                setOpenLoginDialog(false);
                setForgotPswd(true);
              }}
            >
              Forgot/Change Password
            </Button>
          </Box>
        </React.Fragment>
      </Card>
    </div>
  );
};

export default LoginDialog;
