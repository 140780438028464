import * as React from "react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import "react-data-grid/dist/react-data-grid.css";
import ApplicationService from "../../api/ApplicationService";
import { GlobalContext } from "../../context/GlobalState";
import { makeStyles } from "@material-ui/core/styles";

import TenantTab from "../offlineapplication/TenantTab";
import TenantService from "../../api/TenantService";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { IconButton, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CloseIcon from "@material-ui/icons/Close";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import { getDecryptedData, storeEncryptedData } from "../../api/GlobalService";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TenantsDataTable(props) {
  const classes = useStyles();
  const { currentApplication, editTenantApplication } = useContext(
    GlobalContext
  );
  const [applications, setApplications] = useState([]);
  const [selectedRentalUnit, setSelectedRentalUnit] = useState(null);
  const [revisitApp, setRevisitApp] = useState(false);
  const [dupTenantApplication, setDupTenantApplication] = useState(null);
  const [firstPage, setFirstPage] = useState(false);
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [lastPage, setLastPage] = useState(false);

  let arr = [];
  for (var i = 1; i <= totalPages; i++) {
    arr.push(i);
  }

  const columns = [
    {
      name: "applid",
      label: "Application ID",
    },
    {
      name: "rentcode",
      label: "Rent Code",
      options: {
        display: false
      }
    },
    {
      name: "tenantid",
      label: "Tenant Id",
      options: {
        display: false
      }
    },
    { name: "tenantname", label: "Tenant Name" },
    { name: "landlordname", label: "Landlord name" },
    { name: "rentaladdress", label: "Rental Address" },
    {
      name: "action",
      label: "Action",
      options: {

        customBodyRender: (value, tableMeta) => {
          // console.log("Row Data is : ", row);

          return (
            <Stack>
              <Button
                size={"small"}
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedRentalUnit({
                    ...selectedRentalUnit,
                    rentcode: tableMeta.rowData[1],
                  });
                  TenantService.getTenantById(tableMeta.rowData[2]).then((response) => {
                    setDupTenantApplication({ ...response.data, exist: true });

                    sessionStorage.setItem("tenantId", tableMeta.rowData[2]);
                    // storeEncryptedData("tenantId", tableMeta.rowData[2]);

                    // editTenantApplication({ ...response.data, exist: true });
                    setRevisitApp(true);
                  });
                }}
              >
                Re-visit Tenant
                {/* <Link to="/app/tenant-revisit">Tenant-Form</Link> */}
              </Button>
            </Stack>
          );
        },
      }
    },

    // { key: 'rentcode', name: 'Rental Unit', resizable: true, formatter:(props)=>'RentCode: '+props.row.rentalunit.rentcode+', HouseNo: '+props.row.rentalunit.houseNo+', Floor No: '+props.row.rentalunit.floorNo+', Address: '+ props.row.rentalunit.address1+', '+ props.row.rentalunit.address2
    // },
    // { key: 'tenant', name: 'Tenant', resizable: true, formatter:(props)=>'EpicNo: '+props.row.tenant.epicNo+', Name: '+props.row.tenant.firstName+' '+props.row.tenant.lastName
    // },
  ];

  const getApplications = async () => {
    if (currentApplication.id) {
      const response = await ApplicationService.getIncompleteApplicationByLandlordEpicNumber(
        // getDecryptedData("username"),
        sessionStorage.getItem("username"),
        currentApplication.id,
        selectedPageNumber
      );

      if (lastPage !== response.data.iscurrentPageLast) setLastPage(!lastPage);

      if (firstPage !== response.data.iscurrentPageFirst)
        setFirstPage(!firstPage);

      if (totalPages === 0) {
        setTotalPages(response.data.totalPages);
        // setLinks(response.data.applDto);
      }

      setApplications(response.data.applDto);
    }
  };
  useEffect(() => {
    getApplications();
  }, [selectedPageNumber]);

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <CustomDataTable rows={applications} columns={columns} title="Incomplete Applications of different Tenants for the Landlord" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={firstPage}
            onClick={() => setSelectedPageNumber(selectedPageNumber - 1)}
          >
            <NavigateBeforeIcon />
          </Button>
          {arr.map((number) => (
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedPageNumber(number - 1);
                // console.log(`Page number is ${number}`);
              }}
              key={number}
            >
              {number}
            </Button>
          ))}
          <Button
            disabled={lastPage}
            onClick={() => setSelectedPageNumber(selectedPageNumber + 1)}
          >
            <NavigateNextIcon />
          </Button>
        </div>
      </div>
      <Dialog
        open={revisitApp}
        onClose={() => setRevisitApp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="1000px"
        minHeight="60%"
        maxHeight="60%"
      >
        <IconButton
          style={{
            backgroundColor: "#eb5809",
            color: "#fff",
            position: "absolute",
            right: 0,
            top: 0,
            height: "20px",
            width: "20px",
            borderRadius: 1,
          }}
          onClick={() => {
            setRevisitApp(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">
          Re-visit Tenant Application
        </DialogTitle>
        <DialogContent>
          <TenantTab
            selectedRentalUnit={selectedRentalUnit}
            dupTenantApplication={dupTenantApplication}
            activeStep={props.activeStep}
            setActiveStep={props.setActiveStep}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
