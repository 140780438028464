export default (state, action) => {
  switch (action.type) {
    case "SET_APPLICATION_DATA":
      return {
        state: action.payload,
      };

    case "RESET_APPLICATION_DATA":
      return {
        state: action.payload,
      };
    // case "GET_APPLICATION_DATA":
    //   return {
    //     state,
    //   };
    default: {
      return state;
    }
  }
};
