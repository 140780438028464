import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Backdrop, CircularProgress, FormControl } from "@material-ui/core";
import { useState } from "react";

import { useContext } from "react";
// import { useUserDispatch } from "../../context/UserContext";
import { GlobalContext } from "../../context/GlobalState";
import LandlordService from "../../api/LandlordService";
import ApplicationService from "../../api/ApplicationService";
import TenantService from "../../api/TenantService";
import { ApplicationContext } from "../../context/ApplicationState";
import AddIcon from "@material-ui/icons/Add";
import { Alert, Autocomplete, Snackbar, Stack } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CheckBoxGroup from "../tenants/applications/CheckBoxGroup";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import httpApi from "../../api/http-common-apikey";
import httpApiApp from "../../api/http-common-apikey-application";
import JSEncrypt from "jsencrypt";
import UserService from "../../api/UserService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  downloadApp: {
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  responsiveLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px', // Adjust the font size for smaller screens
    },
    // Add more styles as needed for different breakpoints
  },
  responsiveButtonText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px', // Adjust the font size for smaller screens
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '9px', // Adjust the font size for smaller screens
      padding: "2px 16px"
    },
    // Add more styles as needed for different breakpoints
  },
}));

// function getSteps() {
//   return ;
// }

export default function CreateApplication(props) {
  const [download, setDownload] = useState(true);
  const [tenantExist, setTenantExist] = useState(false);
  const [selectedRentalUnit, setSelectedRentalUnit] = useState(null);
  const [landlordExist, setLandlordExist] = useState(false);
  const [open, setOpen] = useState(false);
  const [familyMemberCodes, setFamilyMemberCodes] = useState([]);
  const [helperMemberCodes, setHelperMemberCodes] = useState([]);
  const [application, setApplication] = useState(null); // the application to be created.
  const [apply, setApply] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const {
    existingApplication,
    currentApplication,
    runningApplication,
    getRunningApplication,
    newLandlordApplication,
    newTenantApplication,
  } = useContext(GlobalContext);
  const {
    applicationData,
    setApplicationData,
    resetApplicationData,
  } = useContext(ApplicationContext);

  var [epicNo, setEpicNo] = useState();

  const classes = useStyles();

  var [epicNumber, setEpicNumber] = useState();
  const [lndEpic, setLndEpic] = useState("");
  var [otp, setOtp] = useState();
  const [otpApiKey, setOtpApiKey] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [appGenerated, setAppGenerated] = useState(false);

  const [backDropOpen, setBackDropOpen] = useState(false);

  const [errors, setErrors] = useState({
    lndError: false,
    lndErrorText: "",
    otpError: false,
    otpErrorText: "",
    tntError: false,
    tntErrorText: "",
  });
  const [publicKey, setPublicKey] = useState("");

  useEffect(() => {
    UserService.getPublicKey()
      .then((response) => setPublicKey(response.data))
      .catch((error) => console.log(error));
  }, []);

  const handleRentalUnitChange = (value) => {
    setSelectedRentalUnit(value);
  };

  const selectFamily = (memberCode, action) => {
    if (action === "ADD") familyMemberCodes.push(memberCode);
    else if (action === "REMOVE") {
      const index = familyMemberCodes.indexOf(memberCode);
      if (index > -1) {
        familyMemberCodes.splice(index, 1);
      }
    }
    setFamilyMemberCodes([...familyMemberCodes]);
  };
  const selectHelper = (memberCode, action) => {
    if (action === "ADD") helperMemberCodes.push(memberCode);
    else if (action === "REMOVE") {
      const index = helperMemberCodes.indexOf(memberCode);
      if (index > -1) {
        helperMemberCodes.splice(index, 1);
      }
    }
    setHelperMemberCodes([...helperMemberCodes]);
  };

  const handleClickOpen = () => {
    // await setApplicationData(appData);
    console.log("Application context: ", applicationData);
    TenantService.createApplication(applicationData.state, otpApiKey).then(
      (res) => {
        setApplication(res.data);
        setAppGenerated(true);
      }
    );
    // setSelectedRentalUnit(null);
  };

  const handleDownload = async () => {
    setBackDropOpen(true);
    setDownload((download) => !download);
    console.log(runningApplication);
    const base64Pdf = await ApplicationService.getApplicationPdf(
      runningApplication
    );
    setDownload((download) => !download);
    var blob = base64toBlob(base64Pdf.data.base64img);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
      setBackDropOpen(false);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      setBackDropOpen(false);
    }
  };
  function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  const applyApplication = async () => {
    console.log("applID: ", application.applid);
    const data = {
      membercode: familyMemberCodes,
      helperCode: helperMemberCodes,
      applid: application.applid,
    };

    try {
      const response = await ApplicationService.addFamilyAndHelperMembers(data);
      console.log(response.data);
      setApply(true);
      if (response.status === 200) {
        setSnackBar({
          message:
            "Application applied successfully! You can download the application below.",
          severity: "success",
          open: true,
        });
        // finish(true);
        getRunningApplication(application.applid);
        resetApplicationData();
        newLandlordApplication();
        newTenantApplication();
        setOpen(!open);
      } else {
        setSnackBar({
          message: "Error in applying here..!",
          severity: "error",
          open: true,
        });
        setOpen(false);
      }
    } catch (err) {
      setSnackBar({
        message: "Error in applying..!",
        severity: "error",
        open: true,
      });
      setOpen(false);
    }
  };

  console.log("Current Application: ", application);

  return (
    <Container>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={backDropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={snackBar.open}
          // autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return; // Ignore the click outside behavior
            }
            setSnackBar({ ...snackBar, open: false });
          }}
        >
          <Alert
            onClose={() => {
              setSnackBar({ ...snackBar, open: false }); // Correctly close the Snackbar when 'x' is clicked
            }}
            severity={snackBar.severity}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>

        <form className={classes.root} noValidate autoComplete="off">
          <Box
            sx={{
              bgcolor: "background.paper",
              width: { sm: "auto", md: 500 },
              position: { sm: "static", md: "relative" },
              minHeight: { sm: "auto", md: 200 },
              padding: "10px"
            }}
          >
            <Stack spacing={4} direction="column">
              <Box
                sx={{
                  display: { lg: "grid", md: "grid", sm: "flex", xs: "flex" },
                  flexDirection: { sm: "column", xs: "column" },
                  justifyContent: { sm: "space-between", xs: "space-between" },
                  gridTemplateColumns: "3fr 1fr",
                  gridGap: "5%",
                  alignItems: { xs: "normal", sm: "normal", md: "center" }

                }}
              >
                <FormControl>
                  <TextField
                    size="small"
                    variant="outlined"
                    disabled={otpSent}
                    id="epicnumber"
                    onChange={(event) => {
                      setLndEpic(event.target.value.toUpperCase());
                    }}
                    value={lndEpic}
                    label="Landlord's Epic Card Number..."
                    error={errors.lndError}
                    helperText={errors.lndError ? errors.lndErrorText : ""}
                    InputLabelProps={{
                      className: classes.responsiveLabel,
                    }}
                  />
                </FormControl>
                <Button
                  disabled={lndEpic.length < 8 || otpSent}
                  color="primary"
                  variant="outlined"
                  style={{ height: "2rem" }}
                  onClick={() =>
                    httpApi
                      .get(`apikey/getOTP/${lndEpic}`)
                      .then((res) => {
                        setSnackBar({
                          message:
                            // "OTP has been sent to registered mobile number or email.",
                            "Please enter OTP received in your registered mobile number or email.",
                          severity: "success",
                          open: true,
                        });
                        setOtpSent(true);
                        setErrors({
                          ...errors,
                          lndError: false,
                        });
                      })
                      .catch((err) => {
                        setErrors({
                          ...errors,
                          lndError: true,
                          lndErrorText: "Landlord epic number does not exist",
                        });
                      })
                  }
                >
                  Get OTP
                </Button>
              </Box>
              <Box
                sx={{
                  display: { lg: "grid", md: "grid", sm: "flex", xs: "flex" },
                  flexDirection: { sm: "column", xs: "column" },
                  justifyContent: { sm: "space-between", xs: "space-between" },
                  gridTemplateColumns: "3fr 1fr",
                  gridGap: "5%",
                  alignItems: { xs: "normal", sm: "normal", md: "center" }
                }}
              >
                <FormControl>
                  <TextField
                    variant="outlined"
                    size="small"
                    disabled={!otpSent || verified}
                    id="otp"
                    onChange={async (event) => {
                      otp = event.target.value;
                      setOtpValue(event.target.value);
                    }}
                    value={otp}
                    label="OTP"
                    error={errors.otpError}
                    helperText={errors.otpError ? errors.otpErrorText : ""}
                    InputLabelProps={{
                      className: classes.responsiveLabel,
                    }}
                  />
                </FormControl>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={otpValue < 5 || verified}
                  style={{ height: "2rem" }}

                  onClick={async () => {
                    try {
                      let crypt = new JSEncrypt();
                      crypt.setPublicKey(publicKey);
                      let enc = crypt.encrypt(otpValue);
                      httpApi.defaults.headers["Content-type"] =
                        "application/json";
                      const response = await httpApi.post(
                        `apikey/validateOTP/${lndEpic}`,
                        enc
                      );
                      httpApiApp.defaults.headers["API-key"] = response.data;
                      setVerified(true);

                      await LandlordService.getByEpicNumberAnonymous(lndEpic)
                        .then((response) => {
                          setErrors({
                            ...errors,
                            lndError: false,
                            lndErrorText: "",
                          });
                          setErrors({
                            ...errors,
                            otpError: false,
                          });
                          response.data.exist = true;
                          response.data.id = response.data.landlord_id;
                          setSnackBar({ ...snackBar, open: false });

                          existingApplication(response.data);
                          setLandlordExist(true);
                        })
                        .catch((error) => {
                          setErrors({
                            ...errors,
                            lndError: true,
                            lndErrorText: error.response.data.message,
                          });
                          setLandlordExist(false);
                        });
                      // setErrors({
                      //   ...errors,
                      //   tntError: false,
                      //   tntErrorText: "",
                      // });
                      setOtpApiKey(response.data);
                      setLandlordExist(true);
                      delete httpApi.defaults.headers["Content-type"];
                    } catch (error) {
                      console.log(error.response);

                      setErrors({
                        ...errors,
                        otpError: true,
                        otpErrorText: "Invlalid OTP!",
                      });
                    }
                  }}
                >
                  Verify
                </Button>
              </Box>
              {landlordExist && (
                <FormControl>
                  <Autocomplete
                    disabled={appGenerated}
                    size={"small"}
                    onChange={(event, value) => {
                      handleRentalUnitChange(value);
                    }}
                    id="standard-basic"
                    options={currentApplication.rentalUnit.filter(
                      (unit) => unit.vacant === true
                    )}
                    getOptionLabel={(option) =>
                      option.vacant === true &&
                      "Floor Number:" +
                      option.floorNo +
                      "/ House Number:" +
                      option.houseNo
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rental Unit *"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.responsiveLabel,
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
              <FormControl>
                <TextField
                  disabled={selectedRentalUnit === null || appGenerated}
                  id="epic-number"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    className: classes.responsiveLabel,
                  }}
                  onChange={async (event) => {
                    epicNo = event.target.value;
                    if (epicNo.length >= 8) {
                      try {
                        const response = await TenantService.getTenantByIdAnonymous(
                          epicNo
                        );
                        setErrors({
                          ...errors,
                          tntError: false,
                          tntErrorText: "",
                        });

                        var appData = {
                          tenantid: response.data.id,
                          rentcode: selectedRentalUnit.rentcode,
                          landlordid: currentApplication.id,
                          applStatus: {
                            applstatus: "LARP",
                          },
                          landlordcode: currentApplication.id,
                          applType: "APPLPS",
                          // logby: sessionStorage.getItem("username"),
                          logby: currentApplication.id,
                        };
                        setApplicationData(appData);
                        setTenantExist(true);
                      } catch (error) {
                        // console.log(error.response);
                        setErrors({
                          ...errors,
                          tntError: true,
                          tntErrorText: "Tenant does not exist!",
                        });
                      }
                    }
                  }}
                  value={epicNo}
                  label="Tenant's ID"
                  error={errors.tntError}
                  helperText={errors.tntError ? errors.tntErrorText : ""} /
                >
              </FormControl>

              <Stack direction="row" spacing={1}>
                <Button
                  disabled={
                    !tenantExist ||
                    appGenerated ||
                    selectedRentalUnit === null ||
                    errors.tntError
                  }
                  size="small"
                  color="secondary"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}
                  className={classes.responsiveButtonText}
                >
                  Generate Application
                </Button>
                <Button
                  disabled={!open}
                  style={{ float: "right" }}
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={handleDownload}
                  className={classes.responsiveButtonText}
                >
                  Download Application
                </Button>
              </Stack>
              {/* <Button
                // style={{ float: "right" }}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  setEpicNumber("");
                  setEpicNo("");
                }}
              >
                Reset
              </Button> */}

              {application !== null && (
                <div>
                  <CheckBoxGroup
                    apply={apply}
                    type="family"
                    selectedTenant={application}
                    selectFamily={selectFamily}
                  />
                  <CheckBoxGroup
                    apply={apply}
                    type="helper"
                    selectedTenant={application}
                    selectHelper={selectHelper}
                  />
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={async () => {
                      await applyApplication();
                    }}
                    disabled={apply}
                    size="small"
                    className={classes.responsiveButtonText}
                  >
                    Apply
                  </Button>
                </div>
              )}
            </Stack>
          </Box>
        </form>

        <div>
          <div>
            {/* <Typography className={classes.instructions}>
            All steps completed
          </Typography> */}

            {/* <Button
            onClick={handleDownload}
            color="primary"
            disabled={!download}
            className={classes.downloadApp}
          >
            Download the Complete Application
          </Button> */}
          </div>
        </div>
      </div>
    </Container>
  );
}
