import { Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ApplicationService from "../../api/ApplicationService";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import { GlobalContext } from "../../context/GlobalState";
import DeleteIcon from "@material-ui/icons/Delete";

const ApplicationsList = () => {
  const [rows, setRows] = useState([]);
  const { currentApplication } = useContext(GlobalContext);
  const [toggle, setToggle] = useState(false);

  const columns = [
    {
      name: "slno",
      label: "Sl.No",
      options: {
        display: false,
        filter: true,
      },
    },
    {
      name: "applid",
      label: "Application ID",
      options: {
        filter: true,
      },
    },
    {
      name: "applStatus.description",
      label: "Status",
      options: {
        filter: true,
      },
    },
    {
      name: "landlordcode",
      label: "Landlord ID",
      options: {
        filter: true,
      },
    },
    {
      name: "tenant.id",
      label: "Tenant ID",
      options: {
        filter: true,
      },
    },
    {
      name: "logby",
      label: "Applied By",
      options: {
        filter: true,
      },
    },
    {
      name: "applStatus.applstatus",
      label: "Status Code",
      options: {
        filter: true,
        display: false,
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              // disabled={
              //   tableMeta.rowData[5] !== currentApplication.id ||
              //   tableMeta.rowData[6] === "PLVP"
              // }
              variant="outlined"
              endIcon={<DeleteIcon />}
              onClick={() => {
                ApplicationService.deleteApplicationAnonymous(
                  tableMeta.rowData[1],
                  currentApplication.id
                )
                  .then((response) => {
                    setToggle(!toggle);
                    alert(response.data.message);
                  })
                  .catch((err) =>
                    alert(
                      err.response.data.message +
                        ". Application already forwarded."
                    )
                  );
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    ApplicationService.getAllApplicationListByLandlord(currentApplication.id)
      .then((response) => setRows(response.data))
      .catch((err) => console.log(err));
  }, [toggle]);

  return (
    <div>
      <Typography align="center" sx={{ color: "red" }}>
        Note: Applications cannot be deleted if (1)They are applied by other
        users. or (2)They are approved / rejected by Local Authorities.
      </Typography>
      <CustomDataTable columns={columns} rows={rows} title="Applications" />
    </div>
  );
};

export default ApplicationsList;
