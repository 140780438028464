import { Grid } from "@mui/material";
import React from "react";
import EmailUpdateForm from "./EmailUpdateForm";
import UserDetails from "./UserDetails";
import PageHeader from "../../components/PageHeader";
import PersonIcon from "@mui/icons-material/Person";
import { Paper } from "@material-ui/core";

const UserProfile = () => {
  return (
    <>
    <Grid container direction="row" spacing={3} justifyContent="center">
         <Grid item lg="8" md="5" sm="12" xs="12">

    <Paper>
    <PageHeader title="Profile" subTitle="Account details" icon={<PersonIcon fontSize="large" />}  />

    </Paper>
    </Grid>
    </Grid>
      <Grid container direction="row" spacing={3} justifyContent="center">
        <Grid item lg="8" md="5" sm="12" xs="12">
          <UserDetails />
        </Grid>
        <Grid item lg="8" md="5" sm="12" xs="12">
          <EmailUpdateForm
            title="Email Updation"
            subheader="Enter your new email below to update email."
            placeHolder="New Email"
          />
        </Grid>
      </Grid>
      {/* <ProfileForm /> */}
    </>
  );
};

export default UserProfile;
