import { makeStyles, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import React from "react";
import { useState, useEffect } from "react";

// const useStyle = makeStyles({
//     fontW
// })

const Timer = (props) => {
  const { initialMinute, initialSeconds } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Box sx={{ backgroundColor: "lightyellow" }}>
      {minutes === 0 && seconds === 0 ? null : (
        <Typography
          style={{ color: "red", fontWeight: "bold" }}
          variant="subtitle1"
        >
          {" "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
      )}
    </Box>
  );
};

export default Timer;
