import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import MenuIcon from "@mui/icons-material/Menu";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import {
  Backdrop,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField,
} from "@material-ui/core";
import LandlordDetails from "./LandlordDetails";
import Application from "./Application";

import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import ApplicationService from "../../api/ApplicationService";
import { useState } from "react";
import UserService from "../../api/UserService";
import { Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import ComboBox from "../../components/combo-box/ComboBox";
import { getDecryptedData } from "../../api/GlobalService";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#232338",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
    // fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  viewAppButton: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.4rem",
    },
  },
  actionButton: {
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ApplicationDetails(props) {
  const classes = useStyles();
  const [prompt, setPrompt] = useState({ reject: false, approve: false });
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { processApplication, openSnackBar, getApplications } = useContext(
    GlobalContext
  );
  const { handleClose, open, refresh, setRefresh, view } = props;
  const [remarkOpen, setRemarkOpen] = useState(false);
  const [remarks, setRemarks] = useState();
  const handleRemarkDialogClose = () => {
    setRemarkOpen(false);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDownload = async () => {
    setIsLoading(true);
    const base64Pdf = await ApplicationService.getApplicationPdfAsUser(
      processApplication.appl.applid
    );
    var blob = base64toBlob(base64Pdf.data.base64img);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
    setIsLoading(false);
  };
  function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
  }

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleDownload}>
        <IconButton size="large" color="inherit">
          <AppRegistrationIcon />
        </IconButton>
        <p>View Complete Application</p>
      </MenuItem>
    </Menu>
  );
  const approveApplication = async () => {
    // const role = getDecryptedData("role");
    const role = sessionStorage.getItem("role");
    const data = {
      applid: processApplication.appl.applid,
      applStatus: {
        applstatus: role === "MODERATOR" ? "LAVP" : "PLVP",
      },
      appltype: "APPLPS",
      remarks: "(May be Accepted)",
    };
    try {
      const result = await ApplicationService.updateApplication(data);
      openSnackBar({
        message: "Successfully Approved Application",
        severity: "success",
        open: true,
      });
      setPrompt(prevState => ({
        ...prevState,
        reject: false,
        approve: false
      }));


      setRefresh(!refresh);
      handleClose(false);
    } catch (error) {
      setPrompt(prevState => ({
        ...prevState,
        reject: false,
        approve: false
      }));

      console.log(error);
      // handle this
    }
  };

  const rejectApplication = async () => {
    const data = {
      applid: processApplication.appl.applid,
      applStatus: {
        applstatus:
          // getDecryptedData("role") === "MODERATOR" ? "LAVP" : "RJT",
          sessionStorage.getItem("role") === "MODERATOR" ? "LAVP" : "RJT",
      },
      appltype: "APPLPS",
      remarks: remarks,
    };
    try {
      await ApplicationService.updateApplication(data);
      setRefresh(!refresh);
    } catch (error) {
      // handle this
    }
  };

  // console.log(processApplication);
  return (
    <div>
      <Dialog
        open={remarkOpen}
        onClose={handleRemarkDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Remarks</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please justify the reason for rejection.
          </DialogContentText>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Remarks"
            type="text"
            fullWidth
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
          /> */}
          <ComboBox remarks={remarks} setRemarks={setRemarks} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemarkDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await rejectApplication();
              setRemarkOpen(false);
              handleClose(false);
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Application Number:{" "}
              {processApplication != undefined
                ? processApplication.appl.applid
                : "NA"}
            </Typography>

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                autoFocus
                // color="primary"
                style={{ backgroundColor: "#C36241", color: "#fff" }}
                variant="contained"
                onClick={handleDownload}
                className={classes.viewAppButton}
              >
                {" "}
                View Complete Application
              </Button>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}

        <Box
          sx={{
            padding: {
              lg: "30px 100px 100px 100px",
              md: "30px 100px 100px 100px",
            },
          }}
        >
          <Application />
        </Box>

        {view ? (
          <></>
        ) : (
          <DialogActions className={classes.actionButton}>
            <Button
              onClick={async () => {
                // await approveApplication();
                setPrompt({ ...prompt, approve: true });
              }}
              variant="contained"
              style={{ backgroundColor: "#556B2F", color: "#fff" }}
            >
              {/* {getDecryptedData("role") === "MODERATOR" */}
              {sessionStorage.getItem("role") === "MODERATOR"
                ? "Recommend for Approval"
                : "Accept Application"}
            </Button>
            <Button
              onClick={() => {
                // setRemarkOpen(true);
                setPrompt({ ...prompt, reject: true });
              }}
              variant="contained"
              style={{ backgroundColor: "#8B0000", color: "#fff" }}
            >
              {/* {getDecryptedData("role") === "MODERATOR" */}
              {sessionStorage.getItem("role") === "MODERATOR"
                ? "Recommend for Rejection"
                : "Decline Application"}
            </Button>
          </DialogActions>
        )}
        <Dialog
          open={prompt.reject || prompt.approve}
          onClose={async () =>
            await setPrompt(prevState => ({
              ...prevState,
              reject: false,
              approve: false
            }))
          }
          fullWidth
        >
          <DialogContent>
            <DialogContentText>
              <Typography align="center">
                Please confirm your{" "}
                {prompt.reject ? "rejection" : prompt.approve ? "approval" : ""}
                .
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actionButton}>
            <IconButton
              style={{ backgroundColor: "#556B2F", color: "#fff" }}
              onClick={async () => {
                if (prompt.reject) {
                  setPrompt(prevState => ({
                    ...prevState,
                    reject: false,
                    approve: false
                  }));
                  setRemarkOpen(true)
                }
                else {
                  setPrompt(prevState => ({
                    ...prevState,
                    reject: false,
                    approve: false
                  }));
                  await approveApplication();
                }
                // setPrompt({ ...prompt, reject: false, approve: false })
              }
              }
            >
              <CheckCircleOutlineIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                setPrompt({ ...prompt, reject: false, approve: false })
              }
              style={{ backgroundColor: "#8B0000", color: "#fff" }}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </Dialog>
    </div>
  );
}
