import React, { useEffect } from "react";
import { makeStyles, responsiveFontSizes } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Widget from "../../components/Widget/Widget";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@mui/lab/Autocomplete";
// styles
import useStyles from "../offlineapplication/styles";
import Button from "@material-ui/core/Button";
import {
  Typography,
  TextField,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useState } from "react";
import MasterData from "../../api/MasterData";
import { Alert } from "@mui/lab";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import HelperService from "../../api/HelperService";
import * as Icons from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";
import DocumentService from "../../api/DocumentService";
import { FormLabel } from "@material-ui/core";
import { getDecryptedData } from "../../api/GlobalService";

const AddressType = { PERMANENT: 0, PRESENT: 1 };

const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
}));

export default function HelperForm(props) {
  var classTheme = useStyles();
  const { currentApplication } = useContext(GlobalContext);
  const { type, closeHelperModal, handleClose } = props;
  const [ownerType, setOwnerType] = useState([]);

  const { setBackDrop, refresh, setRefresh } = props;
  const [isSameAspermanentAddress, setSameAspermanentAddress] = React.useState(
    false
  );
  const [isParentEpicCard, setIsParentEpicCard] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [presentSelectedDistrict, setPresentSelectedDistrict] = useState([]);
  const [presentSelectedState, setPresentSelectedState] = useState([]);
  const [presentDistricts, setPresentDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [signatureFlag, setSignatureFlag] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const [profile, setProfile] = React.useState({
    landlordId: currentApplication.id,
    // landlordId=currentApplication.landlord_id,
    memberName: "",
    age: "",
    permanentAddress: {
      address1: "",
      address2: "",
      pinCode: "",
      district: {
        districtCode: "",
        districtName: "",
      },
      statename: {
        stateCode: "",
        stateName: "",
      },
    },
    presentAddress: {
      pinCode: "", // Assuming presentAddress also has a pinCode
    },
  });
  const errorInitialState = {
    error: false,
    age: {
      exist: false,
      helperText: "Age is required",
    },
    memberName: {
      exist: false,
      helperText: "Member Name required",
    },
    permanentAddress: {
      address1: {
        exist: false,
        helperText: "Address Line 1 required!",
      },
      address2: {
        exist: false,
        helperText: "Address Line 2 required!",
      },
      state: {
        exist: false,
        helperText: "State required",
      },
      district: {
        exist: false,
        helperText: "District required",
      },
      pincode: {
        exist: false,
        helperText: "Pincode required",
      },
    },

  };

  const [errors, setErrors] = useState(errorInitialState);
  const [uploading, setUploading] = useState(false);
  //

  // const documentTypes = ["PHOTO", "IDENTITYPROOF", "SIGNATURE"];

  const [file, setFile] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState("SIGNATURE");
  const [open, setOpen] = React.useState(false);
  const [memberCode, setMemberCode] = React.useState("");

  const getDistricts = async (stateId) => {
    const response = await MasterData.getDistrictsByStateId(stateId);

    setDistricts(response.data);
  };
  const getPresentDistricts = async (stateId) => {
    const response = await MasterData.getDistrictsByStateId(stateId);
    setPresentDistricts(response.data);
  };
  const getStates = async () => {
    const response = await MasterData.getStates();
    setStates(response.data);
  };
  useEffect(() => {
    //getDetails();
    getStates();
  }, []);

  const classes = useStyle();

  const handleClickOpen = () => {
    setSelectedDocumentType(null);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedDocumentType(null);
    handleClose()
    setOpen(false);
  };

  const handleDocumentTypeChange = (value) => {
    setSelectedDocumentType(value);
  };

  const handleNameChange = (event) => {
    setProfile({ ...profile, memberName: event.target.value });
  };

  const handleMiddleNameChange = (event) => {
    setProfile({ ...profile, middlename: event.target.value });
  };

  const handleLastNameChange = (event) => {
    setProfile({ ...profile, lastname: event.target.value });
  };

  const handleOccupationChange = (event) => {
    setProfile({ ...profile, occupation: event.target.value });
  };
  const handleParentOrspouseNameChange = (event) => {
    setProfile({ ...profile, parentOrspouseName: event.target.value });
  };
  const handleNationalityChange = (event) => {
    setProfile({ ...profile, nationality: event.target.value });
  };
  const handleAgeChange = (event) => {
    setProfile({ ...profile, age: event.target.value });
  };

  const handleAddressLine1Change = (event, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.address1 = event.target.value;
        break;
      case AddressType.PRESENT:
        s.presentAddress.address1 = event.target.value;
    }
    setProfile(s);
  };

  const handleAddressLine2Change = (event, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.address2 = event.target.value;
        break;
      case AddressType.PRESENT:
        s.presentAddress.address2 = event.target.value;
    }
    setProfile(s);
  };

  const handleDistrictChange = (value, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.district.districtCode = value.districtCode;
        s.permanentAddress.district.districtName = value.districtName;
        setSelectedDistrict(value);
        break;
      case AddressType.PRESENT:
        s.presentAddress.district.districtCode = value.districtCode;
        s.presentAddress.district.districtName = value.districtName;
    }
    setProfile(s);
  };

  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/; // Example: Validate for a 6-digit pincode
    return pincodeRegex.test(pincode);
  };

  const handlePincodeChange = (event, addressType) => {
    const s = { ...profile };
    const pinCode = event.target.value;

    // Update the pincode based on address type
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.pinCode = pinCode;
        break;
      case AddressType.PRESENT:
        s.presentAddress.pinCode = pinCode;
        break;
    }

    // Validate the pincode
    const isValidPincode = validatePincode(pinCode);
    setErrors(prevErrors => ({
      ...prevErrors,
      permanentAddress: {
        ...prevErrors.permanentAddress,
        pincode: addressType === AddressType.PERMANENT
          ? {
            exist: !isValidPincode,
            helperText: isValidPincode ? "" : "Invalid Pincode. Must be 6 digits.",
          }
          : prevErrors.permanentAddress.pincode, // Keep existing error for present address
      }
    }));

    // Update the profile state
    setProfile(s);
  };

  const handleStateChange = (value, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.statename.stateCode = value.stateCode;
        getDistricts(value.stateCode);
        setSelectedState(value);

        break;
      case AddressType.PRESENT:
        s.presentAddress.statename.stateCode = value.stateCode;
        getPresentDistricts(value.stateCode);
    }
    setProfile(s);
  };
  const handleMobileNumberChange = (event) => {
    setProfile({ ...profile, mobile: event.target.value });
  };

  const copyAddress = () => {
    const s = { ...profile };
    Object.assign(s.presentAddress, s.permanentAddress);

    setPresentSelectedDistrict(selectedDistrict);
    setPresentSelectedState(selectedState);

    setProfile(s);
  };
  const validateData = () => {
    const e = errorInitialState;

    if (profile.memberName === "") {
      e.error = true;
      e.memberName.exist = true;
    }
    if (profile.age === "") {
      e.error = true;
      e.age.exist = true;
    }
    if (profile.permanentAddress.address1 === "") {
      e.error = true;
      e.permanentAddress.address1.exist = true;
    }
    if (profile.permanentAddress.address2 === "") {
      e.error = true;
      e.permanentAddress.address2.exist = true;
    }
    if (profile.permanentAddress.statename.stateCode === "") {
      e.error = true;
      e.permanentAddress.state.exist = true;
    }

    if (profile.permanentAddress.district.districtCode === "") {
      e.error = true;
      e.permanentAddress.district.exist = true;
    }

    if (profile.permanentAddress.pinCode === "" || profile.permanentAddress.pinCode.length !== 6) {
      e.error = true;
      e.permanentAddress.pincode.exist = true;
    }

    return e;
  };

  const save = async () => {
    let serverResponse;
    setOwnerType("landlordhelper");
    if (type === "TENANT") {
      delete profile.landlordId;
      // profile.tenantId = getDecryptedData("tenantId");
      profile.tenantId = sessionStorage.getItem("tenantId");
      setOwnerType("tenanthelper");
    }

    // getDecryptedData("role") === "MODERATOR" ||
    //   getDecryptedData("role") === "SUPERMODERATOR"
    sessionStorage.getItem("role") === "MODERATOR" ||
      sessionStorage.getItem("role") === "SUPERMODERATOR"
      ? await HelperService.create(profile, type)
        .then((response) => {
          serverResponse = response;
          setSnackBar({
            ...snackBar,
            message: "Successfully Saved Helper. Please Upload Signature. ",
            open: true,
            severity: "success",
          });
        })
        .catch((error) => {

          /* if(error!=null || error.response!=undefined){
            
      // Loop through all the error array 
      var errorStr= '';
      if(error.response.data!=undefined){

        for(var i=0;i<error.response.data.length;i++){
          errorStr+= error.response.data[i] + '\n';
        }

      */
          var msg = "Error in Server";
          if (error.response != null) msg = error.response.data.message;

          setSnackBar({
            ...snackBar,
            message: msg,
            open: true,
            severity: "error",
          });

          throw error;
        })
      : await HelperService.createAnonymous(profile, type)
        .then((response) => {
          serverResponse = response;
          setSnackBar({
            ...snackBar,
            message: "Successfully Saved Helper. Please Upload Signature. ",
            open: true,
            severity: "success",
          });
        })
        .catch((error) => {

          /* if(error!=null || error.response!=undefined){
            
      // Loop through all the error array 
      var errorStr= '';
      if(error.response.data!=undefined){

        for(var i=0;i<error.response.data.length;i++){
          errorStr+= error.response.data[i] + '\n';
        }

      */
          var msg = "Error in Server";
          if (error.response != null) msg = error.response.data.message;

          setSnackBar({
            ...snackBar,
            message: msg,
            open: true,
            severity: "error",
          });

          throw error;
        });

    // Save Landlord Here

    return serverResponse; // Successfully Saved
  };

  function getBase64(file, callback) {
    const reader = new FileReader();

    reader.addEventListener("load", () => callback(reader.result));

    reader.readAsDataURL(file);
  }

  const uploadFile = async () => {
    if (file === null || file === undefined) {
      setSnackBar({
        message: "Please Choose a file to upload",
        open: true,
        severity: "error",
      });
      return;
    }

    getBase64(file, async function (base64Data) {
      const fileExtension = getExt(file.name);


      let base64String = /,(.+)/.exec(base64Data)[1];

      const payload = {
        ownerid: memberCode, //sessionStorage.getItem("tenantId")
        ownertype: ownerType,
        doctype: "SIGNATURE",
        base64img: base64String,
        contenttype: fileExtension,
      };

      try {
        setUploading(true);
        // getDecryptedData("role") === "MODERATOR" ||
        //   getDecryptedData("role") === "SUPERMODERATOR"
        sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR"
          ? await DocumentService.create(payload)
          : await DocumentService.createAnonymous(payload);
        setFile(null);
        setSelectedDocumentType(null);
        setSnackBar({
          message: "Uploaded Successfully!",
          open: true,
          severity: "success",
        });
        setOpen(false);
        setRefresh(!refresh);
        closeHelperModal();
        setUploading(false);
      } catch (err) {
        setUploading(false);
        setFile(null);
        setSelectedDocumentType(null);
        setSnackBar({
          message: err.response.data.message,
          open: true,
          severity: "error",
        });
      }
      setUploading(false);
    });
  };

  function getExt(filename) {
    var ext = filename.split(".").pop();
    if (ext === filename) return "";
    return ext;
  }

  return (
    <>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Widget disableWidgetMenu>
        <div className={classTheme.dashedBorder} style={{ marginTop: 40 }}>
          <form className={classes.root} noValidate autoComplete="off">
            <Typography variant="h6" component="h6" style={{ marginTop: 0 }}>
              Basic Details
            </Typography>
            <FormControl>
              <TextField
                id="memberName"
                onChange={handleNameChange}
                value={profile.memberName}
                label={
                  <span>
                    Name <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.memberName.exist === true ? true : false}
                helperText={
                  errors.memberName.exist ? errors.memberName.helperText : ""
                }
              />
            </FormControl>

            <FormControl>
              <TextField
                id="date"
                label={
                  <span>
                    Date of Birth <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                type="date"
                defaultValue="2017-05-24"
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleAgeChange}
                value={profile.age}
                error={errors.age.exist === true ? true : false}
                helperText={errors.age.exist ? errors.age.helperText : ""}
              />
            </FormControl>
          </form>
        </div>

        <div className={classTheme.dashedBorder}>
          <form className={classes.root} noValidate autoComplete="off">
            <Typography variant="h6" component="h6" style={{ marginTop: 0 }}>
              Permanent Address
            </Typography>
            <FormControl>
              <TextField
                id="permanent-address-1"
                onChange={(event) => {
                  handleAddressLine1Change(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.address1}
                label={
                  <span>
                    Address Line-1 <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.permanentAddress.address1.exist}
                helperText={
                  errors.permanentAddress.address1.exist
                    ? errors.permanentAddress.address1.helperText
                    : ""
                }
              />
            </FormControl>
            <FormControl>
              <TextField
                id="permanent-address-2"
                onChange={(event) => {
                  handleAddressLine2Change(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.address2}
                label="Address Line-2"
                error={errors.permanentAddress.address2.exist}
                helperText={
                  errors.permanentAddress.address2.exist
                    ? errors.permanentAddress.address2.helperText
                    : ""
                }
              />
            </FormControl>

            <FormControl>
              <Autocomplete
                onChange={(event, value) => {
                  handleStateChange(value, AddressType.PERMANENT);
                }}
                id="permanent-state"
                options={states}
                getOptionLabel={(option) => option.stateName}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        State <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.permanentAddress.state.exist}
                    helperText={
                      errors.permanentAddress.state.exist
                        ? errors.permanentAddress.state.helperText
                        : ""
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl>
              <Autocomplete
                onChange={(event, value) => {
                  handleDistrictChange(value, AddressType.PERMANENT);
                }}
                id="permanent-district"
                options={districts}
                getOptionLabel={(option) => option.districtName}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        District <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.permanentAddress.state.exist}
                    helperText={
                      errors.permanentAddress.district.exist
                        ? errors.permanentAddress.district.helperText
                        : ""
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <TextField
                id="permanent-pincode"
                onChange={(event) => {
                  handlePincodeChange(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.pincode}
                label={
                  <span>
                    Pincode <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.permanentAddress.pincode.exist}
                helperText={
                  errors.permanentAddress.pincode.exist
                    ? errors.permanentAddress.pincode.helperText
                    : ""
                }
              />
            </FormControl>
          </form>
        </div>

        {signatureFlag && (
          <div className={classTheme.dashedBorder}>
            <Button
              style={{ float: "center" }}
              variant="contained"
              color="secondary"
              startIcon={<Icons.CloudUpload />}
              onClick={handleClickOpen}
            >
              Upload Signature
            </Button>
            <Dialog
              open={open}
              onClose={handleCloseDialog}
              aria-labelledby="form-dialog-title"
              maxWidth="1000px"
              disableBackdropClick
            >
              <DialogTitle id="form-dialog-title">Upload Signature</DialogTitle>
              {snackBar.open ? (
                <Alert
                  onClose={() => {
                    setSnackBar({ ...snackBar, open: false });
                  }}
                  severity={snackBar.severity}
                >
                  {snackBar.message}
                </Alert>
              ) : (
                <></>
              )}
              {uploading === false ? (
                <DialogContent>
                  <FormLabel>SIGNATURE(only JPG, PNG or JPEG)</FormLabel>
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
                    onChange={(files) => {
                      setFile(files[0]);
                    }}
                  />
                </DialogContent>
              ) : (
                <h3>Uploading....Please wait</h3>
              )}
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Close
                </Button>
                <Button
                  onClick={async () => {
                    await uploadFile();
                  }}
                  color="primary"
                >
                  Upload
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}

        <Button
          style={{ float: "right", marginTop: 5 }}
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={async () => {
            const errs = validateData();

            if (errs.error === true) {
              setErrors(errs);

              setSnackBar({
                message: "Some of the data you entered might be incorrect. Please re-check!",
                severity: "error",
                open: true,
              });

              return;
            }

            // setBackDrop(true);
            const status = await save();
            if (status && status.memberCode) {
              setSignatureFlag(!signatureFlag);
              setMemberCode(status.memberCode);
              handleClickOpen();
            }
            //  setBackDrop(false);
            // handleClose();
          }}
        >
          Save
        </Button>
      </Widget>
    </>
  );
}
