import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import http from "../../api/http-common";
import ApplicationService from "../../api/ApplicationService";
import { format, compareAsc } from "date-fns";
import PageHeader from "../../components/Header/PageHeader";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MasterData from "../../api/MasterData";
import RentalUnitService from "../../api/RentalUnitService";
import UserService from "../../api/UserService";
import {
  getDecryptedData,
  stringBeforeBrackets,
  stringWithinBrackets,
} from "../../api/GlobalService";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import { GlobalContext } from "../../context/GlobalState";

const options = {
  filter: true,
  selectableRows: "multiple",
  selectableRowsOnClick: false,
  selectableRowsHideCheckboxes: true,
  filterType: "dropdown",
  responsive: "vertical",
  enableNestedDataAccess: ".", // allows nested data separated by "." (see column names and the data structure above)
  fixedHeader: true,
  // fixedSelectColumn: true,
  tableBodyHeight: "500px",
};

const Report = () => {
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [month1, setMonth1] = useState("");
  const [day1, setDay1] = useState("");
  const [year1, setYear1] = useState("");

  const [month2, setMonth2] = useState("");
  const [day2, setDay2] = useState("");
  const [year2, setYear2] = useState("");
  const [localities, setLocalities] = useState([]);
  const [localAuthorities, setLocalAuthorities] = useState([]);
  // const [psCode, setPsCode] = useState("");
  const [selectedPoliceStationCode, setSelectedPoliceStationCode] = useState(
    ""
  );
  const [selectedLocalityCode, setSelectedLocalityCode] = useState("");
  const [selectedLocalAuthorityCode, setSelectedLocalAuthorityCode] = useState(
    ""
  );
  const [policeStations, setPoliceStations] = useState([]);
  const [extraFilter, setExtraFilter] = useState(false);
  const { openSnackBar } = useContext(GlobalContext);
  const [selectedStatusCode, setSelectedStatusCode] = useState("");
  const [payload, setPayload] = useState({
    policeStation: "",
    locality: "",
    localAuthority: "",
    statuscode: "",
  });
  const [noApplicationsFound, setNoApplicationsFound] = useState(false);

  // console.log(format(new Date(2014, 0, 31), "yyyy-MM-dd"));
  let regExp = /\(([^)]+)\)/;

  const statuses = [
    {
      statusName: "Pending at Sub-localities",
      statusCode: "LARP",
    },
    {
      statusName: "Pending at Localities",
      statusCode: "LAVP",
    },
    {
      statusName: "Approved by Localities",
      statusCode: "PLVP",
    },
    {
      statusName: "Rejected by Localities",
      statusCode: "RJT",
    },
  ];

  const columns = [
    {
      name: "id",
      label: "Sl no.",
      options: {
        filter: true,
      },
    },
    {
      name: "applId",
      label: "Appl Id",
      options: {
        filter: true,
      },
    },
    {
      name: "landlordName",
      label: "Landlord Name",
      options: {
        filter: true,
      },
    },
    {
      name: "tenantName",
      label: "Tenant Name",
      options: {
        filter: true,
      },
    },
    {
      name: "occupation",
      label: "Occupation",
      options: {
        filter: true,
      },
    },
    {
      name: "noOfFamilyMembers",
      label: "Number of Family Members",
      options: {
        filter: true,
      },
    },
    {
      name: "address",
      label: "Permanent Address",
      options: {
        filter: true,
      },
    },

    {
      name: "rentalAddress",
      label: "Rental Address",
      options: {
        filter: true,
      },
    },

    // {
    //   name: "action",
    //   label: "",
    //   options: {
    //     filter: true,

    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <Box>
    //           <Button onClick={() => alert("Edit1")}>Edit1</Button>
    //           <Button onClick={() => alert("Edit2")}>Edit2</Button>
    //           <Button onClick={() => alert("Edit3")}>Edit3</Button>
    //         </Box>
    //       );
    //     },
    //   },
    // },

    // { field: 'action', headerName: 'Actions', width: 200, filterable: false,sortable: false,}
  ];

  function getMonthNumber(month) {
    switch (month) {
      case "Jan": {
        return "01";
      }
      case "Feb": {
        return "02";
      }
      case "Mar": {
        return "03";
      }
      case "Apr": {
        return "04";
      }
      case "May": {
        return "05";
      }
      case "Jun": {
        return "06";
      }
      case "Jul": {
        return "07";
      }
      case "Aug": {
        return "08";
      }
      case "Sep": {
        return "09";
      }
      case "Oct": {
        return "10";
      }
      case "Nov": {
        return "11";
      }
      case "Dec": {
        return "12";
      }
      default: {
        return;
      }
    }
  }

  const today = new Date();
  today.setDate(today.getDate());

  const getPoliceStations = async () => {
    const response = await MasterData.getPoliceStationsByDistrictCode(
      // getDecryptedData("usercode")
      sessionStorage.getItem("usercode")
    );
    setPoliceStations(response.data);
  };

  const handlePSChange = async (value) => {
    if (value !== null) {
      setRows([]);
      setNoApplicationsFound(false);

      console.log("PScode ::: ", value);
      setSelectedPoliceStationCode(value.policeStationCode);
      setPayload({ ...payload, policeStation: value.policeStationCode });

      const response = await RentalUnitService.getLocalities(
        value.policeStationCode
      );
      setLocalities(response.data);
    } else {
      setRows([]);
      setNoApplicationsFound(false);

      setLocalities([]);
    }
  };

  const handleStatusChange = async (value) => {
    if (value !== null) {
      setRows([]);
      setNoApplicationsFound(false);
      openSnackBar({ open: false });
      console.log(value);

      setPayload({ ...payload, statuscode: value.statusCode });
      setSelectedStatusCode(value.statusCode);
    } else {
      setRows([]);
      setNoApplicationsFound(false);

      setPayload({ ...payload, statuscode: "" });
    }
  };

  const handleLocalityChange = (value) => {
    if (value != null) {
      setRows([]);
      setNoApplicationsFound(false);

      setSelectedLocalityCode(value.localityCode);
      setPayload({
        ...payload,
        locality: value.localityCode,
      });

      getLocalAuth(value.localityCode);
    } else {
      setRows([]);
      setNoApplicationsFound(false);

      return;
    }
  };

  const handleLocalAuthorityChange = (value) => {
    if (value != null) {
      setRows([]);
      setNoApplicationsFound(false);

      setSelectedLocalAuthorityCode(value.localAuthorityCode);
      setPayload({ ...payload, localAuthority: value.localAuthorityCode });
    } else {
      setRows([]);
      setNoApplicationsFound(false);

      return;
    }
  };

  const getLocalAuth = async (localityCode) => {
    const response = await RentalUnitService.getLocalAutoritiesByLocalityCode(
      localityCode
    );
    var data = [];
    data = response.data;
    for (var i = 0; i < data.length; i++) {
      data[i].id = i + 1;
      data[i].localityName = data[i].localityName;
    }
    setLocalAuthorities(data);
  };

  useEffect(() => {
    // getDecryptedData("role") === "SUPERVISOR" && getPoliceStations();
    sessionStorage.getItem("role") === "SUPERVISOR" && getPoliceStations();
  }, []);

  return (
    <>
      <PageHeader
        title="Application Report"
        subTitle="Generate the completed applications by providing date bounds."
        icon={<UploadFileIcon fontSize="large" />}
      />
      <Paper sx={{ padding: "2%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",

              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
          >
            <DatePicker
              openTo="day"
              label="Start Date"
              value={startDate}
              // minDate={today}
              okText
              inputFormat="dd-MM-yyyy"
              onChange={(newValue) => {
                setRows([]);
                setStartDate(newValue);
                let text = newValue.toString().substring(4, 15);
                console.log(text);
                setMonth1(getMonthNumber(text.substring(0, 3)));
                setDay1(text.substring(4, 6));
                setYear1(text.substring(7, 11));
              }}
              renderInput={(params) => (
                <TextField
                  style={{ width: 300 }}
                  {...params}
                // helperText={params?.inputProps?.placeholder}
                />
              )}
            />
            <DatePicker
              openTo="day"
              label="End Date"
              value={endDate}
              okText
              minDate={startDate}
              maxDate={today}
              inputFormat="dd-MM-yyyy"
              onChange={(newValue) => {
                setRows([]);
                setEndDate(newValue);
                let text = newValue.toString().substring(4, 15);
                console.log(text);
                setMonth2(getMonthNumber(text.substring(0, 3)));
                setDay2(text.substring(4, 6));
                setYear2(text.substring(7, 11));
              }}
              renderInput={(params) => (
                <TextField
                  style={{ width: 300 }}
                  {...params}
                // helperText={params?.inputProps?.placeholder}
                />
              )}
            />

            {/* {getDecryptedData("role") === "SUPERVISOR" && ( */}
            {sessionStorage.getItem("role") === "SUPERVISOR" && (
              <FormControl>
                <Autocomplete
                  // size={"small"}
                  onChange={(event, value) => {
                    handlePSChange(value);
                  }}
                  id="standard-basic"
                  options={policeStations}
                  getOptionLabel={(option) => option.policeStationName}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Police Station *"
                      variant="outlined"
                    // error={errors.policeStation.exist === true ? true : false}
                    // helperText={
                    //   errors.policeStation.exist
                    //     ? errors.policeStation.helperText
                    //     : ""
                    // }
                    />
                  )}
                />
              </FormControl>
            )}
          </Box>
          <Box minHeight={10} />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",

              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
          >
            {/* {getDecryptedData("role") === "SUPERVISOR" && ( */}
            {sessionStorage.getItem("role") === "SUPERVISOR" && (
              <>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Filter via Locality & Sub-Locality"
                  onChange={() => {
                    setRows([]);
                    setExtraFilter(!extraFilter);
                  }}
                />
                {extraFilter && (
                  <>
                    <FormControl>
                      <Autocomplete
                        size={"small"}
                        onChange={(event, value) => {
                          handleLocalityChange(value);
                        }}
                        id="standard-basic"
                        options={localities}
                        getOptionLabel={(option) =>
                          option !== null
                            ? option.localityName +
                            " /Headman:" +
                            option.headManName
                            : ""
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Locality *"
                            variant="outlined"
                          // error={errors.localAuthority.exist === true ? true : false}
                          // helperText={
                          //   errors.localAuthority.exist
                          //     ? errors.localAuthority.helperText
                          //     : ""
                          // }
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl>
                      <Autocomplete
                        size={"small"}
                        onChange={(event, value) => {
                          handleLocalAuthorityChange(value);
                        }}
                        id="standard-basic"
                        options={localAuthorities}
                        getOptionLabel={(option) =>
                          option !== null
                            ? option.localAuthorityName
                            : // + " /Headman:" + option.headManName
                            ""
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub-Locality *"
                            variant="outlined"
                          // error={errors.localAuthority.exist === true ? true : false}
                          // helperText={
                          //   errors.localAuthority.exist
                          //     ? errors.localAuthority.helperText
                          //     : ""
                          // }
                          />
                        )}
                      />
                    </FormControl>
                  </>
                )}
              </>
            )}
            <FormControl>
              <Autocomplete
                size={"small"}
                onChange={(event, value) => {
                  handleStatusChange(value);
                }}
                id="standard-basic"
                options={statuses}
                getOptionLabel={(option) => option.statusName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Application Status *"
                    variant="outlined"
                  // error={errors.policeStation.exist === true ? true : false}
                  // helperText={
                  //   errors.policeStation.exist
                  //     ? errors.policeStation.helperText
                  //     : ""
                  // }
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box sx={{ position: "relative", padding: "2%" }}>
            <FormControl
              sx={{
                position: {
                  lg: "absolute",
                  md: "absolute",
                  left: "45%",
                  xs: "static",
                  sm: "static",
                },
              }}
            >
              <Button
                size={"small"}
                variant="contained"
                onClick={() => {
                  payload.statuscode === ""
                    ? openSnackBar({
                      open: true,
                      message: `Please select an Application Status`,
                      severity: "error",
                    })
                    : ApplicationService.getReportApplications(
                      // getDecryptedData("username"),
                      sessionStorage.getItem("username"),
                      format(new Date(year1, month1 - 1, day1), "yyyy-MM-dd"),
                      format(new Date(year2, month2 - 1, day2), "yyyy-MM-dd"),
                      0,
                      selectedPoliceStationCode,
                      payload
                    )
                      .then((response) => {
                        openSnackBar({
                          open: true,
                          message: `Found ${response.data.applDto.length} application(s) !`,
                          severity: "success",
                        });
                        setNoApplicationsFound(false);
                        let data = [];
                        data = response.data.applDto;
                        for (var i = 0; i < data.length; i++) {
                          data[i].id = i + 1;
                          data[i].applId = data[i].applid;
                          data[i].landlordName = data[i].landlordname;
                          data[i].tenantName = stringBeforeBrackets(
                            data[i].tenantname
                          );
                          data[i].rentalAddress = data[i].rentaladdress;
                          data[i]["address"] = stringWithinBrackets(
                            data[i].tenantname
                          )[0].substring(
                            stringWithinBrackets(
                              data[i].tenantname
                            )[0].indexOf(":") + 1
                          );
                          data[i]["occupation"] = stringWithinBrackets(
                            data[i].tenantname
                          )[1].substring(
                            stringWithinBrackets(
                              data[i].tenantname
                            )[1].indexOf(":") + 1
                          );
                          data[i]["noOfFamilyMembers"] = stringWithinBrackets(
                            data[i].tenantname
                          )[2].substring(
                            stringWithinBrackets(
                              data[i].tenantname
                            )[2].indexOf(":") + 1
                          );
                        }
                        setRows(data);
                      })
                      .catch((err) => {
                        openSnackBar({
                          open: true,
                          message: "No applications found!",
                          severity: "error",
                        });
                        setNoApplicationsFound(true);
                      });
                }}
              >
                SUBMIT
              </Button>
            </FormControl>
          </Box>
        </LocalizationProvider>
      </Paper>

      <Box minHeight="2%" />

      {noApplicationsFound && (
        <Card>
          <Typography align="center" sx={{ color: "red" }} variant="h5">
            No Applications Found
          </Typography>
        </Card>
      )}

      {rows.length !== 0 && (
        <CustomDataTable
          rows={rows}
          columns={columns}
          title="Application List"
        />
      )}
    </>
  );
};

export default Report;
