import React, { useState } from "react";
import { Card, Grid } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import HelpersDataTable from "./HelpersDataTable";
import NewHelper from "./NewHelper";
import PageHeader from "../../components/Header/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";

// 1150px
export default function Helpers(props) {
  var classes = useStyles();
  const { profile } = props;
  const [refresh, setRefresh] = useState(false);
  return (
    <Card>
      {/* <PageTitle title="Helpers" /> */}
      <PageHeader
        title="Domestic Helpers"
        subTitle="Add domestic helpers of tenants."
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <NewHelper
        profile={profile}
        type={props.type}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <HelpersDataTable type={props.type} refresh={refresh} setRefresh={setRefresh} />
        </Grid>
      </Grid>
    </Card>
  );
}
