import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
// styles
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import {
  Typography,
  FormControlLabel,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import MasterData from "../../api/MasterData";
import LandlordService from "../../api/LandlordService";
import { GlobalContext } from "../../context/GlobalState";
import { Alert, Autocomplete, Box, FormControl, Paper, Stack, TextField } from "@mui/material";
import httpApi from "../../api/http-common-apikey";

import VerifyOTPDialog from "../../components/VerifyOTPDialog";
import Info from "../../components/Info";
import InfoText from "../../components/InfoText";
import { getDecryptedData } from "../../api/GlobalService";

const AddressType = { PERMANENT: 0, PRESENT: 1 };
const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const fontColor = {
  style: { color: "#3598bd", fontWeight: 500 },
};

export default function LandlordForm(props) {
  var classTheme = useStyles();
  const {
    currentApplication,
    updateApplication,
    openSnackBar,
    snackbar,
  } = useContext(GlobalContext);
  const { setDisableOtherTabs } = props;
  const [isSameAspermanentAddress, setSameAspermanentAddress] = React.useState(
    false
  );
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [profile, setProfile] = React.useState(currentApplication);
  const [presentSelectedDistrict, setPresentSelectedDistrict] = useState();
  const [presentSelectedState, setPresentSelectedState] = useState();

  // const [presentSelectedState, setPresentSelectedState] = useState(profile.exist ?profile.presentAddress.district.statename.stateCode: null);
  const [permanentSelectedDistrict, setPermanentSelectedDistrict] = useState();
  const [permanentSelectedState, setPermanentSelectedState] = useState();
  const [presentDistricts, setPresentDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState(
    profile.exist === true ? profile.occupation : null
  );
  const [openVerifyOTP, setOpenVerifyOTP] = useState(false);
  const [backDropOpen, setBackDropOpen] = useState(false);

  const errorInitialState = {
    error: false,
    firstName: {
      exist: false,
      helperText: "FirstName required",
    },
    lastName: {
      exist: false,
      helperText: "LastName required",
    },
    // email: {
    //   exist: false,
    //   helperText: "email required",
    // },
    occupation: {
      exist: false,
      helperText: "Occupation required",
    },
    mobile: {
      exist: false,
      helperText: "Mobile Number required",
    },
    permanentAddress: {
      address1: {
        exist: false,
        helperText: "Address Line 1 required!",
      },
      address2: {
        exist: false,
        helperText: "Address Line 2 required!",
      },
      state: {
        exist: false,
        helperText: "State required",
      },
      district: {
        exist: false,
        helperText: "District required",
      },
      pinCode: {
        exist: false,
        helperText: "pinCode required",
      },
    },
    presentAddress: {
      address1: {
        exist: false,
        helperText: "Address Line 1 required!",
      },
      address2: {
        exist: false,
        helperText: "Address Line 2 required!",
      },
      state: {
        exist: false,
        helperText: "State required",
      },
      district: {
        exist: false,
        helperText: "District required",
      },
      pinCode: {
        exist: false,
        helperText: "pinCode required",
      },
    },
  };

  const [errors, setErrors] = useState(errorInitialState);
  //----Call Api to get Distrgict-----------------
  const getDistricts = async (stateId) => {
    // get district for permanent Address
    const response = await MasterData.getDistrictsByStateId(stateId);
    setDistricts(response.data);
  };
  const getPresentDistricts = async (stateId) => {
    const response = await MasterData.getDistrictsByStateId(stateId); // get district for persent
    setPresentDistricts(response.data);
  };
  const getStates = async () => {
    const response = await MasterData.getStates();
    setStates(response.data);
    console.log(response);
  };
  const getoccupations = async () => {
    const response = await MasterData.getOccupation(); // get Occupation
    setOccupation(response.data);
  };

  const getDetails = () => {
    // This re-render.
    if (profile.exist === true) {
      setPermanentSelectedState(
        profile.permanentAddress.district.statename.stateCode
      );
      setPermanentSelectedDistrict(profile.permanentAddress.district);
      setPresentSelectedState(
        profile.presentAddress.district.statename.stateCode
      );
      setPresentSelectedDistrict(profile.presentAddress.district);
      setSelectedOccupation(profile.occupation);
    }
  };
  useEffect(() => {
    profile.exist && getDetails();
    getStates();
    getoccupations();
  }, [false]);

  const classes = useStyle();
  const handleFirstNameChange = (event) => {
    setProfile({ ...profile, firstName: event.target.value });
    event.target.value.length > 0
      ? setErrors({ ...errors, firstName: { exist: false } })
      : setErrors({
        ...errors,
        firstName: { exist: true, helperText: "First Name required" },
      });
  };
  const handleMiddleNameChange = (event) => {
    setProfile({ ...profile, middleName: event.target.value });
  };
  const handleLastNameChange = (event) => {
    setProfile({ ...profile, lastName: event.target.value });
    event.target.value.length > 0
      ? setErrors({ ...errors, lastName: { exist: false } })
      : setErrors({
        ...errors,
        lastName: { exist: true, helperText: "Last Name required" },
      });
  };

  const handleEmailChange = (event) => {
    setProfile({ ...profile, email: event.target.value });
  };
  // const handleOccupationChange = (event) => {
  //   setProfile({...profile,occupation:event.target.value});
  // };
  const handleAddressLine1Change = (event, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.address1 = event.target.value;
        setProfile({
          ...profile,
          permanentAddress: { address1: event.target.value },
        });
        break;
      case AddressType.PRESENT:
        s.presentAddress.address1 = event.target.value;
    }
    setProfile(s);
  };
  const handleAddressLine2Change = (event, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.address2 = event.target.value;
        setProfile({
          ...profile,
          permanentAddress: { address2: event.target.value },
        });
        break;
      case AddressType.PRESENT:
        s.presentAddress.address2 = event.target.value;
    }
    setProfile(s);
  };
  const handleoccupationChange = (value) => {
    //value has value and occupationCode
    console.log("value", value)
    const s = { ...profile };
    if (value !== null) {
      s.occupation = value.value;
      setSelectedOccupation(value);
      setProfile(s);
    } else {
      s.occupation = "";
      setProfile(s);
      setSelectedOccupation(null);
    }
  };

  const handlepinCodeChange = (event, addressType) => {
    const s = { ...profile };
    switch (addressType) {
      case AddressType.PERMANENT:
        s.permanentAddress.pinCode = event.target.value;
        break;
      case AddressType.PRESENT:
        s.presentAddress.pinCode = event.target.value;
        break;
    }
    setProfile(s);
  };

  const handleStateChange = (value, addressType) => {
    const s = { ...profile };
    if (value !== null) {
      switch (addressType) {
        case AddressType.PERMANENT:
          s.permanentAddress.district.statename.stateCode = value.stateCode;
          getDistricts(value.stateCode);
          setPermanentSelectedState(value);
          // setProfile(s);
          break;
        case AddressType.PRESENT:
          s.presentAddress.district.statename.stateCode = value.stateCode;
          getPresentDistricts(value.stateCode);
          setPresentSelectedState(value);
        // setProfile(s);
      }
      setProfile(s);
    } else {
      switch (addressType) {
        case AddressType.PERMANENT:
          s.permanentAddress.district.statename.stateCode = "";
          setPermanentSelectedState([]);
          setDistricts([]);
          break;
        case AddressType.PRESENT:
          s.presentAddress.district.statename.stateCode = "";
          setPresentSelectedState([]);
          setDistricts([]);
      }
    }
  };

  const handleDistrictChange = (value, addressType) => {
    const s = { ...profile };
    if (value !== null) {
      switch (addressType) {
        case AddressType.PERMANENT:
          s.permanentAddress.district.districtCode = value.districtCode;
          s.permanentAddress.district.districtName = value.districtName;

          setPermanentSelectedDistrict(value);
          // setProfile(s);

          break;
        case AddressType.PRESENT:
          s.presentAddress.district.districtCode = value.districtCode;
          s.presentAddress.district.districtName = value.districtName;

          setPresentSelectedDistrict(value);
        // setProfile(s);
      }
      setProfile(s);
    } else {
      switch (addressType) {
        case AddressType.PERMANENT:
          s.permanentAddress.district.districtCode = "";
          setPermanentSelectedDistrict(null);
          break;
        case AddressType.PRESENT:
          s.presentAddress.district.districtCode = "";
          setPresentSelectedDistrict([]);
      }
      setProfile(s);
    }
  };
  const handleMobileNumberChange = (event) => {
    event.target.value.length < 11 &&
      setProfile({ ...profile, mobile: event.target.value });
    event.target.value.length > 0 && /^\d+$/.test(event.target.value)
      ? setErrors({ ...errors, mobile: { exist: false } })
      : event.target.value.length === 0
        ? setErrors({
          ...errors,
          mobile: { exist: true, helperText: "Mobile number required" },
        })
        : event.target.value.length < 11 &&
        setErrors({
          ...errors,
          mobile: { exist: true, helperText: "Only numbers are allowed" },
        });
  };
  const copyAddress = () => {
    const s = { ...profile };
    Object.assign(s.presentAddress, s.permanentAddress);
    setPresentSelectedDistrict(permanentSelectedDistrict);
    setPresentSelectedState(permanentSelectedState);
    setProfile(s);
  };
  const validateLandLordData = () => {
    const e = errorInitialState;
    if (profile.firstName === "") {
      e.error = true;
      e.firstName.exist = true;
    }
    if (profile.lastName === "") {
      e.error = true;
      e.lastName.exist = true;
    }
    // if (profile.email === "") {
    //   e.error = true;
    //   e.email.exist = true;
    // }
    if (profile.occupation === "") {
      e.error = true;
      e.occupation.exist = true;
    }
    if (profile.mobile === "") {
      e.error = true;
      e.mobile.exist = true;
    }
    if (profile.permanentAddress.address1 === "") {
      e.error = true;
      e.permanentAddress.address1.exist = true;
    }
    if (profile.permanentAddress.address2 === "") {
      e.error = true;
      e.permanentAddress.address2.exist = true;
    }
    if (profile.permanentAddress.district.statename.stateCode === "") {
      e.error = true;
      e.permanentAddress.state.exist = true;
    }
    if (profile.permanentAddress.district.districtCode === "") {
      e.error = true;
      e.permanentAddress.district.exist = true;
    }
    if (profile.permanentAddress.pinCode === "") {
      e.error = true;
      e.permanentAddress.pinCode.exist = true;
    }
    if (profile.presentAddress.address1 === "") {
      e.error = true;
      e.presentAddress.address1.exist = true;
    }
    if (profile.presentAddress.address2 === "") {
      e.error = true;
      e.presentAddress.address2.exist = true;
    }
    if (profile.presentAddress.district.statename.stateCode === "") {
      e.error = true;
      e.presentAddress.state.exist = true;
    }
    if (profile.presentAddress.district.districtCode === "") {
      e.error = true;
      e.presentAddress.district.exist = true;
    }
    if (profile.presentAddress.pinCode === "") {
      e.error = true;
      e.presentAddress.pinCode.exist = true;
    }
    return e;
  };

  const saveLandlord = async () => {
    setBackDropOpen(true);
    if (profile.middleName === "") delete profile.middleName;
    if (profile.email === "") delete profile.email;

    delete profile.exist;
    await LandlordService.create(profile)
      .then((response) => {
        setBackDropOpen(false);
        if (response != null || response != undefined) {
          if (response.status == 200) {
            //Success
            console.log(response);
            response.data.exist = true;
            response.data.id = response.data.landlord_id;
            sessionStorage.setItem("landlordId", response.data.id);
            updateApplication(response.data);

            // getDecryptedData("role") === null &&
            sessionStorage.getItem("role") === null &&
              httpApi.get(`apikey/getOTP/${profile.id}`).then(res => openSnackBar({
                message:
                  `Successfully Saved Landlord. Please login to proceed with further data entries.`,
                open: true,
                severity: "success",
              }));
            props.setActiveStep((prevActiveStep) => prevActiveStep - 1)
            // getDecryptedData("role") === null && setOpenVerifyOTP(true);
            // getDecryptedData("role") !== null &&
            // sessionStorage.getItem("role") === null && setOpenVerifyOTP(true);
            sessionStorage.getItem("role") !== null &&
              setDisableOtherTabs(false);
            //  setDetails({...response.data, exist:true});
          }
          // else {
          //   var errorStr = "";
          //   if (response.data != undefined) {
          //     for (var i = 0; i < response.data.length; i++) {
          //       errorStr += response.data[i] + "\n";
          //     }
          //   }
          //   openSnackBar({ message: errorStr, open: true, severity: "error" });
          // }
        }
      })
      .catch((error) => {
        openSnackBar({
          message: error.response.data.message
            ? error.response.data.message
            : error.response.data, // error message as string
          open: true,
          severity: "error",
        });
        //  updateApplication(profile); // Save the Current data
      });
    // Save Landlord Here
    return true; // Successfully Saved
  };
  const updateLandlord = async () => {
    let data = profile;
    if (data.middleName === "") delete data.middleName;
    if (data.email === "") delete data.email;

    delete data.exist; // should be deleted to be able to update landlord properly
    // getDecryptedData("role") !== null
    sessionStorage.getItem("role") !== null
      ? await LandlordService.update(data)
        .then((response) => {
          if (response != null || response != undefined) {
            if (response.status == 200) {
              //Success
              response.data.exist = true;
              response.data.id = response.data.landlord_id;
              updateApplication(response.data);
              openSnackBar({
                message: "Successfully updated landlord!",
                open: true,
                severity: "success",
              });
              //  setDetails({...response.data, exist:true});
            } else {
              var errorStr = "";
              if (response.data != undefined) {
                for (var i = 0; i < response.data.length; i++) {
                  errorStr += response.data[i] + "\n";
                }
              }
              openSnackBar({
                message: errorStr,
                open: true,
                severity: "error",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          openSnackBar({
            message: "Error in Server",
            open: true,
            severity: "error",
          });
        })
      : await LandlordService.updateAnonymous(data)
        .then((response) => {
          if (response != null || response != undefined) {
            if (response.status == 200) {
              //Success
              response.data.exist = true;
              response.data.id = response.data.landlord_id;
              updateApplication(response.data);
              openSnackBar({
                message: "Successfully updated landlord!",
                open: true,
                severity: "success",
              });
              //  setDetails({...response.data, exist:true});
            } else {
              var errorStr = "";
              if (response.data != undefined) {
                for (var i = 0; i < response.data.length; i++) {
                  errorStr += response.data[i] + "\n";
                }
              }
              openSnackBar({
                message: errorStr,
                open: true,
                severity: "error",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);

          /* if(error!=null || error.response!=undefined){
      
// Loop through all the error array 
var errorStr= '';
if(error.response.data!=undefined){

  for(var i=0;i<error.response.data.length;i++){
    errorStr+= error.response.data[i] + '\n';
  }

*/
          console.log(error);
          openSnackBar({
            message: "Error in Server",
            open: true,
            severity: "error",
          });
        });
    // Save Landlord Here

    return true; // Successfully Saved
  };

  console.log(errors);

  if (currentApplication === undefined) return <div>Loading..</div>;

  return (
    <>
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {
        //profile.exist===false?<Chip color="secondary" label="Record not exist, add new Landlord" />:<Chip color="primary" label="Record exist" />
      }
      {/* {getDecryptedData("role") === null && ( */}
      {sessionStorage.getItem("role") === null && (
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          // anchorOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{ height: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={() => {
              openSnackBar({ open: false });
            }}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}

      <Box
        sx={{
          backgroundColor: "#E8E9ED",
          display: "grid",
          gridTemplateColumns: { md: "2fr 1fr 1fr" },
          gap: 2,
        }}
      >
        <Paper
          sx={{
            padding: { xs: "5%", sm: "7%", lg: "1.5%" },
          }}
        >
          <Stack direction="column" spacing={2}>
            <Typography
              align="center"
              variant="h6"
              component="h6"
              style={{ marginTop: 0 }}
              className={classTheme.heading}
            >
              Basic Details
            </Typography>
            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="epic_number"
                value={profile.id}
                readOnly={true}
                label="Epic Number"
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // Align items vertically center
                flexWrap: "wrap",
                gap: "1rem", // Adjust the gap between FormControl components
                width: "100%", // Ensure the Box takes full width of the screen
              }}
            >
              <FormControl sx={{ width: { xs: "100%", sm: "29%" }, flexGrow: 1 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  id="firstname"
                  onChange={handleFirstNameChange}
                  value={profile.firstName}
                  label={
                    <span>
                      First Name <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  error={errors.firstName.exist}
                  helperText={errors.firstName.exist ? errors.firstName.helperText : ""}
                  inputProps={profile.exist && fontColor}
                />
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", sm: "29%" }, flexGrow: 1 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  id="middlename"
                  onChange={handleMiddleNameChange}
                  value={profile.middleName}
                  label="Middle Name"
                  inputProps={profile.exist && fontColor}
                />
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", sm: "29%" }, flexGrow: 1 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  id="lastname"
                  onChange={handleLastNameChange}
                  value={profile.lastName}
                  label={
                    <span>
                      Last Name <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  error={errors.lastName.exist}
                  helperText={errors.lastName.exist ? errors.lastName.helperText : ""}
                  inputProps={profile.exist && fontColor}
                />
              </FormControl>
            </Box>



            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="email"
                onChange={handleEmailChange}
                value={profile.email}
                label="Email"
                // error={errors.email.exist}
                // helperText={errors.email.exist ? errors.email.helperText : ""}
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            {/* <FormControl> */}


            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center", // Align items vertically center
                flexWrap: "wrap",
                gap: "1rem", // Adjust the gap between FormControl components
              }}
            >
              <Autocomplete
                size="small"
                sx={{
                  width: { xs: "100%", sm: "50%", md: 300 },
                  display: { sm: "block", md: "inline-block" },
                }}
                onChange={(event, value) => {
                  handleoccupationChange(value);
                }}
                id="occupation"
                options={occupation}
                value={
                  profile.exist === true
                    ? { value: profile.occupation } //must be an object. This object is then fetched by getOptionLabel
                    : selectedOccupation
                }
                getOptionLabel={(option) => option.value}
                getOptionSelected={() => selectedOccupation}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Occupation <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.occupation.exist}
                    helperText={
                      errors.occupation.exist ? errors.occupation.helperText : ""
                    }
                  />
                )}
              />

              <FormControl sx={{ width: { xs: "100%", sm: "29%" }, flexGrow: 1 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  id="mobilenumber"
                  onChange={handleMobileNumberChange}
                  value={profile.mobile}
                  label={
                    <span>
                      Mobile Number <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  error={errors.mobile.exist}
                  // helperText={
                  //   errors.mobile.exist ? (
                  //     errors.mobile.helperText
                  //   ) : (
                  //     <InfoText text="Enter numbers from 0 - 9" />
                  //   )
                  // }
                  inputProps={profile.exist && fontColor}

                />
              </FormControl>
            </Box>
          </Stack>
        </Paper>

        <Paper
          sx={{ padding: { xs: "5%", sm: "7%", lg: "3%" } }}
        >
          <Stack direction="column" spacing={2}>
            {/* <div className={classTheme.dashedBorder}> */}
            <Typography
              align="center"
              variant="h6"
              component="h6"
              style={{ marginTop: 0 }}
              className={classTheme.heading}
            >
              Permanent Address
            </Typography>
            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="permanent-address-1"
                onChange={(event) => {
                  handleAddressLine1Change(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.address1}
                label={
                  <span>
                    Address Line-1 <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.permanentAddress.address1.exist}
                helperText={
                  errors.permanentAddress.address1.exist
                    ? errors.permanentAddress.address1.helperText
                    : ""
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="permanent-address-2"
                onChange={(event) => {
                  handleAddressLine2Change(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.address2}
                label="Address Line-2"
                inputProps={profile.exist && fontColor}
                error={errors.permanentAddress.address2.exist}
                helperText={
                  errors.permanentAddress.address2.exist
                    ? errors.permanentAddress.address2.helperText
                    : ""
                }
              />
            </FormControl>

            {/* <FormControl style={{ marginTop: "1.5%" }}> */}
            <Autocomplete
              size="small"
              sx={{
                width: { sm: "auto", md: 300 },
                display: { sm: "block", md: "inline-block" },
              }}
              onChange={(event, value) => {
                handleStateChange(value, AddressType.PERMANENT);
              }}
              id="permanent-state"
              options={states}
              value={
                profile.exist === true
                  ? {
                    stateName:
                      profile.permanentAddress.district.statename.stateName,
                  }
                  : permanentSelectedState
              }
              getOptionLabel={(option) => option.stateName}
              getOptionSelected={() => permanentSelectedState}  // not that required, can be deleted later
              // style={{ width: 250 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      State <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  variant="outlined"
                  error={errors.permanentAddress.state.exist}
                  helperText={
                    errors.permanentAddress.state.exist
                      ? errors.permanentAddress.state.helperText
                      : ""
                  }
                />
              )}
            />
            {/* </FormControl> */}
            {/* <FormControl style={{ marginTop: "1.5%" }}> */}
            <Autocomplete
              sx={{
                width: { sm: "auto", md: 300 },
                display: { sm: "block", md: "inline-block" },
              }}
              size={"small"}
              onChange={(event, value) => {
                handleDistrictChange(value, AddressType.PERMANENT);
              }}
              id="permanent-district"
              options={districts}
              value={
                profile !== null
                  ? profile.permanentAddress.district
                  : permanentSelectedDistrict
              }
              getOptionLabel={(option) => option.districtName}
              getOptionSelected={permanentSelectedDistrict}
              // style={{ width: 250 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      District <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  variant="outlined"
                  error={errors.permanentAddress.state.exist}
                  helperText={
                    errors.permanentAddress.district.exist
                      ? errors.permanentAddress.district.helperText
                      : ""
                  }
                />
              )}
            />
            {/* </FormControl> */}
            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="permanent-pincode"
                onChange={(event) => {
                  handlepinCodeChange(event, AddressType.PERMANENT);
                }}
                value={profile.permanentAddress.pinCode}
                label={
                  <span>
                    Pincode <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.permanentAddress.pinCode.exist}
                helperText={
                  errors.permanentAddress.pinCode.exist
                    ? errors.permanentAddress.pinCode.helperText
                    : ""
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            {/* </div> */}
          </Stack>
        </Paper>

        <Paper
          variant="elevation"
          sx={{
            padding: { xs: "5%", sm: "7%", lg: "3%" }
          }}
          elevation="2"
        >
          {/* <div className={classTheme.dashedBorder}> */}
          <Stack direction="column" spacing={2}>
            <Typography
              align="center"
              variant="h6"
              component="h6"
              style={{ marginTop: 0 }}
              className={classTheme.heading}
            >
              Present Address
            </Typography>


            <FormControl sx={{ padding: 0, margin: 0 }}>
              <FormControlLabel style={{ padding: 0, margin: 0 }}
                control={
                  <Checkbox
                    style={{ padding: 0, margin: 0 }}
                    size="small"
                    checked={isSameAspermanentAddress}
                    name="sameAddress"
                    onChange={() => {
                      if (!isSameAspermanentAddress) copyAddress();

                      setSameAspermanentAddress(!isSameAspermanentAddress);
                    }}
                  />
                }
                label={<Typography variant="body2">Same as Permanent Address</Typography>}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="present-address-1"
                label={
                  <span>
                    Address Line-1 <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                onChange={(event) => {
                  handleAddressLine1Change(event, AddressType.PRESENT);
                }}
                value={profile.presentAddress.address1}
                error={errors.presentAddress.address1.exist}
                helperText={
                  errors.presentAddress.address1.exist
                    ? errors.presentAddress.address1.helperText
                    : ""
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="present-address-2"
                label="Address Line-2"
                onChange={(event) => {
                  handleAddressLine2Change(event, AddressType.PRESENT);
                }}
                value={profile.presentAddress.address2}
                inputProps={profile.exist && fontColor}
                error={errors.presentAddress.address2.exist}
                helperText={
                  errors.presentAddress.address2.exist
                    ? errors.presentAddress.address2.helperText
                    : ""
                }
              />
            </FormControl>

            {/* <FormControl style={{ marginTop: "1.5%" }}> */}
            <Autocomplete
              size={"small"}
              sx={{
                width: { sm: "auto", md: 300 },
                display: { sm: "block", md: "inline-block" },
              }}
              onChange={(event, value) => {
                handleStateChange(value, AddressType.PRESENT);
              }}
              id="permanent-state"
              options={states}
              value={
                profile.exist === true
                  ? {
                    stateName:
                      profile.presentAddress.district.statename.stateName,
                  }
                  : presentSelectedState
              }
              // value={presentSelectedState}  // use this after working with update landlord bug.
              getOptionSelected={() => selectedState}
              getOptionLabel={(option) => option.stateName}
              // style={{ width: 250 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      State <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  variant="outlined"
                  error={errors.presentAddress.state.exist}
                  helperText={
                    errors.presentAddress.state.exist
                      ? errors.presentAddress.state.helperText
                      : ""
                  }
                />
              )}
            />
            {/* </FormControl> */}

            {/* <FormControl style={{ marginTop: "1.5%" }}> */}
            <Autocomplete
              size={"small"}
              sx={{
                width: { sm: "auto", md: 300 },
                display: { sm: "block", md: "inline-block" },
              }}
              onChange={(event, value) => {
                handleDistrictChange(value, AddressType.PRESENT);
              }}
              id="permanent-district"
              options={presentDistricts}
              value={
                profile !== null
                  ? profile.presentAddress.district
                  : presentSelectedDistrict
              }
              getOptionSelected={() => selectedDistrict}
              getOptionLabel={(option) => option.districtName}
              // style={{ width: 250 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      District <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  variant="outlined"
                  error={errors.presentAddress.district.exist}
                  helperText={
                    errors.presentAddress.district.exist
                      ? errors.presentAddress.district.helperText
                      : ""
                  }
                />
              )}
            />
            {/* </FormControl> */}

            <FormControl fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="present-pincode"
                label={
                  <span>
                    Pincode <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                onChange={(event) => {
                  handlepinCodeChange(event, AddressType.PRESENT);
                }}
                value={profile.presentAddress.pinCode}
                error={errors.presentAddress.pinCode.exist}
                helperText={
                  errors.presentAddress.pinCode.exist
                    ? errors.presentAddress.pinCode.helperText
                    : ""
                }
                inputProps={profile.exist && fontColor}
              />
            </FormControl>
            {/* </div> */}
          </Stack>
        </Paper>
      </Box>
      <Button
        style={{ float: "none", marginTop: "1rem" }}
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={async (e) => {
          const errs = validateLandLordData();
          if (errs.error === true) {
            setErrors(errs);
            return;
          }
          if (profile.exist === true) await updateLandlord();
          else await saveLandlord();
        }}
      >
        Save
      </Button>
      <VerifyOTPDialog
        openVerifyOTP={openVerifyOTP}
        setOpenVerifyOTP={setOpenVerifyOTP}
        id={profile.id}
        setDisableOtherTabs={setDisableOtherTabs}
        snackBar={snackbar}
        setSnackBar={openSnackBar}

      />
    </>
  );
}
