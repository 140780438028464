import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    // "& .MuiTextField-root": {
    //   margin: theme.spacing(1),
    // },
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 5,
  },
  lsInputs: {
    marginLeft: 10,
    marginTop: 10,
  },
  dashedBorder: {
    // border: "1px dashed",
    // borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));
