import { Button } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import DataGrid from "react-data-grid";
import "react-data-grid/dist/react-data-grid.css";
import ApplicationService from "../../../api/ApplicationService";
import { GlobalContext } from "../../../context/GlobalState";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CustomDataTable from "../../../components/data-table/CustomDataTable"
import { getDecryptedData } from "../../../api/GlobalService";
// import Pagination from "@mui/material/Pagination";

const columns = [
  {
    name: "applid",
    label: "Application ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "tenantname",
    label: "Tenant Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "landlordname",
    label: "Landlord name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "rentaladdress",
    label: "Rental Address",
    options: {
      filter: true,
      sort: true,
    },
  },
];
export default function TenantApplicationsDataTable(props) {
  const { refreshDataTable } = props;
  const { currentApplication } = useContext(GlobalContext);
  const [applications, setApplications] = useState([]);
  const [firstPage, setFirstPage] = useState(false);
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [lastPage, setLastPage] = useState(false);

  let arr = [];
  for (var i = 1; i <= totalPages; i++) {
    arr.push(i);
  }

  const getApplications = async () => {
    const response = await ApplicationService.getCompleteApplicationByLandlordEpicNumber(
      // getDecryptedData("username"),
      sessionStorage.getItem("username"),
      currentApplication.id,
      selectedPageNumber
    );

    if (lastPage !== response.data.iscurrentPageLast) setLastPage(!lastPage);

    if (firstPage !== response.data.iscurrentPageFirst)
      setFirstPage(!firstPage);

    if (totalPages === 0) {
      setTotalPages(response.data.totalPages);
      // setLinks(response.data.applDto);
    }

    setApplications(response.data.applDto); //07/04/2021----- changed
  };

  useEffect(() => {
    getApplications();
  }, [selectedPageNumber, refreshDataTable]);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <CustomDataTable
        rows={applications}
        columns={columns}
        title="Completed Applications of Tenants for this specific Landlord"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          disabled={firstPage}
          onClick={() => setSelectedPageNumber(selectedPageNumber - 1)}
        >
          <NavigateBeforeIcon />
        </Button>
        {arr.map((number) => (
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedPageNumber(number - 1);
              // console.log(`Page number is ${number}`);
            }}
            key={number}
          >
            {number}
          </Button>
        ))}
        <Button
          disabled={lastPage}
          onClick={() => setSelectedPageNumber(selectedPageNumber + 1)}
        >
          <NavigateNextIcon />
        </Button>
      </div>
      {/* <Pagination
        count={arr.length}
        onChange={(e) => console.log("Pagination:::", e)}
      /> */}
    </div>
  );
}
