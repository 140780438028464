import {
  DialogActions,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import UserService from "../../api/UserService";
import MasterData from "../../api/MasterData";
import RentalUnitService from "../../api/RentalUnitService";

import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import PageHeader from "../../components/Header/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import AddLocalAuthorityDialog2 from "./AddLocalAuthorityDialog2";
import { Autocomplete } from "@mui/material";
import { Box } from "@mui/system";
import AddLocalAuthorityUserDialog2 from "./AddLocalAuthorityUserDialog2";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import AddLocalAuthority2Dialog from "./AddLocalAuthority2Dialog";
// import AddLocalAuthority2UserDialog from "./AddLocalAuthority2UserDialog";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import AddIcon from "@mui/icons-material/Add";
import { getDecryptedData } from "../../api/GlobalService";

function AddLocalAuthority2({ history }) {
  const [ps, setPs] = useState("");
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [roles, setRoles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [localAuthorityCode, setLocalAuthorityCode] = useState("");
  const [localities, setLocalities] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [la, setLa] = useState({
    localAuthorityName: "",
    headManName: "",
    // area:
    //   getDecryptedData("role") === "SUPERMODERATOR"
    //     ? getDecryptedData("usercode")
    //     : "",
    // ps:
    //   getDecryptedData("role") === "SUPERMODERATOR"
    //     ? getDecryptedData("usercode").slice(0, 9)
    //     : "",
    area:
      sessionStorage.getItem("role") === "SUPERMODERATOR"
        ? sessionStorage.getItem("usercode")
        : "",
    ps:
      sessionStorage.getItem("role") === "SUPERMODERATOR"
        ? sessionStorage.getItem("usercode").slice(0, 9)
        : "",
  });

  const [landlordUser, setLandlordUser] = useState({
    username: "",
    password: "",
    // usercode:
    //   getDecryptedData("role") === "SUPERMODERATOR"
    //     ? getDecryptedData("usercode")
    //     : "",
    usercode:
      sessionStorage.getItem("role") === "SUPERMODERATOR"
        ? sessionStorage.getItem("usercode")
        : "",
    role: [{}],
    email: "rilangkishabong@gmail.com",
  });

  const toggleRefresh = () => {
    setRefresh(!refresh);
  };

  const getRoles = () => {
    UserService.getRoles()
      .then((response) => {
        setRoles(response.data);
        console.log(response.data);
      })
      .catch((e, response) => {
        console.error(e);
        // alert(JSON.stringify(e.response.data));
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const columns = [
    {
      name: "localAuthorityCode",
      label: "Sub-Locality Code",
      options: {
        filter: true,
      },
    },
    {
      name: "localAuthorityName",
      label: "Sub-Locality Name",
      options: {
        filter: true,
      },
    },
    {
      name: "headManName",
      label: "Sub-Locality Head",
      options: {
        filter: true,
      },
    },
    {
      name: "users", // needs to use .map() here
      label: "Users",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[3].map((user, index) => (
            <div key={index}>
              {user}
              {tableMeta.rowData[3].length - 1 === index ? "" : ","}
            </div>
          ));
        },
      },
    },
    {
      name: "action1", // needs buttons
      label: "Actions",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              style={{
                backgroundColor: "#F3A712",
                color: "#fff",
                borderRadius: "20px",
              }}
              onClick={() => {
                setLocalAuthorityCode(tableMeta.rowData[0]); // THE MAIN PROBLEM
                setOpen(!open);
              }}
            >
              ADD USER
            </Button>
          );
        },
      },
    },
  ];

  const getLocalities = async () => {
    const response = await RentalUnitService.getLocalities(
      // getDecryptedData("usercode")
      sessionStorage.getItem("usercode")
    );
    setLocalities(response.data);
  };

  const getLocalAuth = async (localityCode) => {
    const response = await RentalUnitService.getLocalAutoritiesByLocalityCode(
      localityCode
    );
    var data = [];
    data = response.data;
    for (var i = 0; i < data.length; i++) {
      data[i].id = i + 1;
      data[i].localityName = data[i].localityName;
      data[i]["localAuthorityCode"] = data[i].localAuthorityCode;

      const userresponse = await UserService.getUserbycode(
        // sessionStorage.getItem("role") === "SUPERMODERATOR"
        //   ? data[i].localAuthorityCode
        //   : data[i].locality.localityCode
        //   ? data[i].localAuthorityCode
        data[i].localAuthorityCode
      );
      data[i].users = userresponse.data;
    }
    setData(data);
  };

  const getPoliceStation = () => {
    // MasterData.getPoliceStationByCode(getDecryptedData("usercode"))
    MasterData.getPoliceStationByCode(sessionStorage.getItem("usercode"))
      .then(
        (res) => {
          console.log("police Station is : ", res.data.policeStationName);
          setPs(res.data.policeStationName);
          setLa({ ...la, ps: res.data.policeStationCode });
        }
      );
  };

  useEffect(() => {
    // getDistricts();
    if (
      // getDecryptedData("role") !== "MODERATOR" &&
      // getDecryptedData("role") !== "SUPERMODERATOR"
      sessionStorage.getItem("role") !== "MODERATOR" &&
      sessionStorage.getItem("role") !== "SUPERMODERATOR"
    ) {
      getPoliceStation();
      getLocalities();
    }
    if (
      // getDecryptedData("role") === "MODERATOR" ||
      // getDecryptedData("role") === "SUPERMODERATOR"
      sessionStorage.getItem("role") === "MODERATOR" ||
      sessionStorage.getItem("role") === "SUPERMODERATOR"
    ) {
      // getLocalAuth(getDecryptedData("usercode"));
      getLocalAuth(sessionStorage.getItem("usercode"));
    }
  }, [refresh]);

  const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  function rowKeyGetter(row) {
    // let result = [];
    // if (params.row.phone) {
    //   if (params.row.phone.home) {
    //     result.push("home: " + params.row.phone.home);
    //   }
    //   if (params.row.phone.office) {
    //     result.push("office: " + params.row.phone.office);
    //   }
    // } else {
    //   result = ["Unknown"];
    // }
    // return result.join(", ");

    return row.id;
  }

  const handleLocalityChange = (value) => {
    if (value != null) {
      setSelectedLocality(value);
      console.log("value", value);
      getLocalAuth(value.localityCode);
    } else {
      return;
    }
  };

  return (
    <>
      <PageHeader
        title="Sub-Localities"
        subTitle="Add new sub-locality and its corresponding users."
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { sm: "column", xs: "column", lg: "row", md: "row" },
          justifyContent: "space-between",
        }}
      >
        {/* {getDecryptedData("role") === "VERIFIER" && ( */}
        {sessionStorage.getItem("role") === "VERIFIER" && (
          <FormControl>
            <Autocomplete
              size={"small"}
              onChange={(event, value) => {
                handleLocalityChange(value);
              }}
              id="standard-basic"
              options={localities}
              getOptionLabel={(option) => option.localityName}
              sx={{
                width: { xs: "100%", sm: 600, lg: 600, md: 600 },
                marginBottom: { sm: "10px", xs: "10px", lg: 0, md: 0 },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter sub-localities by locality *"
                  variant="outlined"
                // error={errors.localAuthority.exist === true ? true : false}
                // helperText={
                //   errors.localAuthority.exist
                //     ? errors.localAuthority.helperText
                //     : ""
                // }
                />
              )}
            />
          </FormControl>
        )}
        <FormControl>
          <Button
            onClick={() => {
              setOpenDialog(true);
            }}
            variant="contained"
            sx={{
              backgroundColor: "#F3A712",
              "&:hover": { backgroundColor: "#F3A712" },
            }}
          >
            + Add Sub-Locality
          </Button>
        </FormControl>
      </Box>

      <div style={{ marginTop: "2%" }}>
        {data.length === 0 ? (
          "No Data"
        ) : (
          <>
            {selectedLocality && (
              <Typography>
                Headman Name: <b>{selectedLocality.headManName}</b>
              </Typography>
            )}
            <CustomDataTable
              rows={data}
              columns={columns}
              title="Sub-Localities"
            />
          </>
        )}
        <Dialog // Dialog for Creating Local Auth User
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h3" align="center">
              Create Sub-Locality User
            </Typography>
          </DialogTitle>
          <DialogContent>
            <AddLocalAuthorityUserDialog2
              landlordUser={landlordUser}
              setLandlordUser={setLandlordUser}
              roles={roles}
              localAuthorityCode={localAuthorityCode}
              toggleRefresh={toggleRefresh}
              setOpen={setOpen}
            />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog // Dialog for Creating Sub-Locality
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3" align="center">
            Create Sub-Locality
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AddLocalAuthorityDialog2
            getLocalAuth={getLocalAuth}
            setLa={setLa}
            la={la}
            ps={ps}
            localities={localities}
            setOpenDialog={setOpenDialog}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
export default AddLocalAuthority2;
