import * as React from "react";
import DataGrid from "react-data-grid";
import "react-data-grid/dist/react-data-grid.css";
import { useState } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import { useEffect } from "react";
import {
  Button,
  Chip,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ApplicationDetails from "./ApplicationDetails";
import ApplicationService from "../../api/ApplicationService";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LandlordService from "../../api/LandlordService";
import { Autocomplete, CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import ApplicationFlowDialog from "./ApplicationFlowDialog";
import RentalUnitService from "../../api/RentalUnitService";
import UserService from "../../api/UserService";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";
import { getDecryptedData } from "../../api/GlobalService";

const useStyle = makeStyles({
  rowColor: {
    backgroundColor: "#E8EAFC",
    color: "black",
  },
});

export default function ApplicationDataTable(props) {
  const classes = useStyle();
  const { applications, getApplications, addProcessApplication } = useContext(
    GlobalContext
  );
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState(applications);
  const [open, setOpen] = React.useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedAppID, setSelectedAppID] = useState(null);
  // const role = getDecryptedData("role");
  const role = sessionStorage.getItem("role");
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [firstPage, setFirstPage] = useState(false);
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noApplication, setNoAppliation] = useState(false);
  const [view, setView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAppFlow, setOpenAppFlow] = useState(false);
  const [appFlow, setAppFlow] = useState([]);
  const [localAuthorities, setLocalAuthorities] = useState([]);
  const [localAuthPayload, setLocalAuthPayload] = useState({
    username: "",
    usercode: "",
  });
  const [selectedLocalAuth, setSelectedLocalAuth] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [applIdtoDelete, setApplIdToDelete] = React.useState(false);


  const { status, shifted } = props;

  let arr = [];
  for (var i = 1; i <= totalPages; i++) {
    arr.push(i);
  }

  const handleDownload = async (base64img, type) => {
    console.log(base64img);
    //   const base64Pdf = await TenantService.getDocumentsByDocumentId(props.row.docid);
    var blob = base64toBlob(base64img, type);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };

  function base64toBlob(base64Data, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: type });
  }

  const getApplicationFlow = async (id) => {
    try {
      const appFlow = await ApplicationService.getApplicationFlow(id);
      setAppFlow(appFlow.data);
      setOpenAppFlow(true);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: "applid",
      label: "Application ID",
    },
    {
      name: "tenantname",
      label: "Tenant Name",
    },
    {
      name: "landlordname",
      label: "Landlord name",
    },
    {
      name: "rentaladdress",
      label: "Rental Address",
    },
    // {
    //   name: "rentcode",
    //   label: "Rent Code",

    // },
    // {
    //   name: "landlordcode",
    //   label: "Landlord Epic",
    // },
    {
      name: "applStatus",
      label: "Status",
      options: {
        display: shifted && false,
        customBodyRender: (value, tableMeta) => {
          console.log(tableMeta);

          if (tableMeta.rowData[4].applstatus === "PLVP")
            return (
              <Chip
                color="primary"
                label="APPROVED"
                style={{ padding: 0, backgroundColor: "#92d459" }}
              />
            );
          else if (
            tableMeta.rowData[4].applstatus === "LAVP" &&
            // getDecryptedData("role") === "MODERATOR"
            sessionStorage.getItem("role") === "MODERATOR"
          )
            return (
              <Chip
                label="Pending at Locality level"
                style={{ padding: 0, backgroundColor: "#9013FE", color: "#fff" }}
              />
            );
          else if (tableMeta.rowData[4].applstatus === "RJT")
            return (
              <Chip
                label="REJECTED"
                style={{ padding: 0, backgroundColor: "red", color: "#fff" }}
              />
            );
          else
            return (
              <Chip label="Pending" style={{ padding: 0 }} color="secondary" />
            );
        },
      },
    },

    {
      name: "logby",
      label: "Logby",
      options: {
        display: false
      }
    },

    shifted ?
      {
        name: "leftLocalityOn",
        label: "Shifted On",
        options: {
          customBodyRender: (value, tableMeta) => {
            console.log(tableMeta);
            return tableMeta.rowData[6]
          }
        }
      }
      :
      {
        name: "actions",
        label: "Actions",
        options: {
          customBodyRender: (value, tableMeta) => {
            switch (role) {
              case "VERIFIER":
                const openPopper = Boolean(anchorEl);
                const id = openPopper ? "simple-popper" : undefined;
                return tableMeta.rowData[4].applstatus === "LAVP" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: 2, fontSize: 10 }}
                    onClick={async () => {
                      setSelectedAppID(tableMeta.rowData[0]);
                      await getApplication(tableMeta.rowData[0]);
                      setOpen(true);
                    }}
                  >
                    Process
                  </Button>
                ) : tableMeta.rowData[4].applstatus === "RJT" ? (
                  <>
                    <Button
                      size={"small"}
                      variant="contained"
                      color="primary"
                      style={{ margin: 2, fontSize: 10 }}
                      onClick={async () => {
                        setSelectedAppID(tableMeta.rowData[0]);
                        await getApplicationFlow(tableMeta.rowData[0]);
                        // await getApplication(row.applid);
                        // setView(true);
                        // setOpen(true);
                      }}
                    >
                      App Flow
                    </Button>

                    <Button
                      size={"small"}
                      variant="contained"
                      color="primary"
                      style={{ margin: 2, fontSize: 10 }}
                      onClick={async () => {
                        setSelectedAppID(tableMeta.rowData[0]);
                        await getApplication(tableMeta.rowData[0]);
                        setView(true);
                        setOpen(true);
                      }}
                    >
                      View
                    </Button>
                  </>
                ) : (
                  <Button
                    size={"small"}
                    variant="contained"
                    color="primary"
                    style={{ margin: 2, fontSize: 10 }}
                    onClick={async () => {
                      setSelectedAppID(tableMeta.rowData[0]);
                      await getApplication(tableMeta.rowData[0]);
                      setView(true);
                      setOpen(true);
                    }}
                  >
                    View
                  </Button>
                );

              case "MODERATOR":
                return tableMeta.rowData[4].applstatus === "LARP" ? (
                  isLoading && selectedAppID === tableMeta.rowData[0] ? (
                    <CircularProgress size={22} />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 2, fontSize: 10 }}
                        onClick={async () => {
                          setIsLoading(true);
                          setSelectedAppID(tableMeta.rowData[0]);
                          await getApplication(tableMeta.rowData[0]);
                          setOpen(true);
                        }}
                      >
                        Process
                      </Button>
                    </>
                  )
                ) : tableMeta.rowData[4].applstatus === "PLVP" ? (
                  isLoading && selectedAppID === tableMeta.rowData[0] ? (
                    <CircularProgress size={22} />
                  ) : (
                    <>
                      {/* <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: 2, fontSize: 10 }}
                    onClick={async () => {
                      setSelectedAppID(row.applid);
                      setIsLoading(true);
                      const fileBase64 = await LandlordService.generateRC(
                        row.applid
                      );
                      handleDownload(
                        fileBase64.data.base64img,
                        "application/pdf"
                      );
                      setIsLoading(false);
                      // setSelectedAppID(row.applid);
                      // await getApplication(row.applid);
                      // setOpen(true);
                    }}
                  >
                    Certify
                  </Button> */}
                      <Button
                        size={"small"}
                        variant="contained"
                        color="primary"
                        style={{ margin: 2, fontSize: 10 }}
                        onClick={async () => {
                          setSelectedAppID(tableMeta.rowData[0]);
                          await getApplicationFlow(tableMeta.rowData[0]);
                          // await getApplication(row.applid);
                          // setView(true);
                          // setOpen(true);
                        }}
                      >
                        App Flow
                      </Button>
                    </>
                  )
                ) : (
                  <>
                    <Button
                      size={"small"}
                      variant="contained"
                      color="primary"
                      style={{ margin: 2, fontSize: 10 }}
                      onClick={async () => {
                        setSelectedAppID(tableMeta.rowData[0]);
                        await getApplication(tableMeta.rowData[0]);
                        setView(true);
                        setOpen(true);
                      }}
                    >
                      View
                    </Button>
                    <Button
                      size={"small"}
                      variant="contained"
                      color="primary"
                      style={{ margin: 2, fontSize: 10 }}
                      onClick={async () => {
                        setSelectedAppID(tableMeta.rowData[0]);
                        await getApplicationFlow(tableMeta.rowData[0]);
                        // await getApplication(row.applid);
                        // setView(true);
                        // setOpen(true);
                      }}
                    >
                      App Flow
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      disabled={
                        // tableMeta.rowData[5] !== getDecryptedData("username") ||
                        tableMeta.rowData[5] !== sessionStorage.getItem("username") ||
                        tableMeta.rowData[4].applstatus !== "LAVP"
                      }
                      variant="contained"
                      color="primary"
                      style={{ margin: 2, fontSize: 10 }}
                      size={"small"}

                      onClick={async () => {
                        setApplIdToDelete(tableMeta.rowData[0]);
                        setOpenConfirmDialog(true);
                      }}
                    >
                      Delete
                    </Button>
                  </>
                );

              case "SUPERMODERATOR":
                return tableMeta.rowData[4].applstatus === "LAVP" ? (
                  isLoading && selectedAppID === tableMeta.rowData[0] ? (
                    <CircularProgress size={22} />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 2, fontSize: 10 }}
                        onClick={async () => {
                          setIsLoading(true);
                          setSelectedAppID(tableMeta.rowData[0]);
                          await getApplication(tableMeta.rowData[0]);
                          setOpen(true);
                        }}
                      >
                        Process
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 2, fontSize: 10 }}
                        onClick={async () => {
                          setSelectedAppID(tableMeta.rowData[0]);
                          await getApplicationFlow(tableMeta.rowData[0]);
                        }}
                      >
                        App Flow
                      </Button>
                    </>
                  )
                ) : tableMeta.rowData[4].applstatus === "PLVP" ? (
                  isLoading && selectedAppID === tableMeta.rowData[0] ? (
                    <CircularProgress size={22} />
                  ) : (
                    <>
                      <Button
                        size={"small"}
                        variant="contained"
                        color="primary"
                        style={{ margin: 2, fontSize: 10 }}
                        onClick={async () => {
                          setSelectedAppID(tableMeta.rowData[0]);
                          await getApplication(tableMeta.rowData[0]);
                          setView(true);
                          setOpen(true);
                        }}
                      >
                        View
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: 2, fontSize: 10 }}
                        onClick={async () => {
                          setSelectedAppID(tableMeta.rowData[0]);
                          setIsLoading(true);
                          const fileBase64 = await LandlordService.generateRC(
                            tableMeta.rowData[0]
                          );
                          handleDownload(
                            fileBase64.data.base64img,
                            "application/pdf"
                          );
                          setIsLoading(false);
                          // setSelectedAppID(row.applid);
                          // await getApplication(row.applid);
                          // setOpen(true);
                        }}
                      >
                        Certify
                      </Button>
                      <Button
                        size={"small"}
                        variant="contained"
                        color="primary"
                        style={{ margin: 2, fontSize: 10 }}
                        onClick={async () => {
                          setSelectedAppID(tableMeta.rowData[0]);
                          await getApplicationFlow(tableMeta.rowData[0]);
                          // await getApplication(row.applid);
                          // setView(true);
                          // setOpen(true);
                        }}
                      >
                        App Flow
                      </Button>


                    </>
                  )
                ) : (
                  <>
                    <Button
                      size={"small"}
                      variant="contained"
                      color="primary"
                      style={{ margin: 2, fontSize: 10 }}
                      onClick={async () => {
                        setSelectedAppID(tableMeta.rowData[0]);
                        await getApplication(tableMeta.rowData[0]);
                        setView(true);
                        setOpen(true);
                      }}
                    >
                      View
                    </Button>
                    <Button
                      size={"small"}
                      variant="contained"
                      color="primary"
                      style={{ margin: 2, fontSize: 10 }}
                      onClick={async () => {
                        setSelectedAppID(tableMeta.rowData[0]);
                        await getApplicationFlow(tableMeta.rowData[0]);
                        // await getApplication(row.applid);
                        // setView(true);
                        // setOpen(true);
                      }}
                    >
                      App Flow
                    </Button>
                  </>
                );

              default:
                return <></>;
            }
          }
        }
      },

  ];
  const getApps = async (dataForSupermoderator) => {
    if (dataForSupermoderator !== null) {
      try {
        const response = await ApplicationService.getApplicationForPagination(
          status,
          // getDecryptedData("username"),
          // selectedPageNumber,
          // getDecryptedData("role") === "SUPERMODERATOR"
          //   ? dataForSupermoderator.localAuthorityCode
          //   : getDecryptedData("usercode")
          sessionStorage.getItem("username"),
          selectedPageNumber,
          sessionStorage.getItem("role") === "SUPERMODERATOR"
            ? dataForSupermoderator.localAuthorityCode
            : sessionStorage.getItem("usercode")
        );

        setNoAppliation(false);

        if (lastPage !== response.data.iscurrentPageLast)
          setLastPage(!lastPage);

        if (firstPage !== response.data.iscurrentPageFirst)
          setFirstPage(!firstPage);

        if (totalPages === 0) {
          setTotalPages(response.data.totalPages);
          setLinks(response.data.applDto);
        }
        /* the datagrid required for each row a unique id */
        for (var i = 0; i < response.data.applDto.length; i++) {
          response.data.applDto[i].id = i;
        }

        getApplications(response.data.applDto); //

        setData(response.data.applDto);
      } catch {
        setNoAppliation(true);
      }
    } else return;
  };

  const getLocalAuth = async (localityCode) => {
    const response = await RentalUnitService.getLocalAutoritiesByLocalityCode(
      // getDecryptedData("usercode")
      sessionStorage.getItem("usercode")
    );
    var data = [];
    data = response.data;
    for (var i = 0; i < data.length; i++) {
      data[i].id = i + 1;
      data[i].localityName = data[i].localityName;

      const userresponse = await UserService.getUserbycode(
        // sessionStorage.getItem("role") === "SUPERMODERATOR"
        //   ? data[i].localAuthorityCode
        //   : data[i].locality.localityCode
        //   ? data[i].localAuthorityCode
        data[i].localAuthorityCode
      );
      data[i].users = userresponse.data;
    }
    setLocalAuthorities(data);
  };

  const handleDelete = async () => {
    await ApplicationService.deleteApplication(applIdtoDelete)
      .then((response) => {
        alert(response.data.message)
        setRefresh(!refresh);
      })
      .catch((err) => alert(err.response.data.message));
  }

  useEffect(() => {
    // getDecryptedData("role") !== "SUPERMODERATOR"
    sessionStorage.getItem("role") !== "SUPERMODERATOR"
      ? getApps()
      : getLocalAuth();
    // getDecryptedData("role") === "SUPERMODERATOR" &&
    sessionStorage.getItem("role") === "SUPERMODERATOR" &&
      getApps(selectedLocalAuth);
  }, [selectedPageNumber, refresh]);

  // useEffect(() => {
  //   sessionStorage.getItem("role") === "SUPERMODERATOR" &&
  //     getApps(selectedLocalAuth);
  // }, [selectedPageNumber]);

  const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getApplication = async (id) => {
    try {
      const app = await ApplicationService.getApplication(
        id != null ? id : selectedAppID
      );
      addProcessApplication(app.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  function rowKeyGetter(row) {
    return row.id;
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <ApplicationDetails
        open={open}
        view={view}
        handleClose={handleClose}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      {/* {getDecryptedData("role") === "SUPERMODERATOR" && ( */}
      {sessionStorage.getItem("role") === "SUPERMODERATOR" && (
        <FormControl>
          <Autocomplete
            size={"small"}
            onChange={(event, value) => {
              setToggle(() => !toggle);
              setSelectedLocalAuth(value);
              getApps(value);
            }}
            id="standard-basic"
            options={localAuthorities}
            getOptionLabel={(option) => option.localAuthorityName}
            sx={{
              width: { xs: "100%", sm: 600, lg: 600, md: 600 },
              marginBottom: { sm: "10px", xs: "10px", lg: 0, md: 0 },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter applications by Sub-Localities *"
                variant="outlined"
              // error={errors.localAuthority.exist === true ? true : false}
              // helperText={
              //   errors.localAuthority.exist
              //     ? errors.localAuthority.helperText
              //     : ""
              // }
              />
            )}
          />
        </FormControl>
      )}

      {noApplication ? (
        <div>No Applications</div>
      ) : (
        <div>
          <CustomDataTable
            rows={data}
            columns={columns}
            title="Applications List"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={firstPage}
              onClick={() => setSelectedPageNumber(selectedPageNumber - 1)}
            >
              <NavigateBeforeIcon />
            </Button>
            {arr.map((number) => (
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectedPageNumber(number - 1);
                  console.log(`Page number is ${number}`);
                }}
                key={number}
              >
                {number}
              </Button>
            ))}
            <Button
              disabled={lastPage}
              onClick={() => setSelectedPageNumber(selectedPageNumber + 1)}
            >
              <NavigateNextIcon />
            </Button>
          </div>
        </div>
      )}
      <ApplicationFlowDialog
        openAppFlow={openAppFlow}
        setOpenAppFlow={setOpenAppFlow}
        appFlow={appFlow}
      />
      <ConfirmDialog
        text="Are you sure you want to delete this application?"
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        handleDelete={handleDelete}
      />
    </div>
  );
}
