import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import SearchIcon from "@mui/icons-material/Search";
import ApplicationService from "../../api/ApplicationService";
import CustomDataTable from "../../components/data-table/CustomDataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { GlobalContext } from "../../context/GlobalState";
import TenantService from "../../api/TenantService";
import { makeStyles } from "@material-ui/styles";
import TenantTab from "../offlineapplication/TenantTab";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmDialog from "../../components/confirm-dialog/ConfirmDialog";
import { storeEncryptedData } from "../../api/GlobalService";

const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const initialState = {
  name: "",
  entity: "tenant",
};

const SearchTenantAndLandlord = () => {
  const classes = useStyle();
  const [payload, setPayload] = useState(initialState);
  const [rows, setRows] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const {
    openSnackBar,
    editTenantApplication,
    newTenantApplication,
  } = useContext(GlobalContext);
  const [noUserFound, setNoUserFound] = useState(false);
  const [editTenant, setEditTenant] = useState(false);
  const [rowDetails, setRowDetails] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
      },
    },

    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
      },
    },

    { name: "lastName", label: "Last Name" },
    { name: "age", label: "Date of Birth" },
    { name: "mobile", label: "Mobile" },
    { name: "occupation", label: "Occupation" },
    { name: "permanentAddress.address1", label: "Permanent Address" },
    { name: "presentAddress.address1", label: "Present Address" },
    {
      name: "delete",
      label: "Delete Tenant",
      options: {
        display: payload.entity === "tenant" ? true : false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size={"small"}
              variant="contained"
              color="primary"
              onClick={() => {
                setRowDetails(tableMeta);
                setOpenConfirmDialog(true);
                // handleDelete(tableMeta);
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          );
        },
      },
    },
    {
      name: "edit",
      label: "Edit Tenant",
      options: {
        display: payload.entity === "tenant" ? true : false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              size={"small"}
              variant="contained"
              color="primary"
              onClick={() => {
                handleEdit(tableMeta.rowData[0]);
              }}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];

  const handleDelete = async () => {
    setBackdropOpen(true);
    TenantService.remove(rowDetails.rowData[0])
      .then(async (response) => {
        // await deleteRentalUnit(rowDetails.rowData[8]);
        setBackdropOpen(false);
        setOpenConfirmDialog(false);

        openSnackBar({
          open: true,
          message: "Successfully Deleted Rental Unit",
          severity: "success",
        });
        handleSearch();
      })
      .catch(async (err) => {
        setBackdropOpen(false);
        setOpenConfirmDialog(false);

        openSnackBar({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  };

  const handleEdit = async (tenantId) => {
    setBackdropOpen(true);
    TenantService.getTenantById(tenantId)
      .then(async (response) => {

        sessionStorage.setItem("tenantId", tenantId);
        // storeEncryptedData("tenantId", tenantId);

        editTenantApplication({ ...response.data, exist: true });
        setEditTenant(true);
        setBackdropOpen(false);
        // handleSearch();
      })
      .catch(async (err) => {
        setBackdropOpen(false);
        openSnackBar({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  };

  const handleSearch = async () => {
    await ApplicationService.searchPeople(payload).then((response) => {
      if (Array.isArray(response.data)) {
        setRows(response.data);
        response.data === "" ? setNoUserFound(true) : setNoUserFound(false);
      } else {
        response.data === "" ? setNoUserFound(true) : setNoUserFound(false);

        setRows([]);
        let data = null;
        data = response.data;
        if (data.id === null) {
          data.id = data.landlord_id;
        }
        // data[0]["permanentAddress"] = data.permanentAddress.address1;
        // data.presentAddress = data.presentAddress.address1;

        setRows((oldArray) => [...oldArray, data]);
        response.data[0] === "" && setNoUserFound(true);
      }
    });
    // rows.length < 1 ? setNotFound(true) : setNotFound(false);
  };

  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === 'Enter') {
      // Trigger the button click
      handleClick();
    }
  };

  const handleClick = async () => {
    // setBackDropOpen(true);
    await handleSearch();
    // setBackDropOpen(false);
  };



  return (
    <>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageHeader
        title="Search Tenants & Landlords"
        subTitle="Search and edit, update or delete tenant details."
        icon={<SearchIcon fontSize="large" />}
      />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          width: { xs: "90%", md: "50%" },
          padding: "10px",
          margin: "auto"
        }}
      >
        {/* <Typography>Search Tenants & Landlords</Typography> */}
        <FormControl>
          <TextField
            key={noUserFound}
            autoFocus={true}
            size={"small"}
            margin="dense"
            label="Name (or EPIC, PAN, ID)" //REGISTRATION NUMBER HAS PROBLEM
            type="text"
            value={payload.name}
            onChange={(e) => {
              setPayload({
                ...payload,
                name: e.target.value,
              });
            }}
            onKeyDown={handleKeyPress}
          />
        </FormControl>
        <FormControl>
          <FormLabel >Entity:</FormLabel>
          <RadioGroup
            key={noUserFound}
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={payload.entity}
            name="radio-buttons-group"
            onChange={(event) => {
              setNoUserFound(false);
              setPayload({ ...payload, entity: event.target.value });
              setRows([]);
            }}
          >
            <FormControlLabel
              value="tenant"
              control={<Radio size="small" />}
              label="Tenant"
            />
            <FormControlLabel
              value="landlord"
              control={<Radio size="small" />}
              label="Landlord"
            />
          </RadioGroup>
        </FormControl>
        <Box sx={{ margin: "auto", display: "flex", gap: 2 }}>
          <Button size={"small"} variant="outlined" onClick={handleSearch}>
            Search
          </Button>

          <Button
            variant="outlined"
            size={"small"}
            onClick={() => {
              setPayload({ ...payload, name: "" });
              setPayload({
                ...payload,
                entity: initialState.entity,
              });

              setRows([]);
              // setPayload({ ...payload, entity: payload.entity });
              // setOpenSearchDialog(false);
              setNoUserFound(false);
            }}
          >
            Reset
          </Button>
        </Box>
      </Paper>

      <Box sx={{ minHeight: 50 }} />
      {rows.length !== 0 && rows[0] !== "" && (
        <CustomDataTable
          rows={rows}
          columns={columns}
          title={
            payload.entity === "landlord" ? "Landlord List" : "Tenant List"
          }
        />
      )}

      {noUserFound && (
        <Card>
          <Typography align="center" sx={{ color: "red" }} variant="h5">
            No {payload.entity} found.
          </Typography>
        </Card>
      )}
      <Dialog
        open={editTenant}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            sessionStorage.removeItem("tenantId");
            newTenantApplication();
            setEditTenant(false);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="1000px"
        minHeight="60%"
        maxHeight="60%"
      >
        <IconButton
          style={{
            backgroundColor: "#eb5809",
            color: "#fff",
            position: "absolute",
            right: 0,
            top: 0,
            height: "20px",
            width: "20px",
            borderRadius: 1,
          }}
          onClick={() => {
            sessionStorage.removeItem("tenantId");
            newTenantApplication();

            setEditTenant(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">Edit Tenant Details</DialogTitle>
        <DialogContent>
          <TenantTab selectedRentalUnit={false} />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        text="This action will delete all the data assiocated with this tenant (including family members, helpers and documents). Are you sure you want to delete?"
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default SearchTenantAndLandlord;
