import React from "react";
import StepperForm from "../offlineapplication/StepperForm";

const TenantRevisit = () => {
  return (
    <>
      <StepperForm />
    </>
  );
};

export default TenantRevisit;
