import React from "react";
import { Card, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// styles
// components
// import PageTitle from "../../components/PageTitle/PageTitle";
import TenantsDataTable from "./TenantsDataTable";
import TenantForm from "./TenantForm";
import NewTenant from "./NewTenant";
import PageHeader from "../../components/Header/PageHeader";
import HailIcon from "@mui/icons-material/Hail";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function Tenants(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <PageTitle title="Tenants" /> */}
      <PageHeader
        title="Tenants"
        subTitle="Add or re-visit a tenant profile."
        icon={<HailIcon fontSize="large" />}
      />
      <NewTenant
        activeStep={props.activeStep}
        setActiveStep={props.setActiveStep}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <p style={{ color: "blue" }}>INCOMPLETE APPLICATION(S) </p>
          <TenantsDataTable
            activeStep={props.activeStep}
            setActiveStep={props.setActiveStep}
          />
        </Grid>
      </Grid>
    </div>
  );
}
