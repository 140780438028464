import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dialog, DialogTitle } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { DialogContent, DialogContentText } from "@material-ui/core";
import CampaignIcon from "@mui/icons-material/Campaign";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import ImageIcon from "@mui/icons-material/Image";
import { CustomListItem } from "./CustomListItem";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PreReqDialog({ openPreReq, setOpenPreReq }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={openPreReq} fullWidth onClose={() => setOpenPreReq(false)}>
      <DialogTitle
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography align="center" variant="h5" style={{ color: "#B12B39" }}>
          Announcement
        </Typography>
        <CampaignIcon sx={{ color: "#B12B39" }} fontSize="large" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please make sure that you have the following documents before
          proceeding with the data entries.
        </DialogContentText>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              centered
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Landlord" {...a11yProps(0)} />
              <Tab label="Tenant" {...a11yProps(1)} />
              {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <List>
              <CustomListItem
                primary="Epic card number"
                secondary="Scan copy of Epic card or Epic Card Number issued by election Commission of India of the landlord"
                icon={<InboxIcon />}
              />
              <CustomListItem
                primary="Email"
                secondary="Email may be used as an alternative backup means receiving any information."
                icon={<DraftsIcon />}
              />

              <CustomListItem
                primary="Mobile number"
                secondary="Working Mobile Number is mandatory, as all security related SMS as well as any general
purpose notification will be triggered at the given mobile number."
                icon={<PhoneAndroidIcon />}
              />
            </List>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <List>
              <CustomListItem
                primary="Identity card"
                secondary="Any identification proof document issued by 
                government/government agent such as driving 
                licence, PAN, Passport(excluding AADHAR)"
                icon={<InboxIcon />}
              />
              <CustomListItem
                primary="Email"
                secondary="Email may be used as an alternative backup means receiving any information."
                icon={<DraftsIcon />}
              />
              <CustomListItem
                primary="Mobile number"
                secondary="Working Mobile Number is mandatory, as all 
                 security related SMS as well as any general purpose notification
                 will be triggered at the given mobile number."
                icon={<PhoneAndroidIcon />}
              />
            </List>
            <Divider />
            <List>
            <Typography align="center" color={"red"}>
              Scanned copies must be in JPEG/JPG format and maximum size of
              500KB.
            </Typography>
              <CustomListItem
                primary="Tenant's signature"
                secondary="Scanned copy of tenant's signature."
                icon={<ImageIcon />}
              />
              <CustomListItem
                primary="Tenant's photo"
                secondary="Scanned copy of tenant's photo."
                icon={<ImageIcon />}
              />
              <CustomListItem
                primary="Tenant's identity proof"
                secondary="Scanned copy of tenant's identity proof."
                icon={<ImageIcon />}
              />
              <CustomListItem
                primary="Domestic helper's signature"
                secondary="Scanned copy of signature of the domestic helper if domestic helper is staying along with the tenant in the rental unit."
                icon={<ImageIcon />}
              />
            </List>
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
          Item Three
        </TabPanel> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
