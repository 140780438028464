import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
// import ApplicationReducer from "./ApplicationReducer";

// const applicationData = {
//   tenantid: "",
//   rentcode: "",
//   landlordid: "",
//   applStatus: { applstatus: "" },
//   landlordcode: "",
//   applType: "",
//   logby: "",
// };

const initialState = {
  processApplication: null,
  snackbar: {
    severity: "success",
    open: false,
    message: "Welcome!",
  },
  runningApplication: null,
  applications: [],
  currentApplication: {
    exist: false,
    id: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    occupation: "",
    epicNo: "",
    mobile: "",
    rentalUnit: [],
    helper: [],
    permanentAddress: {
      address1: "",
      address2: "",
      pinCode: "",
      district: {
        districtCode: "",
        districtName: "",
        statename: {
          stateCode: "",
          stateName: "",
        },
      },
      statename: {
        stateCode: "",
        stateName: "",
      },
    },
    presentAddress: {
      address1: "",
      address2: "",
      pinCode: "",
      district: {
        districtCode: "",
        districtName: "",
        statename: {
          stateCode: "",
          stateName: "",
        },
      },
      statename: {
        stateCode: "",
        stateName: "",
      },
    },
  },
  tenantApplication: {
    exist: false,
    id: "",
    individualEpic: "",
    email: "",
    firstName: "",
    document: [],
    middleName: "",
    lastName: "",
    occupation: "",
    permanentAddress: {
      address1: "",
      address2: "",
      pinCode: "",
      district: {
        districtCode: "",
        districtName: "",
        statename: {
          stateCode: "",
          stateName: "",
        },
      },
      statename: {
        stateCode: "",
        stateName: "",
      },
    },
    presentAddress: {
      address1: "",
      address2: "",
      pinCode: "",
      district: {
        districtCode: "",
        districtName: "",
        statename: {
          stateCode: "",
          stateName: "",
        },
      },
      statename: {
        stateCode: "",
        stateName: "",
      },
    },
    mobile: "",
  },
};
export const GlobalContext = createContext(initialState);
// export const ApplicationContext = createContext(applicationData);

// export const ApplicationProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(ApplicationReducer, applicationData);

//   function setApplicationData(appData) {
//     dispatch({
//       type: "SET_APPLICATION_DATA",
//       payload: appData,
//     });
//   }

//   return (
//     <ApplicationContext.Provider
//       value={{
//         applicationData: state.applicationData,
//         setApplicationData,
//       }}
//     >
//       {children}
//     </ApplicationContext.Provider>
//   );
// };
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  async function openSnackBar(message) {
    dispatch({
      type: "OPEN_SNACKBAR",
      payload: message,
    });
  }
  // async function getusername(name){
  //     dispatch({
  //         type: 'GET_USERNAME',
  //         payload:name
  //     });

  // };
  // async function getusercode(code){
  //     dispatch({
  //         type: 'GET_USERCODE',
  //         payload:code
  //     });

  // };

  async function getApplications(applications) {
    dispatch({
      type: "GET_APPLICATIONS",
      payload: applications,
    });
  }

  async function getRunningApplication(application) {
    dispatch({
      type: "GET_RUNNINGAPPLICATIONS",
      payload: application,
    });
  }

  function newApplication(application) {
    dispatch({
      type: "NEW_APPLICATION",
      payload: application,
    });
  }

  function updateApplication(application) {
    dispatch({
      type: "UPDATE_APPLICATION",
      payload: application,
    });
  }

  function addProcessApplication(application) {
    dispatch({
      type: "PROCESS_APPLICATION",
      payload: application,
    });
  }

  function addRentalUnit(rentalUnit) {
    dispatch({
      type: "ADD_RENTALUNIT",
      payload: rentalUnit,
    });
  }
  function addLandlordHelper(helper) {
    dispatch({
      type: "ADD_HELPER",
      payload: helper,
    });
  }

  function existingApplication(application) {
    dispatch({
      type: "EXISTING_APPLICATION",
      payload: application,
    });
  }

  function editApplication(applications) {
    dispatch({
      type: "EDIT_APPLICATION",
      payload: applications,
    });
  }

  function editTenantApplication(applications) {
    dispatch({
      type: "EDIT_TENANT_APPLICATION",
      payload: applications,
    });
  }

  function newTenantApplication() {
    dispatch({
      type: "NEW_TENANT_APPLICATION",
      payload: initialState.tenantApplication,
    });
  }

  function newLandlordApplication() {
    dispatch({
      type: "NEW_LANDLORD_APPLICATION",
      payload: initialState.currentApplication,
    });
  }

  function editRentalUnit(rentCode) {
    dispatch({
      type: "EDIT_RENTALUNIT",
      payload: rentCode,
    });
  }

  function deleteRentalUnit(rentCode) {
    dispatch({
      type: "DELETE_RENTALUNIT",
      payload: rentCode,
    });
  }

  function addTenantDocument(document) {
    dispatch({
      type: "ADD_TENANT_DOCUMENT",
      payload: document,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        initialState: initialState.currentApplication,
        applications: state.applications,
        snackbar: state.snackbar,
        currentApplication: state.currentApplication,
        tenantApplication: state.tenantApplication,
        processApplication: state.processApplication,
        runningApplication: state.runningApplication,
        username: state.username,
        usercode: state.usercode,
        helper: state.currentApplication.helper,
        applicationData: state.applicationData,
        newApplication,
        existingApplication,
        addRentalUnit,
        getApplications,
        editApplication,
        addProcessApplication,
        openSnackBar,
        updateApplication,
        editTenantApplication,
        newTenantApplication,
        getRunningApplication,
        addLandlordHelper,
        newLandlordApplication,
        editRentalUnit,
        deleteRentalUnit,
        addTenantDocument,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
