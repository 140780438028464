import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Widget from "../../components/Widget/Widget";
import Autocomplete from "@mui/lab/Autocomplete";
// styles
import useStyles from "../offlineapplication/styles";
import Button from "@material-ui/core/Button";
import { Typography, TextField, Snackbar } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useState } from "react";
import MasterData from "../../api/MasterData";
import { Alert } from "@mui/lab";
import TenantService from "../../api/TenantService";
import { GlobalContext } from "../../context/GlobalState";
import { useContext } from "react";
import * as Icons from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import DocumentService from "../../api/DocumentService";
import IconButton from '@mui/material/IconButton';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CameraComponent from "../../components/file-upload/CameraComponent";
import { getDecryptedData } from "../../api/GlobalService";


const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
}));

export default function FamilyMemberForm(props) {
  var classTheme = useStyles();

  const { currentApplication } = useContext(GlobalContext);


  const {
    snackBar,
    setSnackBar,
    famMemberFlag,
    setFamMemberFlag,
    setOpen,
    refresh,
    setRefresh,
    closeHelperModal
  } = props;
  const [selectedDocumentType, setSelectedDocumentType] = useState("PHOTO");

  const [occupation, setOccupation] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [photoFlag, setPhotoFlag] = useState(false);
  const [memberCode, setMemberCode] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);

  const [relationships, setRelationships] = useState([]);
  const [document, setDocument] = useState(null);

  const [openCamera, setOpenCamera] = useState(false)

  // const [snackBar, setSnackBar] = useState({
  //   open: false,
  //   severity: "success",
  //   message: "",
  // });


  const [profile, setProfile] = React.useState({
    // tenantId: getDecryptedData("tenantId"),
    tenantId: sessionStorage.getItem("tenantId"),
    memberName: "",
    age: "",
    occupation: "",
    relationship: "",
  });

  const errorInitialState = {
    error: false,

    // age: {
    //   exist: false,
    //   helperText: "Age required",
    // },
    occupation: {
      exist: false,
      helperText: "Occupation required",
    },
    memberName: {
      exist: false,
      helperText: "Member Name required",
    },
    relationship: {
      exist: false,
      helperText: "Relationship required",
    },
  };

  const [errors, setErrors] = useState(errorInitialState);

  const classes = useStyle();

  const handleNameChange = (event) => {
    setProfile({ ...profile, memberName: event.target.value });
  };

  const handleRelationshipChange = (value) => {
    if (value !== null) {
      setProfile({ ...profile, relationship: value });
    } else {
      setProfile({ ...profile, relationship: "" });
    }
  };


  function getBase64(file, callback) {
    const reader = new FileReader();

    reader.addEventListener("load", () => callback(reader.result));

    reader.readAsDataURL(file);
  }

  function getExt(filename) {
    var ext = filename.split(".").pop();
    if (ext === filename) return "";
    return ext;
  }

  const uploadFile = async () => {

    if (file === null || file === undefined) {
      setSnackBar({
        message: "Please Choose a file to upload",
        open: true,
        severity: "error",
      });

      return;
    }


    getBase64(file, async function (base64Data) {
      const fileExtension = getExt(file.name);


      let base64String = /,(.+)/.exec(base64Data)[1];

      const payload = {
        ownerid: memberCode, //sessionStorage.getItem("tenantId")
        ownertype: "tenantfamily",
        doctype: "PHOTO",
        base64img: base64String,
        contenttype: fileExtension,
      };

      try {
        setUploading(true);
        // getDecryptedData("role") === "MODERATOR" ||
        //   getDecryptedData("role") === "SUPERMODERATOR"
        sessionStorage.getItem("role") === "MODERATOR" ||
          sessionStorage.getItem("role") === "SUPERMODERATOR"
          ? await DocumentService.create(payload)
          : await DocumentService.createAnonymous(payload);
        setFile(null);
        setSelectedDocumentType(null);
        setSnackBar({
          message: "Uploaded Successfully!",
          open: true,
          severity: "success",
        });
        setOpen(false);
        setRefresh(!refresh);
        props.setReload(!props.reload)
        closeHelperModal();
        setUploading(false);
        //  openSnackBar({message:'Successfully Uploaded Document', severity:'success',open:true})
      } catch (err) {
        setUploading(false);
        setFile(null);
        setSelectedDocumentType(null);
        setSnackBar({
          message: err.response.data.message,
          open: true,
          severity: "error",
        });
        ///openSnackBar({message:'Error Uploading Document', severity:'error',open:true})
      }
      setUploading(false);
    });

    // Upload now
  };

  const handleAgeChange = (event) => {
    // let date = event.target.value.replace("-", "/");
    // date = date.replace("-", "/");
    setProfile({ ...profile, age: event.target.value });
  };

  const handleClickOpen = () => {
    setSelectedDocumentType(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedDocumentType(null);
    setOpenDialog(false);
  };


  const validateData = () => {
    const e = errorInitialState;

    if (profile.memberName === "") {
      e.error = true;
      e.memberName.exist = true;
    }
    // if (profile.age === "" || !/^\d+$/.test(profile.age)) {
    //   e.error = true;
    //   e.age.exist = true;
    // }
    if (profile.occupation === "") {
      e.error = true;
      e.occupation.exist = true;
    }
    if (profile.relationship === "") {
      e.error = true;
      e.relationship.exist = true;
    }

    return e;
  };

  const getoccupations = async () => {
    const response = await MasterData.getOccupation(); // get Occupation
    setOccupation(response.data);
  };

  const getRelationships = async () => {
    const response = await MasterData.getRelationships();
    setRelationships(response.data);
  };

  useEffect(() => {
    getoccupations();
    getRelationships();
  }, []);

  const save = async () => {
    let serverResponse;

    // Reformat data here
    // getDecryptedData("role") === "MODERATOR" ||
    //   getDecryptedData("role") === "SUPERMODERATOR"
    sessionStorage.getItem("role") === "MODERATOR" ||
      sessionStorage.getItem("role") === "SUPERMODERATOR"
      ? await TenantService.addFamilyMember(profile)
        .then((response) => {
          serverResponse = response.data;

          setFamMemberFlag(!famMemberFlag);
          setSnackBar({
            ...snackBar,
            message: "Successfully Saved Family Member ",
            open: true,
            severity: "success",
          });
          // setOpen(false);
        })
        .catch((error) => {
          setSnackBar({
            ...snackBar,
            message:
              error.response === null
                ? "Error in server!"
                : error.response.data.message,
            open: true,
            severity: "error",
          });
        })
      : await TenantService.addFamilyMemberAnonymous(profile)
        .then((response) => {
          serverResponse = response.data;

          setFamMemberFlag(!famMemberFlag);
          setSnackBar({
            ...snackBar,
            message: "Successfully Saved Family Member ",
            open: true,
            severity: "success",
          });
        })
        .catch((error) => {

          /* if(error!=null || error.response!=undefined){
             
       // Loop through all the error array 
       var errorStr= '';
       if(error.response.data!=undefined){
 
         for(var i=0;i<error.response.data.length;i++){
           errorStr+= error.response.data[i] + '\n';
         }
 
       */

          var msg = "Error in Server";
          if (error.response != null) msg = error.response.data.message;

          setSnackBar({
            ...snackBar,
            message: msg,
            open: true,
            severity: "error",
          });

          throw error;
        });

    // Save FamilyMemeber Here

    return serverResponse; // Successfully Saved
  };

  return (
    <>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setSnackBar({ ...snackBar, open: false });
        }}
      >
        <Alert
          onClose={() => {
            setSnackBar({ ...snackBar, open: false });
          }}
          severity={snackBar.severity}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Widget disableWidgetMenu>
        <div className={classTheme.dashedBorder} style={{ marginTop: 40 }}>
          <form className={classes.root} noValidate autoComplete="off">
            <Typography variant="h6" component="h6" style={{ marginTop: 0 }}>
              Basic Details
            </Typography>

            <FormControl>
              <TextField
                id="memberName"
                onChange={handleNameChange}
                value={profile.memberName}
                label={
                  <span>
                    Name <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                error={errors.memberName.exist === true ? true : false}
                helperText={
                  errors.memberName.exist ? errors.memberName.helperText : ""
                }
              />
            </FormControl>

            <FormControl>
              <TextField
                id="date"
                label={
                  <span>
                    Date of Birth <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                type="date"
                defaultValue="yyyy-mm-dd"
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleAgeChange}
              />
            </FormControl>

            <FormControl>
              <Autocomplete
                onChange={(event, value) => {
                  if (value !== null) {
                    setSelectedOccupation(value);
                    setProfile({ ...profile, occupation: value.value });
                  } else {
                    setProfile({
                      ...profile,
                      occupation: "",
                    });
                  }
                }}
                id="occupation"
                options={occupation}
                getOptionLabel={(option) => option.value}
                // getOptionSelected={() => selectedOccupation}
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Occupation <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.occupation.exist}
                    helperText={
                      errors.occupation.exist
                        ? errors.occupation.helperText
                        : ""
                    }
                  />
                )}
                disabled={profile.exist ? true : false}
                error={errors.occupation.exist === true ? true : false}
                helperText={
                  errors.occupation.exist ? errors.occupation.helperText : ""
                }
              />
            </FormControl>

            <FormControl>
              <Autocomplete
                onChange={(event, value) => {
                  handleRelationshipChange(value);
                }}
                id="relationship"
                options={relationships.map((rel) => rel.value)}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Relationship <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errors.relationship.exist}
                    helperText={
                      errors.relationship.exist
                        ? errors.relationship.helperText
                        : ""
                    }
                  />
                )}
              />
            </FormControl>
          </form>
        </div>

        {photoFlag && (
          <div className={classTheme.dashedBorder}>
            <Button
              style={{ float: "center" }}
              variant="contained"
              color="secondary"
              startIcon={<Icons.CloudUpload />}
              onClick={handleClickOpen}
            >
              Upload Passport Photograph
            </Button>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="form-dialog-title"
              maxWidth="1000px"
              disableBackdropClick
            >
              <DialogTitle id="form-dialog-title">Upload Passport Photograph</DialogTitle>
              {snackBar.open ? (
                <Alert
                  onClose={() => {
                    setSnackBar({ ...snackBar, open: false });
                  }}
                  severity={snackBar.severity}
                >
                  {snackBar.message}
                </Alert>
              ) : (
                <></>
              )}
              {uploading === false ? (
                <DialogContent >
                  <FormLabel>PHOTOGRAPH(only JPG or JPEG)</FormLabel>
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={["image/jpeg", "image/jpg"]}
                    onChange={(files) => {
                      setFile(files[0]);
                    }}
                  />
                </DialogContent>
              ) : (
                <h3>Uploading....Please wait</h3>
              )}
              <DialogActions>
                <IconButton
                  onClick={() => {
                    // setCamFile(label);
                    setOpenCamera(true);
                  }}
                // color="primary"
                >
                  <CameraAltOutlinedIcon />
                </IconButton>
                <Button onClick={handleCloseDialog} color="primary">
                  Close
                </Button>
                <Button
                  onClick={async () => {
                    await uploadFile();
                  }}
                  color="primary"
                >
                  Upload
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}

        <Button
          style={{ float: "right", marginTop: 5 }}
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={async () => {
            const errs = validateData();

            if (errs.error === true) {
              setErrors(errs);

              return;
            }

            // setBackDrop(true);
            const status = await save();
            if (status && status.memberCode) {
              setPhotoFlag(!photoFlag);
              setMemberCode(status.memberCode);
              handleClickOpen();
            }
            //  setBackDrop(false);
          }}
        >
          Save
        </Button>
        <Dialog
          open={openCamera}
          onClose={() => setOpenCamera(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CameraComponent
            setSnackBar={setSnackBar}
            document={document}
            setUploading={setUploading}
            // addTenantDocument={addTenantDocument}
            setDocument={setDocument}
            snackBar={snackBar}
            label={"PHOTO"}
            setOpenCamera={setOpenCamera}
            // famMemFlag={true}
            memberCode={memberCode}
            setOpen={setOpen}
          />
        </Dialog>
      </Widget>
    </>
  );
}
