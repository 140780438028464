import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";
// import { createTheme } from '@mui/material/styles';


const overrides = {
  // typography: {
  //   h2: {
  //     fontSize: "6.9rem",
  //     letterSpacing: "0.24em",
  //     lineHeight: 2.43,
  //   },
  //   h3: {
  //     fontSize: "2rem",
  //     letterSpacing: "0.24em",
  //     lineHeight: 2.43,
  //   },
  //   h4: {
  //     fontSize: "1.5rem",
  //     letterSpacing: "0.24em",
  //     lineHeight: 2.43,
  //   },
  //   h5: {
  //     fontSize: "1.285rem",
  //     letterSpacing: "0.24em",
  //     lineHeight: 2.43,
  //   },
  //   h6: {
  //     fontSize: "1.142rem",
  //     letterSpacing: "0.24em",
  //     lineHeight: 2.43,
  //   },
  //   subtitle1: {
  //     fontSize: "1.142rem",
  //     letterSpacing: "0.24em",
  //     lineHeight: 2.43,
  //   },
  // },
  typography: {
  //  fontSize: 13, // default font-size for all the text.
 
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
  MuiButton: {
    raisedPrimary: {
      color: "blue",
    },
  },
};

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};
